

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as MapShowModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/MapShow.module.css";

var accuracy$p = Utils.Translations.tr("js.map.legend.accuracy.accuracy");

var under100m$p = Utils.Translations.tr("js.map.legend.accuracy.under_100m");

var under1km$p = Utils.Translations.tr("js.map.legend.accuracy.under_1km");

var over1km$p = Utils.Translations.tr("js.map.legend.accuracy.over_1km");

var under300ft$p = Utils.Translations.tr("js.map.legend.accuracy.under_300ft");

var under1ml$p = Utils.Translations.tr("js.map.legend.accuracy.under_1ml");

var over1ml$p = Utils.Translations.tr("js.map.legend.accuracy.over_1ml");

var styles = MapShowModuleCss;

function iconStyle(s) {
  return El.Cn.concat(Icon.style(undefined, undefined, undefined, "map-marker-alt"), s);
}

function MapAccuracyLegend(Props) {
  var param = Props.wire;
  var ctx = param.ctx;
  var match = ctx.localizator.measurementSystem;
  var match$1;
  var exit = 0;
  if (typeof match !== "object" && match === "Imperial") {
    match$1 = [
      under300ft$p(ctx),
      under1ml$p(ctx),
      over1ml$p(ctx)
    ];
  } else {
    exit = 1;
  }
  if (exit === 1) {
    match$1 = [
      under100m$p(ctx),
      under1km$p(ctx),
      over1km$p(ctx)
    ];
  }
  return React.createElement("div", {
              className: styles.legend
            }, React.createElement("span", {
                  className: styles.description
                }, accuracy$p(ctx)), React.createElement("i", {
                  className: iconStyle(styles.high)
                }), React.createElement("span", {
                  className: styles.description
                }, match$1[0]), React.createElement("i", {
                  className: iconStyle(styles.moderate)
                }), React.createElement("span", {
                  className: styles.description
                }, match$1[1]), React.createElement("i", {
                  className: iconStyle(styles.low)
                }), React.createElement("span", {
                  className: styles.description
                }, match$1[2]));
}

var make = MapAccuracyLegend;

export {
  make ,
}
/* accuracy' Not a pure module */
