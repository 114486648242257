

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../../common/Link/Link.mjs";
import * as Modal from "../../modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Context from "../../../context/Context.mjs";
import * as UserAPI from "./UserAPI.mjs";
import * as UserTableJS from "./UserTableJS.mjs";
import * as UserFieldUserForm from "./UserFieldUserForm.mjs";
import * as UserInvitationTable from "./UserInvitationTable.mjs";

var users$p = Utils.Translations.tr("js.admin.users.title.index");

var add$p = Utils.Translations.tr("js.admin.users.index.add_user");

var addFieldUser$p = Utils.Translations.tr("js.admin.users.index.add_field_user");

var licenses$p = Utils.Translations.tx("js.admin.users.index.using_licenses");

var paidLicenses$p = Utils.Translations.tx("js.admin.users.index.paid_licenses");

var manageSubscriptions$p = Utils.Translations.tr("js.admin.users.index.manage_subscriptions");

function UserIndex(Props) {
  var wire = Props.wire;
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setAvailableLicenses = match$1[1];
  var availableLicenses = match$1[0];
  var loaded = function (data) {
    if (data.TAG !== "Ok") {
      return ;
    }
    var match = data._0;
    var userInvitations = match.userInvitations;
    var usedLicenses = match.usedLicenses;
    var availableLicenses = match.availableLicenses;
    var users = match.users;
    setState(function (param) {
          return {
                  loadedUsers: users,
                  usedLicenses: usedLicenses,
                  userInvitations: userInvitations
                };
        });
    setAvailableLicenses(function (param) {
          return availableLicenses;
        });
  };
  var onCloseFieldUserForm = function () {
    navigate(undefined, {
          NAME: "User",
          VAL: "Index"
        });
    Backend.$$finally(UserAPI.Index.index(wire), loaded);
  };
  React.useEffect((function () {
          Backend.$$finally(UserAPI.Index.index(wire), loaded);
        }), []);
  if (state === undefined) {
    return null;
  }
  var match$2 = wire.route.path;
  var tmp;
  if (typeof match$2 === "object" && match$2.NAME === "User") {
    var match$3 = match$2.VAL;
    tmp = typeof match$3 === "object" && match$3.NAME === "Field" ? React.createElement(Modal.make, {
            wire: wire,
            onClose: (function () {
                navigate(undefined, {
                      NAME: "User",
                      VAL: "Index"
                    });
              }),
            children: React.createElement(UserFieldUserForm.make, {
                  wire: wire,
                  onClose: onCloseFieldUserForm
                })
          }) : null;
  } else {
    tmp = null;
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("div", {
                      className: "page-header"
                    }, React.createElement("div", {
                          className: "row item-header"
                        }, React.createElement("div", {
                              className: "col-lg-12"
                            }, React.createElement("h1", {
                                  className: "text-center text-left-sm"
                                }, users$p(ctx)), React.createElement(Link.make, {
                                  wire: wire,
                                  route: {
                                    NAME: "User",
                                    VAL: {
                                      NAME: "Custom",
                                      VAL: "New"
                                    }
                                  },
                                  className: "add-link item-add-button btn btn-primary btn-outline",
                                  children: null
                                }, React.createElement("i", {
                                      className: Icon.style(undefined, undefined, undefined, "plus")
                                    }), El.space, add$p(ctx)), React.createElement(Link.make, {
                                  wire: wire,
                                  route: {
                                    NAME: "User",
                                    VAL: {
                                      NAME: "Field",
                                      VAL: "New"
                                    }
                                  },
                                  className: "add-link item-add-button btn btn-primary btn-outline",
                                  children: null
                                }, React.createElement("i", {
                                      className: Icon.style(undefined, undefined, undefined, "plus")
                                    }), El.space, addFieldUser$p(ctx))))), React.createElement("div", {
                      className: "row"
                    }, React.createElement("div", {
                          className: "col-lg-12 licenses"
                        }, React.createElement("div", {
                              className: "note note-info note-new"
                            }, licenses$p({
                                  count: state.usedLicenses
                                }, ctx), El.space, availableLicenses !== undefined ? paidLicenses$p({
                                    count: availableLicenses
                                  }, ctx) : null, Context.canManageSubscription(ctx) ? React.createElement("span", undefined, El.space, El.ndash, El.space, React.createElement(Link.make, {
                                        wire: wire,
                                        route: "Billing",
                                        children: manageSubscriptions$p(ctx)
                                      })) : null))), React.createElement(UserInvitationTable.make, {
                      wire: wire,
                      userInvitations: state.userInvitations
                    }), React.createElement("div", {
                      className: "row"
                    }, React.createElement("div", {
                          className: "col-lg-12"
                        }, React.createElement("div", {
                              className: "panel"
                            }, React.createElement("div", {
                                  className: "panel-body"
                                }, React.createElement(UserTableJS.make, {
                                      data: state.loadedUsers,
                                      ctx: ctx
                                    })))))), tmp);
}

var make = UserIndex;

export {
  make ,
}
/* users' Not a pure module */
