

import * as Icon from "../../../../../../Icon.mjs";
import * as Hooks from "../../../../../../libraries/hooks/Hooks.mjs";
import * as Types from "../../../../../../types/Types.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Context_Types from "../../../../../../context/Context_Types.mjs";
import * as Schedule_Button from "../../../button/Schedule_Button.mjs";
import * as Schedule_Header_Mode from "./mode/Schedule_Header_Mode.mjs";
import * as Schedule_Header_JobDuration from "./job_duration/Schedule_Header_JobDuration.mjs";
import * as Schedule_Header_AssigneesFilter from "./assignees_filter/Schedule_Header_AssigneesFilter.mjs";
import * as Schedule_Header_SettingsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/header/right/settings/Schedule_Header_Settings.module.css";

var styles = Schedule_Header_SettingsModuleCss;

function Schedule_Header_Settings(Props) {
  var ctx = AppContext.useCtx();
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var show = match[0];
  var close = function () {
    setShow(function (param) {
          return false;
        });
  };
  var toggle = function (_event) {
    setShow(function (show) {
          return !show;
        });
  };
  var popoverRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, popoverRef, close);
  Hooks.useOnEscPress(undefined, close);
  return React.createElement("div", {
              className: styles.container
            }, React.createElement(Schedule_Button.make, {
                  onClick: toggle,
                  active: show,
                  className: styles.button,
                  children: React.createElement("span", {
                        className: Icon.style(undefined, undefined, undefined, "cog")
                      })
                }), React.createElement(Optional.make, {
                  show: show,
                  children: React.createElement("div", {
                        ref: popoverRef,
                        className: styles.popover
                      }, React.createElement(Schedule_Header_Mode.make, {}), React.createElement(Schedule_Header_JobDuration.make, {}), React.createElement(Optional.make, {
                            show: Context_Types.Features.hasFlag("teams", ctx.features),
                            children: React.createElement(Schedule_Header_AssigneesFilter.make, {})
                          }))
                }));
}

var make = Schedule_Header_Settings;

export {
  make ,
}
/* styles Not a pure module */
