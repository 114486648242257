

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Constants from "../../../../lib/Schedule_Constants.mjs";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_Types_JobCore from "../../../../lib/types/job/Schedule_Types_JobCore.mjs";
import * as Schedule_UnscheduledHooks from "../../Schedule_UnscheduledHooks.mjs";
import * as Schedule_UnscheduledJobModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/uscheduled_jobs/components/job/Schedule_UnscheduledJob.module.css";

var noTemplate$p = Utils.Translations.tr("js.schedule.v2.job.without_template");

var noAssignee$p = Utils.Translations.tr("js.schedule.v2.job.without_assignee");

function hours$p(hours, c) {
  return Utils.Translations.tx("js.duration.hours")({
              hours: hours
            }, c);
}

function minutes$p(minutes, c) {
  return Utils.Translations.tx("js.duration.minutes")({
              minutes: minutes
            }, c);
}

var styles = Schedule_UnscheduledJobModuleCss;

function Schedule_UnscheduledJob(Props) {
  var job = Props.job;
  var match = AppContext.useWire();
  var subscriptions = match.subscriptions;
  var ctx = match.ctx;
  var match$1 = Schedule_Types_Job.UnscheduledJob.core(job);
  var resolution = match$1.resolution;
  var address = match$1.address;
  var duration = match$1.duration;
  var uuid = match$1.uuid;
  var title = Schedule_Types_Job.UnscheduledJob.title(job);
  var templateBlock = React.createElement("div", {
        className: styles.template
      }, title !== undefined ? title : noTemplate$p(ctx));
  var match$2 = Schedule_Types_Job.UnscheduledJob.assignees(job)[0];
  var assigneeBlock;
  var exit = 0;
  var name;
  if (match$2 !== undefined) {
    if (match$2.TAG === "Team") {
      name = match$2._0.name;
      exit = 1;
    } else {
      name = match$2._0._0.name;
      exit = 1;
    }
  } else {
    assigneeBlock = React.createElement("div", {
          className: styles.noAssignee
        }, React.createElement("i", {
              className: Icon.style(undefined, undefined, undefined, "user")
            }), noAssignee$p(ctx));
  }
  if (exit === 1) {
    assigneeBlock = React.createElement("div", {
          className: styles.assignee
        }, name);
  }
  var hours = Caml_int32.div(duration, Schedule_Constants.minutesInHour);
  var minutes = Caml_int32.mod_(duration, Schedule_Constants.minutesInHour);
  var duration$1 = El.Cn.concat(El.Cn.concat(" ", hours === 0 ? "" : hours$p(hours, ctx)), minutes === 0 ? "" : minutes$p(minutes, ctx));
  var durationBlock = React.createElement("div", {
        className: styles.duration
      }, duration$1);
  var infoBlock = React.createElement("div", {
        className: styles.info
      }, assigneeBlock, durationBlock);
  var addressBlock = address !== undefined ? React.createElement("div", {
          className: styles.address
        }, Schedule_Types_JobCore.Address.toString(Caml_option.valFromOption(address))) : null;
  var resolutionClassName = resolution !== undefined ? (
      resolution.successful ? styles.successful : styles.unsuccessful
    ) : "";
  var className = El.Cn.concat(styles.job, match$1.status === "Finished" ? El.Cn.concat(styles.finished, resolutionClassName) : "");
  var dragRef = Schedule_UnscheduledHooks.Draggable.use(job);
  return React.createElement("div", {
              ref: dragRef,
              className: className,
              onClick: (function (_event) {
                  subscriptions.jobs.trigger({
                        TAG: "OpenJob",
                        _0: uuid
                      });
                })
            }, templateBlock, infoBlock, addressBlock);
}

var make = Schedule_UnscheduledJob;

export {
  make ,
}
/* noTemplate' Not a pure module */
