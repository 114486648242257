

import * as Url from "../../bindings/url/Url.mjs";
import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Types from "../../types/Types.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              url: field.required("url", Url.decode)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              orderNo: field.required("orderNo", JsonDecode.$$int),
              file: field.required("file", JsonDecode.option(decode))
            };
    });

var Territory = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              code: field.required("code", JsonDecode.string),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              timezone: field.required("timezone", JsonDecode.string),
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(decode$2))
            };
    });

var Territory$1 = {
  decode: decode$3
};

var New = {
  Territory: Territory$1
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              TAG: "Saved",
              name: field.required("name", JsonDecode.string),
              url: field.required("url", Url.decode)
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              name: field.required("name", JsonDecode.string),
              domFile: undefined,
              file: field.required("file", JsonDecode.option(decode$4)),
              timezone: field.required("timezone", JsonDecode.option(JsonDecode.string)),
              timezoneVariants: field.required("timezoneVariants", JsonDecode.array(decode$2))
            };
    });

var Territory$2 = {
  decode: decode$5
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$7 = JsonDecode.object(function (field) {
      return {
              users: field.required("users", JsonDecode.array(decode$6)),
              teams: field.required("teams", JsonDecode.array(decode$6)),
              clients: field.required("clients", JsonDecode.array(decode$6)),
              sites: field.required("sites", JsonDecode.array(decode$6)),
              jobs: field.required("jobs", JsonDecode.array(decode$6)),
              templates: field.required("templates", JsonDecode.array(decode$6))
            };
    });

var decode$8 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var Territory$3 = {
  decode: decode$8
};

var UsedLayout = {
  Territory: Territory$3
};

var Index_File = {};

var Index = {
  $$File: Index_File,
  Territory: Territory
};

var TimezoneVariant = {};

var Show_File = {};

var Show = {
  $$File: Show_File,
  Territory: Territory$2
};

var UsedBy_Entity = {};

var UsedBy = {
  Entity: UsedBy_Entity,
  decode: decode$7
};

export {
  Index ,
  TimezoneVariant ,
  New ,
  Show ,
  UsedBy ,
  UsedLayout ,
}
/* decode Not a pure module */
