

import * as Icon from "../../../../../Icon.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Optional from "../../../../common/Optional.mjs";
import * as FileInput_Preview_RemoveButton from "./FileInput_Preview_RemoveButton.mjs";
import * as FileInput_Preview_DownloadButton from "./FileInput_Preview_DownloadButton.mjs";
import * as FileInput_PreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/preview/FileInput_Preview.module.css";

var styles = FileInput_PreviewModuleCss;

function FileInput_Preview_Image(Props) {
  var image = Props.image;
  var url = Props.url;
  var removeFile = Props.removeFile;
  var showImage = Props.showImage;
  var readOnly = Props.readOnly;
  return React.createElement("div", {
              className: styles.imageWrapper,
              onClick: (function (param) {
                  showImage(image.uuid);
                })
            }, React.createElement("div", {
                  className: styles.image,
                  style: {
                    backgroundColor: "white",
                    backgroundImage: "url('" + RCore.$$Option.getOr(image.previewUrl, url) + "')",
                    border: "1px solid #f6f6f6"
                  }
                }), React.createElement("div", {
                  className: styles.imageHover
                }, React.createElement("div", {
                      className: styles.imageLight
                    }), React.createElement("div", {
                      className: styles.imageContent
                    }, React.createElement("span", {
                          className: Icon.style(undefined, undefined, undefined, "eye")
                        })), React.createElement("div", {
                      className: styles.imageButtons
                    }, React.createElement(Optional.make, {
                          show: !readOnly,
                          children: React.createElement(FileInput_Preview_RemoveButton.make, {
                                removeFile: removeFile,
                                decoration: "Light"
                              })
                        }), React.createElement(FileInput_Preview_DownloadButton.make, {
                          url: url,
                          name: image.name,
                          decoration: "Light"
                        }))));
}

var make = FileInput_Preview_Image;

export {
  make ,
}
/* styles Not a pure module */
