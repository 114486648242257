

import * as Icon from "../../../Icon.mjs";
import * as Link from "../../../components/common/Link/Link.mjs";
import * as Modal from "../../../components/modal/Modal.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Collapse from "../../../components/common/Collapse/Collapse.mjs";
import * as Optional from "../../../components/common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Pages_Skills_RemoveWarningModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/skills/remove_warning/Pages_Skills_RemoveWarning.module.css";

var styles = Pages_Skills_RemoveWarningModuleCss;

var skillInUse$p = Utils.Translations.tr("js.admin.skills.modal.skill_in_use");

var deleteQuestion$p = Utils.Translations.tr("js.admin.skills.modal.delete_question");

var showWhereSkillUsed$p = Utils.Translations.t("js.admin.skills.modal.show_where_skill_used");

var usedInWorkers$p = Utils.Translations.tr("js.admin.skills.modal.used_in_workers");

var usedInTeams$p = Utils.Translations.tr("js.admin.skills.modal.used_in_teams");

var usedInTemplates$p = Utils.Translations.tr("js.admin.skills.modal.used_in_templates");

var delete$p = Utils.Translations.tr("js.admin.skills.modal.delete");

var cancel$p = Utils.Translations.tr("js.admin.skills.modal.cancel");

function Pages_Skills_RemoveWarning$Entity(Props) {
  var entity = Props.entity;
  var entityType = Props.entityType;
  var wire = AppContext.useWire();
  var route;
  switch (entityType) {
    case "Worker" :
        route = {
          NAME: "User",
          VAL: {
            NAME: "Custom",
            VAL: {
              NAME: "Edit",
              VAL: entity.uuid
            }
          }
        };
        break;
    case "Team" :
        route = {
          NAME: "Team",
          VAL: {
            NAME: "Edit",
            VAL: entity.uuid
          }
        };
        break;
    case "Template" :
        route = {
          NAME: "Template",
          VAL: {
            NAME: "Edit",
            VAL: entity.uuid
          }
        };
        break;
    
  }
  return React.createElement("li", undefined, React.createElement(Link.make, {
                  wire: wire,
                  route: route,
                  target: "blank",
                  children: null
                }, entity.name, React.createElement("span", {
                      className: Icon.style(undefined, undefined, undefined, "external-link-alt") + " " + styles.icon
                    })));
}

function Pages_Skills_RemoveWarning$Entities(Props) {
  var usedBy = Props.usedBy;
  var entityType = Props.entityType;
  var ctx = AppContext.useCtx();
  var text;
  switch (entityType) {
    case "Worker" :
        text = usedInWorkers$p(ctx);
        break;
    case "Team" :
        text = usedInTeams$p(ctx);
        break;
    case "Template" :
        text = usedInTemplates$p(ctx);
        break;
    
  }
  return React.createElement(Optional.make, {
              show: Caml_obj.notequal(usedBy, []),
              children: null
            }, React.createElement("p", undefined, text), React.createElement("ul", undefined, usedBy.map(function (entity, i) {
                      return React.createElement(Pages_Skills_RemoveWarning$Entity, {
                                  entity: entity,
                                  entityType: entityType,
                                  key: i.toString()
                                });
                    })));
}

function Pages_Skills_RemoveWarning(Props) {
  var skill = Props.skill;
  var param = Props.usedBy;
  var onClose = Props.onClose;
  var onAccept = Props.onAccept;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  return React.createElement(Modal.make, {
              wire: wire,
              onClose: onClose,
              children: React.createElement("div", {
                    className: styles.modalContainer
                  }, React.createElement("div", {
                        className: styles.modal
                      }, React.createElement("div", {
                            className: styles.modalHeader
                          }, React.createElement("div", undefined, skillInUse$p(ctx)), React.createElement("div", undefined, React.createElement("a", {
                                    className: "close",
                                    onClick: (function ($$event) {
                                        $$event.preventDefault();
                                        onClose();
                                      })
                                  }, React.createElement("span", {
                                        className: Icon.style(undefined, undefined, undefined, "times")
                                      })))), React.createElement("div", {
                            className: styles.modalBody
                          }, React.createElement("p", undefined, deleteQuestion$p(ctx)), React.createElement(Collapse.make, {
                                label: showWhereSkillUsed$p(ctx),
                                children: React.createElement("div", undefined, React.createElement(Pages_Skills_RemoveWarning$Entities, {
                                          usedBy: param.users,
                                          entityType: "Worker"
                                        }), React.createElement(Pages_Skills_RemoveWarning$Entities, {
                                          usedBy: param.teams,
                                          entityType: "Team"
                                        }), React.createElement(Pages_Skills_RemoveWarning$Entities, {
                                          usedBy: param.templates,
                                          entityType: "Template"
                                        }))
                              })), React.createElement("div", {
                            className: styles.modalFooter
                          }, React.createElement("button", {
                                className: "btn btn-default",
                                disabled: false,
                                onClick: (function ($$event) {
                                    $$event.preventDefault();
                                    onClose();
                                  })
                              }, cancel$p(ctx)), React.createElement("button", {
                                className: "btn btn-danger",
                                disabled: false,
                                onClick: (function ($$event) {
                                    $$event.preventDefault();
                                    onAccept(skill);
                                  })
                              }, delete$p(ctx)))))
            });
}

var make = Pages_Skills_RemoveWarning;

export {
  make ,
}
/* styles Not a pure module */
