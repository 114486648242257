

import * as Icon from "../../../../Icon.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var styles = BillingModuleCss;

function Billing_Components_Paypal(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: styles.cardInfo
            }, React.createElement("i", {
                  className: Icon.style("2x", undefined, undefined, "cc-paypal")
                }), RCore.$$Option.getOr(children, null));
}

var make = Billing_Components_Paypal;

export {
  make ,
}
/* styles Not a pure module */
