

import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as Context_Lang from "./Context_Lang.mjs";
import * as Types_Spatial from "../types/Types_Spatial.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              separator: field.required("separator", JsonDecode.string),
              delimiter: field.required("delimiter", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              date: field.required("date", JsonDecode.string),
              time: field.required("time", JsonDecode.string),
              datetime: field.required("datetime", JsonDecode.string),
              dateShort: field.required("dateShort", JsonDecode.string)
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              timezone: field.required("timezone", JsonDecode.string),
              timezoneOffset: field.required("timezoneOffset", JsonDecode.$$int),
              formats: field.required("formats", decode$1)
            };
    });

function fromString(system) {
  switch (system) {
    case "imperial" :
        return "Imperial";
    case "international" :
        return "International";
    default:
      return {
              TAG: "Unknown",
              _0: system
            };
  }
}

function fromStringOpt(system) {
  switch (system) {
    case "imperial" :
        return "Imperial";
    case "international" :
        return "International";
    default:
      return ;
  }
}

function toString(system) {
  if (typeof system !== "object") {
    if (system === "Imperial") {
      return "imperial";
    } else {
      return "international";
    }
  } else {
    return system._0;
  }
}

var decode$3 = JsonDecode.fromVariant(JsonDecode.string, fromStringOpt);

function fromString$1(string) {
  switch (string) {
    case "global" :
        return "global";
    case "r0" :
        return "r0";
    case "r1" :
        return "r1";
    case "r2" :
        return "r2";
    case "r3" :
        return "r3";
    case "r4" :
        return "r4";
    case "ru" :
        return "ru";
    default:
      return string;
  }
}

var decode$4 = JsonDecode.map(JsonDecode.string, fromString$1);

var decode$5 = JsonDecode.object(function (field) {
      return {
              lang: field.required("lang", Context_Lang.decode),
              mondayFirst: field.required("mondayFirst", JsonDecode.bool),
              international: field.required("international", JsonDecode.bool),
              region: field.required("region", decode$4),
              numbers: field.required("numbers", decode),
              datetime: field.required("datetime", decode$2),
              approximateGeolocation: field.required("approximateGeolocation", Types_Spatial.$$Geolocation.decode),
              measurementSystem: field.required("measurementSystem", decode$3),
              currency: field.required("currency", JsonDecode.string),
              currencyFormat: field.required("currencyFormat", JsonDecode.string)
            };
    });

function toJs(localizator) {
  return {
          lang: Context_Lang.toString(localizator.lang),
          mondayFirst: localizator.mondayFirst,
          international: localizator.international,
          numbers: localizator.numbers,
          datetime: localizator.datetime,
          approximateGeolocation: localizator.approximateGeolocation,
          measurementSystem: toString(localizator.measurementSystem),
          currency: localizator.currency,
          currencyFormat: localizator.currencyFormat
        };
}

var Numbers = {};

var Formats = {};

var Datetime = {};

var MeasurementSystem = {
  fromString: fromString,
  toString: toString,
  decode: decode$3
};

var Region = {
  decode: decode$4
};

export {
  Numbers ,
  Formats ,
  Datetime ,
  MeasurementSystem ,
  Region ,
  decode$5 as decode,
  toJs ,
}
/* decode Not a pure module */
