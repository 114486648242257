

import * as El from "../../../../../../libraries/El.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Schedule_Types_Job from "../../../../lib/types/job/Schedule_Types_Job.mjs";
import * as Schedule_JobPreviewInfoModuleCss from "/home/runner/work/planado/planado/client/rescript/components/schedule/components/popovers/job_preview/info/Schedule_JobPreviewInfo.module.css";

var styles = Schedule_JobPreviewInfoModuleCss;

var assigneeSeparator$p = Utils.Translations.tr("js.schedule.v2.job.assignee_separator");

function Schedule_JobPreviewInfo$ScheduledAt(Props) {
  var job = Props.job;
  var wire = AppContext.useWire();
  var scheduledAt = Schedule_Types_Job.ScheduledJob.scheduledAt(job);
  if (scheduledAt === undefined) {
    return null;
  }
  var scheduledAt$1 = Caml_option.valFromOption(scheduledAt);
  var scheduledFinishAt = Locale.T.nextMinutes(Schedule_Types_Job.ScheduledJob.duration(job), scheduledAt$1);
  var interval;
  if (Locale.T.equalDay(scheduledAt$1, scheduledFinishAt)) {
    var a = Locale.T.fmtM("Time", wire)(scheduledAt$1);
    var b = Locale.T.fmtM("Time", wire)(scheduledFinishAt);
    interval = a + "–" + b;
  } else {
    var a$1 = Locale.T.fmtM("DateTimeNoYear", wire)(scheduledAt$1);
    var b$1 = Locale.T.fmtM("DateTimeNoYear", wire)(scheduledFinishAt);
    interval = a$1 + " – " + b$1;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.date
                }, interval), React.createElement("div", {
                  className: styles.separator
                }, assigneeSeparator$p(wire.ctx)));
}

var make = React.memo(Schedule_JobPreviewInfo$ScheduledAt);

var ScheduledAt = {
  assigneeSeparator$p: assigneeSeparator$p,
  make: make
};

var noAssignee$p = Utils.Translations.tr("js.schedule.v2.job.without_assignee");

function Schedule_JobPreviewInfo$Assignee(Props) {
  var job = Props.job;
  var match = AppContext.useWire();
  var match$1 = Schedule_Types_Job.ScheduledJob.assignees(job)[0];
  var name;
  if (match$1 === undefined) {
    return React.createElement("div", {
                className: styles.noAssignee
              }, React.createElement("i", {
                    className: Icon.style(undefined, undefined, undefined, "user")
                  }), noAssignee$p(match.ctx));
  }
  name = match$1.TAG === "Team" ? match$1._0.name : match$1._0._0.name;
  return React.createElement("div", {
              className: styles.assignee
            }, name);
}

var make$1 = React.memo(Schedule_JobPreviewInfo$Assignee);

var Assignee = {
  noAssignee$p: noAssignee$p,
  make: make$1
};

function Schedule_JobPreviewInfo(Props) {
  var job = Props.job;
  var toggleJobForm = Props.toggleJobForm;
  var wire = AppContext.useWire();
  var hasRightsToEdit = Schedule_Types_Job.ScheduledJob.hasRightsToEdit(job, wire);
  var toggleJobForm$1 = function (param) {
    if (hasRightsToEdit) {
      return toggleJobForm();
    }
    
  };
  var className = El.Cn.concat(styles.info, hasRightsToEdit ? "" : styles.noRights);
  return React.createElement("div", {
              className: className,
              onClick: toggleJobForm$1
            }, React.createElement(make, {
                  job: job
                }), React.createElement(make$1, {
                  job: job
                }));
}

var make$2 = React.memo(Schedule_JobPreviewInfo);

export {
  styles ,
  ScheduledAt ,
  Assignee ,
  make$2 as make,
}
/* styles Not a pure module */
