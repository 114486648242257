

import * as Fun from "../../../libraries/Fun.mjs";
import * as Case from "../../../utils/Case.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../libraries/PromiseF.mjs";
import * as JsContext from "../../../libraries/JsContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobFieldType from "./JobFieldType.mjs";
import * as JobViewTypes from "./views/JobViewTypes.mjs";
import * as JobIndexTypes from "./JobIndexTypes.mjs";
import * as JobViewDecoder from "./views/JobViewDecoder.mjs";
import * as JobViewEncoder from "./views/JobViewEncoder.mjs";
import * as JobIndexIdToUuid from "./JobIndexIdToUuid.mjs";
import * as Shared_Lib_Array from "../../../shared/lib/Shared_Lib_Array.mjs";
import * as BackgroundTasksApi from "../../background_task/BackgroundTasksApi.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode)
            };
    });

function search(wire, query) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: {
                      NAME: "Find",
                      VAL: query
                    }
                  }
                }), decode);
}

function encodeStartOfDay(v) {
  var __x = (function (__x) {
        return Locale.T.startOf("day", __x);
      })(v);
  return Locale.T.formatM("DateTimeISO")(__x);
}

function encodeEndOfDay(v) {
  var __x = (function (__x) {
        return Locale.T.endOf("day", __x);
      })(v);
  return Locale.T.formatM("DateTimeISO")(__x);
}

function makePolytypeAnyOrEmptyValue(value) {
  if (value === "NotFilled") {
    return {
            type: "not_filled"
          };
  } else {
    return {
            type: "any"
          };
  }
}

function makeNumberValue(value) {
  var tmp;
  if (typeof value !== "object") {
    tmp = value === "NotFilled" ? ({
          type: "not_filled",
          exactValue: undefined,
          intervalValues: undefined
        }) : ({
          type: "any",
          exactValue: undefined,
          intervalValues: undefined
        });
  } else if (value.TAG === "Eql") {
    tmp = {
      type: "eql",
      exactValue: value._0,
      intervalValues: undefined
    };
  } else {
    var i = value._0;
    tmp = i.start !== undefined || i.finish !== undefined ? ({
          type: "interval",
          exactValue: undefined,
          intervalValues: i
        }) : ({
          type: "any",
          exactValue: undefined,
          intervalValues: undefined
        });
  }
  return tmp;
}

function makeStringValue(value) {
  var tmp;
  if (typeof value !== "object") {
    tmp = value === "NotFilled" ? ({
          type: "not_filled",
          value: undefined
        }) : ({
          type: "any",
          value: undefined
        });
  } else {
    var value$1 = value._0;
    tmp = value$1 !== undefined ? ({
          type: "filled",
          value: value$1
        }) : ({
          type: "any",
          value: undefined
        });
  }
  return tmp;
}

function makeAssignees(workers, teams) {
  if (workers.length > 0 || teams.length > 0) {
    return {
            workers: workers,
            teams: teams
          };
  }
  
}

function makeClient(client) {
  if (client !== undefined) {
    return {
            uuid: client.uuid,
            type: client.type_
          };
  }
  
}

function arrayToOption(array) {
  if (array.length === 0) {
    return ;
  } else {
    return array;
  }
}

function makeTemplates(templates) {
  return RCore.$$Option.map(arrayToOption(templates), (function (arr) {
                return arr.map(function (template) {
                            if (typeof template !== "object") {
                              return null;
                            } else {
                              return template._0;
                            }
                          });
              }));
}

function makeTerritories(territories) {
  return RCore.$$Option.map(arrayToOption(territories), (function (arr) {
                return arr.map(function (territory) {
                            if (typeof territory !== "object") {
                              return null;
                            } else {
                              return territory._0;
                            }
                          });
              }));
}

function encodeWithoutResolutions(status) {
  return {
          status: JobViewTypes.Status.toString(status),
          resolutions: undefined
        };
}

function encodeWithResolutions(status, resolutions) {
  return {
          status: JobViewTypes.Status.toString(status),
          resolutions: arrayToOption(resolutions)
        };
}

function byFilterAndField(predefinedFields, filter, key) {
  return Fun.both(RCore.$$Option.map(filter, (function (f) {
                    return f.predefinedFields;
                  })), RCore.$$Array.getBy(predefinedFields, (function (f) {
                    return f.key === key;
                  })));
}

function isEnabled(filterSettings, code) {
  return filterSettings.some(function (f) {
              return code === f.key;
            });
}

function encodeTypeCodes(predefinedFields, allTypes, jobFilter) {
  var match = byFilterAndField(predefinedFields, jobFilter, "TypeCode");
  if (match === undefined) {
    return ;
  }
  var filter = match[1].value;
  if (filter.TAG === "Dictionary" && isEnabled(match[0], "TypeCode")) {
    var filter$1 = filter._0;
    if (typeof filter$1 !== "object") {
      if (filter$1 === "Any") {
        return ;
      } else {
        return RCore.$$Array.filterMap(allTypes, (function (t) {
                      if (t.system) {
                        return Caml_option.some(t.uuid);
                      }
                      
                    }));
      }
    } else {
      return filter$1._0;
    }
  }
  
}

function encodeMobileNumber(predefinedFields, jobFilter) {
  var match = byFilterAndField(predefinedFields, jobFilter, "MobileNumber");
  if (match === undefined) {
    return ;
  }
  var filter = match[1].value;
  if (filter.TAG === "String" && isEnabled(match[0], "MobileNumber")) {
    return makeStringValue(filter._0);
  }
  
}

function encodeGeolocation(predefinedFields, jobFilter) {
  var match = byFilterAndField(predefinedFields, jobFilter, "Geolocation");
  if (match === undefined) {
    return ;
  }
  var filled = match[1].value;
  if (filled.TAG === "Geolocation" && isEnabled(match[0], "Geolocation")) {
    return makePolytypeAnyOrEmptyValue(filled._0);
  }
  
}

function encodeCompletionJobUuids(predefinedFields, jobFilter) {
  var match = byFilterAndField(predefinedFields, jobFilter, "CompletionJobs");
  if (match === undefined) {
    return ;
  }
  var filled = match[1].value;
  if (filled.TAG === "Uuids" && isEnabled(match[0], "CompletionJobs")) {
    return makePolytypeAnyOrEmptyValue(filled._0);
  }
  
}

function encodeDuration(predefinedFields, jobFilter, durationType) {
  var match = byFilterAndField(predefinedFields, jobFilter, durationType);
  if (match === undefined) {
    return ;
  }
  var filled = match[1].value;
  if (filled.TAG === "Duration" && isEnabled(match[0], durationType)) {
    return makePolytypeAnyOrEmptyValue(filled._0);
  }
  
}

function keepEnabled(viewFilters, filterSettings) {
  if (filterSettings !== undefined) {
    return RCore.$$Array.keep(viewFilters, (function (v) {
                  return filterSettings.some(function (f) {
                              if (f.enabled) {
                                return Uuid.equal(v.typeUuid, f.typeUuid);
                              } else {
                                return false;
                              }
                            });
                }));
  } else {
    return viewFilters;
  }
}

function emptyValue(field) {
  return {
          typeUuid: field.typeUuid,
          dictionaryValue: undefined,
          booleanValue: undefined,
          stringValue: undefined,
          dateValue: undefined,
          integerValue: undefined,
          decimalValue: undefined,
          currencyValue: undefined,
          attachmentValue: undefined
        };
}

function makeDates(wire) {
  return function (value) {
    if (typeof value !== "object") {
      if (value === "NotFilled") {
        return {
                type: "not_filled",
                interval: undefined
              };
      } else {
        return {
                type: "any",
                interval: undefined
              };
      }
    }
    switch (value.TAG) {
      case "Eql" :
          var date = value._0;
          var value_start = encodeStartOfDay(date);
          var value_finish = encodeEndOfDay(date);
          var value$1 = {
            start: value_start,
            finish: value_finish
          };
          return {
                  type: "filled",
                  interval: value$1
                };
      case "Earlier" :
          var range = value._0;
          var tmp;
          tmp = range.TAG === "Relative" ? encodeEndOfDay(Locale.T.prevDays(range._0)(Locale.T.now(wire))) : encodeEndOfDay(range._0);
          var value$2 = {
            start: "1970-01-01T00:00:00Z",
            finish: tmp
          };
          return {
                  type: "filled",
                  interval: value$2
                };
      case "Later" :
          var range$1 = value._0;
          var tmp$1;
          tmp$1 = range$1.TAG === "Relative" ? encodeStartOfDay(Locale.T.prevDays(range$1._0)(Locale.T.now(wire))) : encodeStartOfDay(range$1._0);
          var value$3 = {
            start: tmp$1,
            finish: "2100-01-01T00:00:00Z"
          };
          return {
                  type: "filled",
                  interval: value$3
                };
      case "Interval" :
          var match = value._0;
          switch (match.TAG) {
            case "Starting" :
                var match$1 = match._0;
                var start = Locale.T.prevDays(match$1[0])(Locale.T.now(wire));
                var value_start$1 = encodeStartOfDay(start);
                var value_finish$1 = encodeEndOfDay(Locale.T.nextDays(match$1[1])(start));
                var value$4 = {
                  start: value_start$1,
                  finish: value_finish$1
                };
                return {
                        type: "filled",
                        interval: value$4
                      };
            case "In" :
                var match$2 = match._0;
                var start$1 = Locale.T.nextDays(match$2[0])(Locale.T.now(wire));
                var value_start$2 = encodeStartOfDay(start$1);
                var value_finish$2 = encodeEndOfDay(Locale.T.nextDays(match$2[1])(start$1));
                var value$5 = {
                  start: value_start$2,
                  finish: value_finish$2
                };
                return {
                        type: "filled",
                        interval: value$5
                      };
            case "Specific" :
                var match$3 = match._0;
                var value_start$3 = encodeStartOfDay(match$3[0]);
                var value_finish$3 = encodeEndOfDay(match$3[1]);
                var value$6 = {
                  start: value_start$3,
                  finish: value_finish$3
                };
                return {
                        type: "filled",
                        interval: value$6
                      };
            
          }
      
    }
  };
}

function encodeCustomField(wire) {
  return function (field) {
    var value = field.value;
    switch (value.TAG) {
      case "Bool" :
          var init = emptyValue(field);
          return {
                  typeUuid: init.typeUuid,
                  dictionaryValue: init.dictionaryValue,
                  booleanValue: value._0,
                  stringValue: init.stringValue,
                  dateValue: init.dateValue,
                  integerValue: init.integerValue,
                  decimalValue: init.decimalValue,
                  currencyValue: init.currencyValue,
                  attachmentValue: init.attachmentValue
                };
      case "Dates" :
          var init$1 = emptyValue(field);
          return {
                  typeUuid: init$1.typeUuid,
                  dictionaryValue: init$1.dictionaryValue,
                  booleanValue: init$1.booleanValue,
                  stringValue: init$1.stringValue,
                  dateValue: makeDates(wire)(value._0),
                  integerValue: init$1.integerValue,
                  decimalValue: init$1.decimalValue,
                  currencyValue: init$1.currencyValue,
                  attachmentValue: init$1.attachmentValue
                };
      case "Dictionary" :
          var value$1 = value._0;
          var init$2 = emptyValue(field);
          var tmp;
          tmp = typeof value$1 !== "object" ? (
              value$1 === "Any" ? ({
                    type: "any",
                    values: []
                  }) : ({
                    type: "not_filled",
                    values: []
                  })
            ) : ({
                type: "filled",
                values: value$1._0
              });
          return {
                  typeUuid: init$2.typeUuid,
                  dictionaryValue: tmp,
                  booleanValue: init$2.booleanValue,
                  stringValue: init$2.stringValue,
                  dateValue: init$2.dateValue,
                  integerValue: init$2.integerValue,
                  decimalValue: init$2.decimalValue,
                  currencyValue: init$2.currencyValue,
                  attachmentValue: init$2.attachmentValue
                };
      case "String" :
          var init$3 = emptyValue(field);
          return {
                  typeUuid: init$3.typeUuid,
                  dictionaryValue: init$3.dictionaryValue,
                  booleanValue: init$3.booleanValue,
                  stringValue: makeStringValue(value._0),
                  dateValue: init$3.dateValue,
                  integerValue: init$3.integerValue,
                  decimalValue: init$3.decimalValue,
                  currencyValue: init$3.currencyValue,
                  attachmentValue: init$3.attachmentValue
                };
      case "Decimal" :
          var init$4 = emptyValue(field);
          return {
                  typeUuid: init$4.typeUuid,
                  dictionaryValue: init$4.dictionaryValue,
                  booleanValue: init$4.booleanValue,
                  stringValue: init$4.stringValue,
                  dateValue: init$4.dateValue,
                  integerValue: init$4.integerValue,
                  decimalValue: makeNumberValue(value._0),
                  currencyValue: init$4.currencyValue,
                  attachmentValue: init$4.attachmentValue
                };
      case "Integer" :
          var init$5 = emptyValue(field);
          return {
                  typeUuid: init$5.typeUuid,
                  dictionaryValue: init$5.dictionaryValue,
                  booleanValue: init$5.booleanValue,
                  stringValue: init$5.stringValue,
                  dateValue: init$5.dateValue,
                  integerValue: makeNumberValue(value._0),
                  decimalValue: init$5.decimalValue,
                  currencyValue: init$5.currencyValue,
                  attachmentValue: init$5.attachmentValue
                };
      case "Attachment" :
          var init$6 = emptyValue(field);
          return {
                  typeUuid: init$6.typeUuid,
                  dictionaryValue: init$6.dictionaryValue,
                  booleanValue: init$6.booleanValue,
                  stringValue: init$6.stringValue,
                  dateValue: init$6.dateValue,
                  integerValue: init$6.integerValue,
                  decimalValue: init$6.decimalValue,
                  currencyValue: init$6.currencyValue,
                  attachmentValue: makePolytypeAnyOrEmptyValue(value._0)
                };
      case "Barcode" :
          var init$7 = emptyValue(field);
          var tmp$1;
          tmp$1 = value._0 === "NotFilled" ? ({
                type: "not_filled",
                value: undefined
              }) : ({
                type: "any",
                value: undefined
              });
          return {
                  typeUuid: init$7.typeUuid,
                  dictionaryValue: init$7.dictionaryValue,
                  booleanValue: init$7.booleanValue,
                  stringValue: tmp$1,
                  dateValue: init$7.dateValue,
                  integerValue: init$7.integerValue,
                  decimalValue: init$7.decimalValue,
                  currencyValue: init$7.currencyValue,
                  attachmentValue: init$7.attachmentValue
                };
      case "Currency" :
          var init$8 = emptyValue(field);
          return {
                  typeUuid: init$8.typeUuid,
                  dictionaryValue: init$8.dictionaryValue,
                  booleanValue: init$8.booleanValue,
                  stringValue: init$8.stringValue,
                  dateValue: init$8.dateValue,
                  integerValue: init$8.integerValue,
                  decimalValue: init$8.decimalValue,
                  currencyValue: makePolytypeAnyOrEmptyValue(value._0),
                  attachmentValue: init$8.attachmentValue
                };
      default:
        return emptyValue(field);
    }
  };
}

var decode$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              jobs: field.required("jobs", Json_Decode$JsonCombinators.array(JobIndexTypes.Job.decode)),
              count: field.required("count", JobIndexTypes.Count.decode)
            };
    });

function buildFilterQuery(options) {
  var templates = options.templates;
  var territories = options.territories;
  var statuses = options.statuses;
  var workers = options.workers;
  var teams = options.teams;
  var resolutions = options.resolutions;
  var canceledResolutions = options.canceledResolutions;
  var scheduledAt = options.scheduledAt;
  var finishedAt = options.finishedAt;
  var client = options.client;
  var createdAt = options.createdAt;
  var suspendedAt = options.suspendedAt;
  var resumedAt = options.resumedAt;
  var predefinedFields = options.predefinedFields;
  var customFields = options.customFields;
  var reportFields = options.reportFields;
  return function (bounds, allResolutions, allTypes, jobFilter, wire) {
    return Case.decamelize({
                templates: makeTemplates(templates),
                territories: makeTerritories(territories),
                statuses: RCore.$$Option.map(arrayToOption(statuses), (function (nonEmptyStatuses) {
                        return nonEmptyStatuses.map(function (status) {
                                    switch (status) {
                                      case "Canceled" :
                                          if (RCore.$$Array.keep(allResolutions, (function (s) {
                                                    return s.type_ === "Unsuccessful";
                                                  })).length !== canceledResolutions.length) {
                                            return encodeWithResolutions(status, canceledResolutions);
                                          } else {
                                            return encodeWithoutResolutions(status);
                                          }
                                      case "Finished" :
                                          if (allResolutions.length !== resolutions.length) {
                                            return encodeWithResolutions(status, resolutions);
                                          } else {
                                            return encodeWithoutResolutions(status);
                                          }
                                      default:
                                        return encodeWithoutResolutions(status);
                                    }
                                  });
                      })),
                assignees: makeAssignees(workers, teams),
                scheduledAt: RCore.$$Option.map(scheduledAt, makeDates(wire)),
                finishedAt: RCore.$$Option.map(finishedAt, makeDates(wire)),
                clients: makeClient(client),
                createdAt: RCore.$$Option.map(createdAt, makeDates(wire)),
                suspendedAt: RCore.$$Option.map(suspendedAt, makeDates(wire)),
                resumedAt: RCore.$$Option.map(resumedAt, makeDates(wire)),
                types: encodeTypeCodes(predefinedFields, allTypes, jobFilter),
                customFields: RCore.$$Option.map(arrayToOption(keepEnabled(customFields, RCore.$$Option.map(jobFilter, (function (f) {
                                    return f.customFields;
                                  })))), (function (arr) {
                        return arr.map(encodeCustomField(wire));
                      })),
                reportFields: RCore.$$Option.map(arrayToOption(keepEnabled(reportFields, RCore.$$Option.map(jobFilter, (function (f) {
                                    return f.reportFields;
                                  })))), (function (arr) {
                        return arr.map(encodeCustomField(wire));
                      })),
                bounds: bounds,
                geolocation: encodeGeolocation(predefinedFields, jobFilter),
                actualDuration: encodeDuration(predefinedFields, jobFilter, "ActualDuration"),
                enRouteDuration: encodeDuration(predefinedFields, jobFilter, "EnRouteDuration"),
                workDuration: encodeDuration(predefinedFields, jobFilter, "WorkDuration"),
                suspensionDuration: encodeDuration(predefinedFields, jobFilter, "SuspensionDuration"),
                completionJobUuids: encodeCompletionJobUuids(predefinedFields, jobFilter),
                mobileNumber: encodeMobileNumber(predefinedFields, jobFilter)
              });
  };
}

function filter(headers, wire, options, bounds, allResolutions, allTypes, jobFilter) {
  return PromiseF.toIOLazy(function () {
              var json = buildFilterQuery(options)(bounds, allResolutions, allTypes, jobFilter, wire);
              return Backend.decode(Backend.get(headers, true, undefined, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "Filter",
                                  VAL: {
                                    filter: JSON.stringify(json)
                                  }
                                }
                              }
                            }), decode$1);
            });
}

var decode$2 = JsonDecode.object(function (field) {
      return {
              filters: field.required("filters", JobIndexTypes.JobFilters.decode)
            };
    });

function encodePredefinedFields(field) {
  return {
          key: JobFieldType.toString(field.key),
          enabled: field.enabled
        };
}

function encodeCreate(predefinedFields, customFields, reportFields) {
  return {
          predefinedFields: predefinedFields.map(encodePredefinedFields),
          customFields: customFields,
          reportFields: reportFields
        };
}

function encodeUpdate(uuid, predefinedFields, customFields, reportFields) {
  return {
          uuid: uuid,
          predefinedFields: predefinedFields.map(encodePredefinedFields),
          customFields: customFields,
          reportFields: reportFields
        };
}

function update(wire, uuid, predefinedFields, customFields, reportFields) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "FilterSettings",
                                  VAL: "Update"
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: {
                                filters: encodeUpdate(uuid, predefinedFields, customFields, reportFields)
                              }
                            }), decode$2);
            });
}

function create(wire, predefinedFields, customFields, reportFields) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "FilterSettings",
                                  VAL: "New"
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: {
                                filters: encodeCreate(predefinedFields, customFields, reportFields)
                              }
                            }), decode$2);
            });
}

function decodeUpdate(wire) {
  return JsonDecode.object(function (field) {
              return {
                      views: field.required("views", JsonDecode.array(JobViewDecoder.decode(JsContext.get(wire), JobIndexIdToUuid.empty)))
                    };
            });
}

function decodeCreate(wire, mappings) {
  return JsonDecode.object(function (field) {
              return {
                      views: field.required("views", JsonDecode.array(JobViewDecoder.decode(JsContext.get(wire), mappings)))
                    };
            });
}

function update$1(wire, views) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.patch(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "ViewSettings",
                                  VAL: "Update"
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: {
                                views: views.map(JobViewEncoder.encode)
                              }
                            }), decodeUpdate(wire));
            });
}

function create$1(wire, view, mappings) {
  return PromiseF.toIOLazy(function () {
              return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                              NAME: "API",
                              VAL: {
                                NAME: "Job",
                                VAL: {
                                  NAME: "ViewSettings",
                                  VAL: "Update"
                                }
                              }
                            }, {
                              NAME: "Obj",
                              VAL: {
                                view: JobViewEncoder.encode(view)
                              }
                            }), decodeCreate(wire, mappings));
            });
}

function toStringwithSite(fields) {
  return Shared_Lib_Array.flatMap((function (field) {
                switch (field) {
                  case "Client" :
                      return [
                              "client",
                              "site"
                            ];
                  case "ClientExternalId" :
                      return [
                              "client_external_id",
                              "site_external_id"
                            ];
                  default:
                    return [Case.decamelizeString(JobFieldType.toString(field))];
                }
              }), fields);
}

function buildDefaultColumnsQuery() {
  return Case.decamelize({
              jobFields: toStringwithSite(JobFieldType.allFields.map(function (param) {
                        return param[0];
                      })),
              customFields: [],
              reportFields: [],
              virtual: JobViewTypes.Virtual.allFields.map(function (v) {
                    return Case.decamelizeString(JobViewTypes.Virtual.toString(v));
                  })
            });
}

function buildColumnsQuery(columns) {
  return Case.decamelize({
              jobFields: toStringwithSite(Belt_Array.concatMany([
                        ["SerialNo"],
                        columns.jobFields
                      ])),
              customFields: columns.customFields,
              reportFields: columns.reportFields,
              virtual: columns.virtual.map(function (v) {
                    return Case.decamelizeString(JobViewTypes.Virtual.toString(v));
                  })
            });
}

function index(wire) {
  return Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Job",
                VAL: "Index"
              }
            });
}

function $$export(wire, currentFilter, columns, data, activeView, exportColumns) {
  var reqColumns = exportColumns === "All" ? buildColumnsQuery(columns) : buildDefaultColumnsQuery();
  var reqFilter = buildFilterQuery(currentFilter)(undefined, data.resolutions, data.types, data.filters, wire);
  var reqViewUuid = RCore.$$Option.map(activeView, Uuid.toString);
  var request = {
    filter: reqFilter,
    columns: reqColumns,
    viewUuid: reqViewUuid
  };
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: "Export"
                  }
                }, {
                  NAME: "Obj",
                  VAL: Utils.jsonToObj(request)
                }), BackgroundTasksApi.$$Response.decode);
}

function create$2(operation, operationSet, currentFilter, data, wire) {
  var reqFilter = buildFilterQuery(currentFilter)(undefined, data.resolutions, data.types, data.filters, wire);
  var request_operation = JobIndexTypes.BulkOperation.encode(operation);
  var request_operationSet = JobIndexTypes.OperationSet.encode(reqFilter)(operationSet);
  var request = {
    operation: request_operation,
    operationSet: request_operationSet
  };
  return Backend.decode(Backend.post(undefined, undefined, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Job",
                    VAL: "Bulk"
                  }
                }, {
                  NAME: "Obj",
                  VAL: Utils.jsonToObj(request)
                }), BackgroundTasksApi.$$Response.decode);
}

var OperationRequest = {
  create: create$2
};

var Search_Response = {};

var Search = {
  $$Response: Search_Response,
  search: search
};

var Filter_Response = {};

var Filter = {
  $$Response: Filter_Response,
  filter: filter
};

var JobFilterSettings_Response = {};

var JobFilterSettings = {
  $$Response: JobFilterSettings_Response,
  update: update,
  create: create
};

var JobViewSettings_Response = {};

var JobViewSettings = {
  $$Response: JobViewSettings_Response,
  update: update$1,
  create: create$1
};

var Index = {
  index: index,
  $$export: $$export,
  OperationRequest: OperationRequest
};

export {
  Search ,
  Filter ,
  JobFilterSettings ,
  JobViewSettings ,
  Index ,
}
/* decode Not a pure module */
