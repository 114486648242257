

import * as El from "../../libraries/El.mjs";
import * as Icon from "../../Icon.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Title from "../common/Title.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Portal from "../../Portal.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as AppContext from "../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ModalModuleCss from "/home/runner/work/planado/planado/client/rescript/components/modal/Modal.module.css";

var styles = ModalModuleCss;

function toString(action) {
  return "close-" + action.VAL;
}

function fromString(string) {
  var match = Js_string.splitAtMost("-", 1, string)[0];
  if (match === "close") {
    return {
            NAME: "Close",
            VAL: (function (__x) {
                  return Js_string.replace("close-", "", __x);
                })(string)
          };
  }
  
}

var initContext = React.createContext(undefined);

function use() {
  return RCore.$$Option.getExn(React.useContext(initContext), undefined);
}

var make = initContext.Provider;

function makeProps(value, children, param) {
  return {
          value: Caml_option.some(value),
          children: children
        };
}

var cancel$p = Utils.Translations.tr("common.cancel");

var loading$p = Utils.Translations.t("common.loading");

var Translations = {
  cancel$p: cancel$p,
  loading$p: loading$p
};

function closeAction(id, el) {
  var action = {
    NAME: "Close",
    VAL: id
  };
  return React.cloneElement(el, {
              "data-modal-action": toString(action)
            });
}

function Modal$Header(Props) {
  var className = Props.className;
  var children = Props.children;
  var id = use();
  return React.createElement("div", {
              className: RCore.$$Option.getOr(className, "p-modal-header")
            }, children, closeAction(id, React.createElement("i", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, undefined, "times"), styles.close)
                    })));
}

var Header = {
  make: Modal$Header
};

function Modal$HeaderTitle(Props) {
  var values = Props.values;
  var permissionOpt = Props.permission;
  var permission = permissionOpt !== undefined ? permissionOpt : "ViewEdit";
  var match = AppContext.useWire();
  var route = match.route;
  var ctx = match.ctx;
  var title = React.useMemo((function () {
          var title = Title.title(values, permission, route, ctx);
          if (title !== undefined) {
            return title;
          } else {
            return loading$p(ctx);
          }
        }), [
        route,
        values
      ]);
  return React.createElement(Modal$Header, {
              children: title
            });
}

var HeaderTitle = {
  make: Modal$HeaderTitle
};

function Modal$CloseButton(Props) {
  var ctx = Props.ctx;
  var labelOpt = Props.label;
  var buttonClass = Props.buttonClass;
  var label = labelOpt !== undefined ? Caml_option.valFromOption(labelOpt) : cancel$p(ctx);
  var id = use();
  var buttonCN = "btn " + styles.closeBtn + RCore.$$Option.getOr(RCore.$$Option.map(buttonClass, (function (v) {
              return " " + v;
            })), "");
  return closeAction(id, React.createElement("button", {
                  className: buttonCN
                }, label));
}

var CloseButton = {
  make: Modal$CloseButton
};

function Modal$SubmitButton(Props) {
  var onClick = Props.onClick;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  return React.createElement("button", {
              className: "p-modal-btn-submit p-modal-btn btn btn-primary",
              disabled: disabled,
              onClick: onClick
            }, children);
}

var SubmitButton = {
  make: Modal$SubmitButton
};

function Modal$RemoveButton(Props) {
  var onClick = Props.onClick;
  var id = Props.id;
  var forwardRef = Props.forwardRef;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var tmp = {
    className: "p-modal-btn btn btn-danger " + styles.removeBtn,
    disabled: disabled
  };
  if (forwardRef !== undefined) {
    tmp.ref = Caml_option.valFromOption(forwardRef);
  }
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("button", tmp, children);
}

var RemoveButton = {
  make: Modal$RemoveButton
};

var noStyle = {};

function Modal$Body(Props) {
  var className = Props.className;
  var styleOpt = Props.style;
  var children = Props.children;
  var style = styleOpt !== undefined ? styleOpt : noStyle;
  return React.createElement("div", {
              className: RCore.$$Option.getOr(className, "p-modal-body"),
              style: style
            }, children);
}

function Modal$Footer(Props) {
  var className = Props.className;
  var children = Props.children;
  return React.createElement("footer", {
              className: RCore.$$Option.getOr(className, styles.footer)
            }, children);
}

var Footer = {
  make: Modal$Footer
};

function Modal$Dialog(Props) {
  var className = Props.className;
  var levelOpt = Props.level;
  var children = Props.children;
  var level = levelOpt !== undefined ? levelOpt : "Outer";
  var dialogClassName;
  dialogClassName = level === "Outer" ? styles.dialog : styles.dialogNested;
  return React.createElement("div", {
              className: El.Cn.concatOpt(dialogClassName, className)
            }, children);
}

var Dialog = {
  make: Modal$Dialog
};

var bodyOverlay = React.createElement("div", {
      className: styles.bodyOverlay
    });

function Modal(Props) {
  var param = Props.wire;
  var $staropt$star = Props.onClose;
  var $staropt$star$1 = Props.show;
  var children = Props.children;
  var subscriptions = param.subscriptions;
  var onClose = $staropt$star !== undefined ? $staropt$star : (function (prim) {
        
      });
  var show = $staropt$star$1 !== undefined ? $staropt$star$1 : true;
  var showRef = React.useRef(show);
  var id = Hooks.useId(undefined);
  var galleryModalOpened = function () {
    return Utils.getElementById("react-images__pager") !== undefined;
  };
  var keyDown = function (param) {
    var next = param[1];
    var $$event = param[0];
    var match = $$event.code;
    if (match === "Escape" && showRef.current && !galleryModalOpened()) {
      return onClose();
    } else {
      return next($$event);
    }
  };
  if (showRef.current !== show) {
    DomUtils.toggleWindowLock(show);
    showRef.current = show;
  }
  var onDialogClick = function ($$event) {
    var target = $$event.target;
    var dataset = target.dataset;
    var action = RCore.$$Option.flatMap(Caml_option.nullable_to_opt(dataset.modalAction), fromString);
    if (action !== undefined && action.VAL === id) {
      return onClose();
    }
    
  };
  React.useEffect((function () {
          if (show) {
            DomUtils.lockWindow();
          }
          return (function () {
                    if (showRef.current) {
                      return DomUtils.unlockWindow();
                    }
                    
                  });
        }), []);
  React.useEffect((function () {
          if (show) {
            return subscriptions.keydown.subscribe(keyDown);
          }
          
        }), [show]);
  if (show) {
    return React.createElement(Portal.make, {
                root: param.footer,
                children: React.createElement(make, makeProps(id, React.createElement("div", {
                              className: styles.container
                            }, React.createElement("div", {
                                  className: styles.overlay
                                }), React.createElement("div", {
                                  className: styles.dialogContainer,
                                  onClick: onDialogClick
                                }, children)), undefined))
              });
  } else {
    return null;
  }
}

var Body = {
  make: Modal$Body
};

var make$1 = Modal;

var $$default = Modal;

export {
  Translations ,
  Header ,
  HeaderTitle ,
  CloseButton ,
  SubmitButton ,
  RemoveButton ,
  Body ,
  Footer ,
  Dialog ,
  bodyOverlay ,
  make$1 as make,
  $$default as default,
}
/* styles Not a pure module */
