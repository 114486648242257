

import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";

var allFields = [
  [
    "SerialNo",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ExternalId",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Address",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "AddressDescription",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "EntranceNo",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Floor",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Client",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ClientExternalId",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "MobileNumber",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "PrimaryContactName",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "PrimaryContactMobile",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "SecondaryContactName",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "SecondaryContactMobile",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Template",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Skills",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Assignee",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Assignees",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "AssigneesCount",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ScheduledAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ViewedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "EnRouteAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "StartedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "SuspendedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ResumedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "FinishedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ScheduledDuration",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "EnRouteDuration",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "WorkDuration",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "SuspensionDuration",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ActualDuration",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Geolocation",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "StartedLocation",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "FinishedLocation",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Status",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ResolutionName",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "ResolutionComment",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "TypeCode",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Description",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "CreatedAt",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Creator",
    (function (param) {
        return true;
      }),
    false
  ],
  [
    "Territory",
    (function (ctx) {
        return Context_Types.Features.hasFlag("territories", ctx.features);
      }),
    true
  ],
  [
    "CompletionJobs",
    (function (ctx) {
        return Context_Types.Features.hasFlag("linkedJobs", ctx.features);
      }),
    false
  ],
  [
    "ExternalResourceLink",
    (function (ctx) {
        return Context_Types.Features.hasAnyFlag([
                    "bitrix24",
                    "amocrm"
                  ], ctx.features);
      }),
    false
  ]
];

function toString(jobField) {
  switch (jobField) {
    case "SerialNo" :
        return "serialNo";
    case "ExternalId" :
        return "externalId";
    case "Address" :
        return "address";
    case "AddressDescription" :
        return "addressDescription";
    case "Geolocation" :
        return "geolocation";
    case "EntranceNo" :
        return "entranceNo";
    case "Floor" :
        return "floor";
    case "Client" :
        return "client";
    case "ClientExternalId" :
        return "clientExternalId";
    case "MobileNumber" :
        return "mobileNumber";
    case "PrimaryContactName" :
        return "primaryContactName";
    case "PrimaryContactMobile" :
        return "primaryContactMobile";
    case "SecondaryContactName" :
        return "secondaryContactName";
    case "SecondaryContactMobile" :
        return "secondaryContactMobile";
    case "Template" :
        return "template";
    case "Skills" :
        return "skills";
    case "Assignee" :
        return "assignee";
    case "Assignees" :
        return "assignees";
    case "AssigneesCount" :
        return "assigneesCount";
    case "ScheduledAt" :
        return "scheduledAt";
    case "EnRouteAt" :
        return "enRouteAt";
    case "StartedAt" :
        return "startedAt";
    case "FinishedAt" :
        return "finishedAt";
    case "SuspendedAt" :
        return "suspendedAt";
    case "ResumedAt" :
        return "resumedAt";
    case "ViewedAt" :
        return "viewedAt";
    case "ScheduledDuration" :
        return "scheduledDuration";
    case "EnRouteDuration" :
        return "enRouteDuration";
    case "WorkDuration" :
        return "workDuration";
    case "SuspensionDuration" :
        return "suspensionDuration";
    case "ActualDuration" :
        return "actualDuration";
    case "Status" :
        return "status";
    case "ResolutionName" :
        return "resolutionName";
    case "ResolutionComment" :
        return "resolutionComment";
    case "TypeCode" :
        return "typeCode";
    case "Description" :
        return "description";
    case "CreatedAt" :
        return "createdAt";
    case "Creator" :
        return "creator";
    case "Territory" :
        return "territory";
    case "StartedLocation" :
        return "startedLocation";
    case "FinishedLocation" :
        return "finishedLocation";
    case "CompletionJobs" :
        return "completionJobs";
    case "ExternalResourceLink" :
        return "externalResourceLink";
    
  }
}

function fromString(value) {
  switch (value) {
    case "actualDuration" :
        return "ActualDuration";
    case "address" :
        return "Address";
    case "addressDescription" :
        return "AddressDescription";
    case "assignee" :
        return "Assignee";
    case "assignees" :
        return "Assignees";
    case "assigneesCount" :
        return "AssigneesCount";
    case "client" :
        return "Client";
    case "clientExternalId" :
        return "ClientExternalId";
    case "completionJobs" :
        return "CompletionJobs";
    case "createdAt" :
        return "CreatedAt";
    case "creator" :
        return "Creator";
    case "description" :
        return "Description";
    case "enRouteAt" :
        return "EnRouteAt";
    case "enRouteDuration" :
        return "EnRouteDuration";
    case "entranceNo" :
        return "EntranceNo";
    case "externalId" :
        return "ExternalId";
    case "externalResourceLink" :
        return "ExternalResourceLink";
    case "finishedAt" :
        return "FinishedAt";
    case "finishedLocation" :
        return "FinishedLocation";
    case "floor" :
        return "Floor";
    case "geolocation" :
        return "Geolocation";
    case "mobileNumber" :
        return "MobileNumber";
    case "primaryContactMobile" :
        return "PrimaryContactMobile";
    case "primaryContactName" :
        return "PrimaryContactName";
    case "note" :
    case "resolutionComment" :
        return "ResolutionComment";
    case "resolutionName" :
        return "ResolutionName";
    case "resumedAt" :
        return "ResumedAt";
    case "scheduledAt" :
        return "ScheduledAt";
    case "scheduledDuration" :
        return "ScheduledDuration";
    case "secondaryContactMobile" :
        return "SecondaryContactMobile";
    case "secondaryContactName" :
        return "SecondaryContactName";
    case "skills" :
        return "Skills";
    case "startedAt" :
        return "StartedAt";
    case "startedLocation" :
        return "StartedLocation";
    case "status" :
        return "Status";
    case "suspendedAt" :
        return "SuspendedAt";
    case "suspensionDuration" :
        return "SuspensionDuration";
    case "template" :
        return "Template";
    case "territory" :
        return "Territory";
    case "typeCode" :
        return "TypeCode";
    case "viewedAt" :
        return "ViewedAt";
    case "workDuration" :
        return "WorkDuration";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

export {
  allFields ,
  toString ,
  decode ,
}
/* decode Not a pure module */
