

import * as El from "../../../../../libraries/El.mjs";
import * as Icon from "../../../../../Icon.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../inputs/Checkbox.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as AppContext from "../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JobFieldType from "../../JobFieldType.mjs";
import * as JobViewTypes from "../JobViewTypes.mjs";
import * as Context_Types from "../../../../../context/Context_Types.mjs";
import * as JobFieldsList from "../../JobFieldsList.mjs";
import * as JobIndexFilter_FieldsTranslations from "../../job_index_filter/common/JobIndexFilter_FieldsTranslations.mjs";
import * as ColumnsPopoverModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/columns/ColumnsPopover.module.css";

var photoArchiveUrl$p = Utils.Translations.t("js.jobs.index.heading.photo_archive_url");

var photoArchiveUrlShared$p = Utils.Translations.t("js.jobs.index.heading.photo_archive_url_shared");

function odometerM$p(ctx) {
  var match = ctx.localizator.measurementSystem;
  if (typeof match !== "object" && match === "Imperial") {
    return Utils.Translations.t("js.jobs.index.heading.odometer_m_imperial")(ctx);
  } else {
    return Utils.Translations.t("js.jobs.index.heading.odometer_m")(ctx);
  }
}

var modalName$p = Utils.Translations.tr("js.jobs.index.views.columns.modal_name");

var styles = ColumnsPopoverModuleCss;

function checkbox(key, onToggle, checked, label, disabled) {
  return React.createElement("div", {
              key: key,
              className: styles.checkbox
            }, React.createElement(Checkbox.make, {
                  onToggle: onToggle,
                  checked: checked,
                  label: label,
                  disabled: disabled
                }));
}

function ColumnsPopover(Props) {
  var currentColumns = Props.currentColumns;
  var customFields = Props.customFields;
  var reportFields = Props.reportFields;
  var toggleVirtualColumn = Props.toggleVirtualColumn;
  var toggleCustomColumn = Props.toggleCustomColumn;
  var toggleReportColumn = Props.toggleReportColumn;
  var toggleJobColumn = Props.toggleJobColumn;
  var hidePopover = Props.hidePopover;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var jobFields = React.useMemo((function () {
          return RCore.$$Array.filterMap(JobFieldType.allFields, (function (param) {
                        if (param[1](ctx)) {
                          return param[0];
                        }
                        
                      }));
        }), [ctx.features]);
  var jobFieldsCheckboxes = jobFields.map(function (c) {
        return checkbox(JobFieldType.toString(c), (function (param) {
                      if (c === "SerialNo") {
                        return ;
                      } else {
                        return toggleJobColumn(c);
                      }
                    }), c === "SerialNo" || currentColumns.jobFields.some(function (j) {
                        return j === c;
                      }), JobIndexFilter_FieldsTranslations.jobFieldsHeader$p(c)(ctx), c === "SerialNo");
      });
  var customFieldsCheckboxes = customFields.length === 0 ? El.ndash : customFields.map(function (column) {
          return checkbox(Uuid.toString(column.uuid), (function (param) {
                        toggleCustomColumn(column.uuid);
                      }), Js_array.includes(column.uuid, currentColumns.customFields), column.name, false);
        });
  var photoArchiveCheckbox = checkbox(JobViewTypes.Virtual.toString("PhotoArchiveUrl"), (function (param) {
          toggleVirtualColumn("PhotoArchiveUrl");
        }), currentColumns.virtual.some(function (j) {
            return j === "PhotoArchiveUrl";
          }), photoArchiveUrl$p(ctx), false);
  var photoArchiveSharedCheckbox = checkbox(JobViewTypes.Virtual.toString("PhotoArchiveUrlShared"), (function (param) {
          toggleVirtualColumn("PhotoArchiveUrlShared");
        }), currentColumns.virtual.some(function (j) {
            return j === "PhotoArchiveUrlShared";
          }), photoArchiveUrlShared$p(ctx), false);
  var trackingCheckbox = React.createElement(Optional.make, {
        show: Context_Types.Features.hasFlag("tracking", ctx.features),
        children: checkbox(JobViewTypes.Virtual.toString("OdometerM"), (function (param) {
                toggleVirtualColumn("OdometerM");
              }), currentColumns.virtual.some(function (j) {
                  return j === "OdometerM";
                }), odometerM$p(ctx), false)
      });
  var other = reportFields.map(function (field) {
        return checkbox(Uuid.toString(field.uuid), (function (param) {
                      toggleReportColumn(field.uuid);
                    }), currentColumns.reportFields.some(function (j) {
                        return Caml_obj.equal(j, field.uuid);
                      }), field.name, false);
      });
  var reportFieldsCheckboxes = Belt_Array.concatMany([
        [
          photoArchiveCheckbox,
          photoArchiveSharedCheckbox
        ],
        other,
        [trackingCheckbox]
      ]);
  return React.createElement("div", {
              className: "JobsViews-columns"
            }, React.createElement("header", {
                  className: "JobsViews-columns__header"
                }, React.createElement("div", {
                      className: "JobsViews-columns__header-headline"
                    }, modalName$p(ctx)), React.createElement("a", {
                      className: "JobsViews-columns__header-close",
                      onClick: (function (param) {
                          hidePopover();
                        })
                    }, React.createElement("span", {
                          className: Icon.style(undefined, undefined, undefined, "times")
                        }))), React.createElement(JobFieldsList.make, {
                  jobFields: jobFieldsCheckboxes,
                  customFields: customFieldsCheckboxes,
                  reportFields: reportFieldsCheckboxes
                }));
}

var make = ColumnsPopover;

export {
  make ,
}
/* photoArchiveUrl' Not a pure module */
