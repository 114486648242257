

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Link from "../../../../../common/Link/Link.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../../../../../types/Wire.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as Backend from "../../../../../../libraries/backend/Backend.mjs";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ModalQuery from "../../../../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Array from "../../../../../../shared/lib/Shared_Lib_Array.mjs";
import * as JobForm_LinkedJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/linked_jobs/JobForm_LinkedJobs.module.css";

var styles = JobForm_LinkedJobsModuleCss;

var header$p = Utils.Translations.tr("js.jobs.linked.header");

var completionsJobs$p = Utils.Translations.tr("js.jobs.linked.completions_jobs");

var parentDeleted$p = Utils.Translations.tr("js.jobs.linked.job_removed");

var withoutTemplate$p = Utils.Translations.t("js.jobs.linked.without_template");

var withoutAssignee$p = Utils.Translations.t("js.jobs.linked.without_assignee");

function status$p(status) {
  switch (status) {
    case "Posted" :
        return Utils.Translations.tr("js.jobs.statuses.posted");
    case "Scheduled" :
        return Utils.Translations.tr("js.jobs.statuses.scheduled");
    case "Published" :
        return Utils.Translations.tr("js.jobs.statuses.published");
    case "EnRoute" :
        return Utils.Translations.tr("js.jobs.statuses.en_route");
    case "Started" :
        return Utils.Translations.tr("js.jobs.statuses.started");
    case "Suspended" :
        return Utils.Translations.tr("js.jobs.statuses.suspended");
    case "Finished" :
        return Utils.Translations.tr("js.jobs.statuses.finished");
    case "Canceled" :
        return Utils.Translations.tr("js.jobs.statuses.canceled");
    
  }
}

var finished$p = Utils.Translations.tr("js.jobs.statuses.finished");

function JobForm_LinkedJobs$Item(Props) {
  var job = Props.job;
  var currentJob = Props.currentJob;
  var completion = Props.completion;
  var openLinkedJob = Props.openLinkedJob;
  var showLinkedJobs = Props.showLinkedJobs;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var formatDate = function (v) {
    return Locale.T.fmtM("DateFull", wire)(v);
  };
  var formatTime = function (v) {
    return Locale.T.fmtM("Time", wire)(v);
  };
  var modal_1 = {
    NAME: "Edit",
    VAL: ModalQuery.Job.EditOptions.make(undefined, showLinkedJobs, job.uuid)
  };
  var modal = {
    NAME: "Job",
    VAL: modal_1
  };
  var match = job.status;
  var renderStatus;
  if (match === "Finished") {
    var match$1 = Fun.both(job.resolutionName, job.resolutionSuccessful);
    if (match$1 !== undefined) {
      var resolutionCN = El.Cn.concat(match$1[1] ? styles.successful : styles.unsuccessful, styles.resolution);
      renderStatus = React.createElement("div", {
            className: resolutionCN
          }, match$1[0]);
    } else {
      renderStatus = finished$p(ctx);
    }
  } else {
    renderStatus = status$p(job.status)(ctx);
  }
  var temp = currentJob ? El.Cn.concat(styles.row, styles.current) : styles.row;
  var rowCN = completion ? El.Cn.concat(temp, styles.completion) : temp;
  return React.createElement("tr", {
              className: El.Cn.concat(rowCN, "linked-row"),
              onClick: (function (_evt) {
                  openLinkedJob(job.uuid, showLinkedJobs);
                })
            }, React.createElement("td", {
                  className: El.Cn.concat(styles.cell, styles.firstCell)
                }, React.createElement(Link.make, {
                      wire: wire,
                      route: {
                        NAME: "Job",
                        VAL: "Index"
                      },
                      modals: {
                        hd: modal,
                        tl: /* [] */0
                      },
                      target: "blank",
                      children: job.serialNo.toString()
                    })), React.createElement("td", {
                  className: styles.cell
                }, RCore.$$Option.getOr(job.template, withoutTemplate$p(ctx))), React.createElement("td", {
                  className: El.Cn.concat(styles.cell, styles.alignEnd)
                }, RCore.$$Option.getOr(RCore.$$Option.map(job.scheduledAt, formatDate), null)), React.createElement("td", {
                  className: styles.cell
                }, RCore.$$Option.getOr(RCore.$$Option.map(job.scheduledAt, formatTime), null)), React.createElement("td", {
                  className: styles.cell
                }, RCore.$$Option.getOr(job.assignee, withoutAssignee$p(ctx))), React.createElement("td", {
                  className: El.Cn.concat(styles.cell, styles.alignEnd)
                }, renderStatus));
}

function JobForm_LinkedJobs(Props) {
  var inputNextJobs = Props.nextJobs;
  var inputCompletionJobs = Props.completionJobs;
  var currentJobUuid = Props.currentJobUuid;
  var subroute = Props.subroute;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        if (subroute.NAME === "Edit") {
          return ModalQuery.Job.EditOptions.showLinkedJobs(subroute.VAL);
        } else {
          return false;
        }
      });
  var setShowLinkedJobs = match[1];
  var showLinkedJobs = match[0];
  var nextJobs = inputNextJobs.map(function (prim) {
        return prim;
      });
  var openLinkedJob = function (uuid, showLinkedJobs) {
    Backend.$$finally(Backend.get(undefined, undefined, undefined, wire, {
              NAME: "API",
              VAL: {
                NAME: "Job",
                VAL: {
                  NAME: "Edit",
                  VAL: uuid
                }
              }
            }), (function (x) {
            if (x.TAG === "Ok") {
              return Wire.openJob(Caml_option.some(x._0), showLinkedJobs, wire, uuid);
            }
            
          }));
  };
  var iconCN = showLinkedJobs ? Icon.style(undefined, undefined, undefined, "caret-down") : Icon.style(undefined, undefined, undefined, "caret-right");
  var headerCaret = React.createElement("span", {
        className: El.Cn.concat(iconCN, styles.icon)
      });
  var allJobsCounter = React.useMemo((function () {
          return nextJobs.length + inputCompletionJobs.length | 0;
        }), [
        inputNextJobs,
        inputCompletionJobs
      ]);
  var completionWithoutParent = RCore.$$Array.keep(inputCompletionJobs, (function (job) {
          return RCore.$$Option.isNone(RCore.$$Array.getBy(nextJobs, (function (nj) {
                            return Uuid.equal(nj.uuid, job.completionOfUuid);
                          })));
        }));
  var allJobs = Belt_Array.concatMany([
        nextJobs.flatMap(function (nextJob) {
              return Belt_Array.concatMany([
                          [nextJob],
                          RCore.$$Array.filterMap(inputCompletionJobs, (function (completionJob) {
                                  if (Uuid.equal(completionJob.completionOfUuid, nextJob.uuid)) {
                                    return completionJob;
                                  }
                                  
                                }))
                        ]);
            }),
        completionWithoutParent.map(function (prim) {
              return prim;
            })
      ]);
  var currentNumber = RCore.$$Option.getOr(RCore.$$Array.findIndexOpt(allJobs, (function (j) {
              return Uuid.equal(j.uuid, currentJobUuid);
            })), 0);
  var nextUuid = RCore.$$Option.map(allJobs[currentNumber + 1 | 0], (function (param) {
          return param.uuid;
        }));
  var prevUuid = RCore.$$Option.map(allJobs[currentNumber - 1 | 0], (function (param) {
          return param.uuid;
        }));
  var currentNumber$1 = currentNumber + 1 | 0;
  var renderJobNavigate = function (uuid, showLinkedJobs, icon) {
    if (uuid === undefined) {
      return React.createElement("div", {
                  className: El.Cn.concat(El.Cn.concat(styles.navigateButton, icon), styles.disabledIcon)
                });
    }
    var uuid$1 = Caml_option.valFromOption(uuid);
    return React.createElement("div", {
                className: El.Cn.concat(styles.navigateButton, icon),
                onClick: (function (_evt) {
                    openLinkedJob(uuid$1, showLinkedJobs);
                  })
              });
  };
  var renderDeletedRow = function () {
    return React.createElement("tr", {
                className: El.Cn.concat(styles.row, styles.deletedRow)
              }, React.createElement("td", {
                    colSpan: 6
                  }, parentDeleted$p(ctx)));
  };
  var renderWithoutParent = function () {
    var withoutParentUniq = Shared_Lib_Array.distinctBy((function (a, b) {
            return Uuid.equal(a.completionOfUuid, b.completionOfUuid);
          }), completionWithoutParent);
    return withoutParentUniq.map(function (job) {
                return React.createElement(React.Fragment, undefined, renderDeletedRow(), React.createElement("div", {
                                key: El.Cn.concat(Uuid.toString(job.completionOfUuid), "-seperator"),
                                className: styles.completionConnector
                              }, React.createElement("span", undefined, completionsJobs$p(ctx))), RCore.$$Array.keep(inputCompletionJobs, (function (j) {
                                      return Uuid.equal(job.completionOfUuid, j.completionOfUuid);
                                    })).map(function (prim) {
                                  return prim;
                                }).map(function (completionJob) {
                                return React.createElement(JobForm_LinkedJobs$Item, {
                                            job: completionJob,
                                            currentJob: Uuid.equal(currentJobUuid, completionJob.uuid),
                                            completion: true,
                                            openLinkedJob: openLinkedJob,
                                            showLinkedJobs: showLinkedJobs,
                                            key: Uuid.toString(completionJob.uuid)
                                          });
                              }));
              });
  };
  return React.createElement("div", {
              className: El.Cn.concat(styles.container, "linked-jobs")
            }, React.createElement("div", {
                  className: styles.header
                }, React.createElement("div", {
                      className: styles.iconContainer,
                      onClick: (function (param) {
                          setShowLinkedJobs(function (prevState) {
                                return !prevState;
                              });
                        })
                    }, headerCaret, React.createElement("span", undefined, header$p(ctx))), React.createElement("div", {
                      className: styles.jobsNavigation
                    }, React.createElement("div", undefined, Utils.Translations.concatEl([
                              currentNumber$1.toString(),
                              React.createElement("span", {
                                    className: styles.disabledIcon
                                  }, " / "),
                              React.createElement("span", {
                                    className: styles.disabledIcon
                                  }, allJobsCounter.toString())
                            ], ctx)), renderJobNavigate(prevUuid, showLinkedJobs, Icon.style(undefined, undefined, undefined, "caret-up")), renderJobNavigate(nextUuid, showLinkedJobs, Icon.style(undefined, undefined, undefined, "caret-down")))), React.createElement(Optional.make, {
                  show: showLinkedJobs,
                  children: React.createElement("div", {
                        className: styles.jobsContainer
                      }, React.createElement("table", undefined, React.createElement("tbody", undefined, nextJobs.map(function (nextJob) {
                                    var currentCompletionJobs = RCore.$$Array.keep(inputCompletionJobs, (function (completionJob) {
                                              return Uuid.equal(completionJob.completionOfUuid, nextJob.uuid);
                                            })).map(function (prim) {
                                          return prim;
                                        });
                                    return React.createElement(React.Fragment, undefined, React.createElement(JobForm_LinkedJobs$Item, {
                                                    job: nextJob,
                                                    currentJob: Uuid.equal(currentJobUuid, nextJob.uuid),
                                                    completion: false,
                                                    openLinkedJob: openLinkedJob,
                                                    showLinkedJobs: showLinkedJobs,
                                                    key: Uuid.toString(nextJob.uuid)
                                                  }), React.createElement(Optional.make, {
                                                    show: currentCompletionJobs.length > 0,
                                                    children: null
                                                  }, React.createElement("div", {
                                                        key: El.Cn.concat(Uuid.toString(nextJob.uuid), "-seperator"),
                                                        className: styles.completionConnector
                                                      }, React.createElement("span", undefined, completionsJobs$p(ctx))), currentCompletionJobs.map(function (completionJob) {
                                                        return React.createElement(JobForm_LinkedJobs$Item, {
                                                                    job: completionJob,
                                                                    currentJob: Uuid.equal(currentJobUuid, completionJob.uuid),
                                                                    completion: true,
                                                                    openLinkedJob: openLinkedJob,
                                                                    showLinkedJobs: showLinkedJobs,
                                                                    key: Uuid.toString(completionJob.uuid)
                                                                  });
                                                      }), React.createElement("div", {
                                                        className: styles.completionEnd
                                                      })));
                                  }), renderWithoutParent())))
                }));
}

var make = JobForm_LinkedJobs;

export {
  make ,
}
/* styles Not a pure module */
