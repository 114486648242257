

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../common/Optional.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as TableModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Table.module.css";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var styles = Catalog_IndexModuleCss;

var tableStyles = TableModuleCss;

var noResults$p = Utils.Translations.tr("js.admin.catalog.no_results");

function Catalog_ListEntities(Props) {
  var ctx = Props.ctx;
  var entities = Props.entities;
  var title = Props.title;
  var noEntries = Props.noEntries;
  var opened = Props.opened;
  var renderRow = Props.renderRow;
  var renderNewForm = Props.renderNewForm;
  var renderEditForm = Props.renderEditForm;
  var header = Props.header;
  var filter = Props.filter;
  var filterRequest = Props.filterRequest;
  var match = React.useState(function () {
        return false;
      });
  var setAddNew = match[1];
  var onOpenNew = function (e) {
    e.preventDefault();
    setAddNew(function (param) {
          return true;
        });
  };
  var onCloseNew = function () {
    setAddNew(function (param) {
          return false;
        });
  };
  return React.createElement("div", {
              className: "panel-body"
            }, React.createElement(Optional.make, {
                  show: Context_Types.Features.hasFlag("catalog", ctx.features),
                  children: React.createElement("div", {
                        className: styles.header
                      }, React.createElement("a", {
                            className: "add-link btn btn-outline btn-32px",
                            onClick: onOpenNew
                          }, React.createElement("i", {
                                className: Icon.style(undefined, undefined, undefined, "plus")
                              }), El.space, title), React.createElement(Optional.make, {
                            show: entities.length > 15 || filterRequest,
                            children: filter
                          }))
                }), entities.length === 0 ? React.createElement("div", {
                    className: styles.noEntriesContainer
                  }, React.createElement("span", {
                        className: El.Cn.concat(Icon.style(undefined, undefined, undefined, "tags"), styles.tags)
                      }), filterRequest ? React.createElement("p", {
                          className: styles.noEntries
                        }, noResults$p(ctx)) : React.createElement("p", {
                          className: styles.noEntries
                        }, noEntries)) : React.createElement("table", {
                    className: El.Cn.concat(tableStyles.table, styles.dataTable)
                  }, header, React.createElement("tbody", undefined, entities.map(function (e) {
                            return renderRow(e);
                          }))), React.createElement(Optional.make, {
                  show: match[0],
                  children: renderNewForm(onCloseNew)
                }), opened !== undefined ? renderEditForm(Caml_option.valFromOption(opened)) : null);
}

var make = Catalog_ListEntities;

export {
  make ,
}
/* styles Not a pure module */
