

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../common/Optional.mjs";
import * as AppContext from "../../../../context/AppContext.mjs";
import * as JobFieldType from "../JobFieldType.mjs";
import * as JobViewTypes from "../views/JobViewTypes.mjs";
import * as JobIndexTypes from "../JobIndexTypes.mjs";
import * as JobIndexFilter_Dates from "./components/filters/JobIndexFilter_Dates.mjs";
import * as JobIndexFilter_Types from "./common/JobIndexFilter_Types.mjs";
import * as JobIndexFilter_Clients from "./components/filters/JobIndexFilter_Clients.mjs";
import * as JobIndexFilter_Statuses from "./components/filters/JobIndexFilter_Statuses.mjs";
import * as JobIndexFilter_Assignees from "./components/filters/JobIndexFilter_Assignees.mjs";
import * as JobIndexFilter_CreatedAt from "./components/filters/JobIndexFilter_CreatedAt.mjs";
import * as JobIndexFilter_ResumedAt from "./components/filters/JobIndexFilter_ResumedAt.mjs";
import * as JobIndexFilter_Templates from "./components/filters/JobIndexFilter_Templates.mjs";
import * as JobIndexFilter_FinishedAt from "./components/filters/JobIndexFilter_FinishedAt.mjs";
import * as JobIndexFilter_ScheduledAt from "./components/filters/JobIndexFilter_ScheduledAt.mjs";
import * as JobIndexFilter_SuspendedAt from "./components/filters/JobIndexFilter_SuspendedAt.mjs";
import * as JobIndexFilter_Territories from "./components/filters/JobIndexFilter_Territories.mjs";
import * as JobIndexFilter_CustomNumber from "./components/custom/JobIndexFilter_CustomNumber.mjs";
import * as JobIndexFilter_CustomString from "./components/custom/JobIndexFilter_CustomString.mjs";
import * as JobIndexFilter_CustomBoolean from "./components/custom/JobIndexFilter_CustomBoolean.mjs";
import * as JobIndexFilter_SettingsButton from "./components/settings/JobIndexFilter_SettingsButton.mjs";
import * as JobIndexFilter_CustomDictionary from "./components/custom/JobIndexFilter_CustomDictionary.mjs";
import * as JobIndexFilter_PredefinedAnyOrEmpty from "./components/filters/JobIndexFilter_PredefinedAnyOrEmpty.mjs";

var typeCode$p = Utils.Translations.t("js.jobs.index.heading.type_code");

var mobileNumber$p = Utils.Translations.t("js.jobs.index.heading.mobile_number");

function JobIndexFilter(Props) {
  var data = Props.data;
  var currentFilter = Props.currentFilter;
  var activeFilters = Props.activeFilters;
  var activeView = Props.activeView;
  var filterDisabled = Props.filterDisabled;
  var dictionaries = Props.dictionaries;
  var send = Props.send;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var allPredefinedFields = function (ctx, savedFields) {
    var calcEnabled = function (field, $$default) {
      var f = RCore.$$Array.getBy(savedFields, (function (f) {
              return field === f.key;
            }));
      if (f !== undefined) {
        return f.enabled;
      } else {
        return $$default;
      }
    };
    return RCore.$$Array.filterMap(JobFieldType.allFields, (function (param) {
                  var field = param[0];
                  if (param[1](ctx)) {
                    return {
                            key: field,
                            enabled: calcEnabled(field, param[2])
                          };
                  }
                  
                }));
  };
  var match$1 = React.useMemo((function () {
          var filters = data.filters;
          if (filters !== undefined) {
            return [
                    allPredefinedFields(ctx, filters.predefinedFields),
                    filters.customFields,
                    filters.reportFields
                  ];
          } else {
            return [
                    allPredefinedFields(ctx, []),
                    [],
                    []
                  ];
          }
        }), [data.filters]);
  var reportFields = match$1[2];
  var customFields = match$1[1];
  var predefinedFields = match$1[0];
  var toggleFilter = function (filter) {
    return function (show) {
      send({
            TAG: "ToggleFilter",
            _0: filter,
            _1: show
          });
    };
  };
  var typesToDict = function (types) {
    return RCore.$$Array.filterMap(types, (function (t) {
                  if (t.system) {
                    return ;
                  } else {
                    return JobIndexTypes.Dictionary.Value.make(t.uuid, t.name);
                  }
                }));
  };
  var dictionaryValues = function (dictUuid) {
    var dict = RCore.$$Array.getBy(dictionaries, (function (param) {
            return Uuid.equal(param.uuid, dictUuid);
          }));
    if (dict !== undefined) {
      return dict.values;
    } else {
      return [];
    }
  };
  var fieldsWithValues = function (pickedFields, knownFields, currentFilter) {
    var makeFieldValue = function (type_, value, dataType) {
      if (typeof type_ !== "object") {
        switch (type_) {
          case "DatePicker" :
              if (value !== undefined) {
                if (value.TAG === "Dates") {
                  return {
                          TAG: "Dates",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Dates",
                        _0: undefined
                      };
              }
          case "Input" :
              if (value !== undefined) {
                switch (value.TAG) {
                  case "String" :
                      return {
                              TAG: "String",
                              _0: value._0
                            };
                  case "Decimal" :
                      return {
                              TAG: "Decimal",
                              _0: value._0
                            };
                  case "Integer" :
                      return {
                              TAG: "Integer",
                              _0: value._0
                            };
                  default:
                    return ;
                }
              }
              break;
          case "DatetimePicker" :
              if (value !== undefined) {
                if (value.TAG === "Dates") {
                  return {
                          TAG: "Dates",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Dates",
                        _0: undefined
                      };
              }
          case "Signature" :
              if (value !== undefined) {
                if (value.TAG === "Attachment") {
                  return {
                          TAG: "Attachment",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Attachment",
                        _0: undefined
                      };
              }
          case "Checkbox" :
              if (value !== undefined) {
                if (value.TAG === "Bool") {
                  return {
                          TAG: "Bool",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Bool",
                        _0: undefined
                      };
              }
          case "Barcode" :
              if (value !== undefined) {
                if (value.TAG === "Barcode") {
                  return {
                          TAG: "Barcode",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Barcode",
                        _0: undefined
                      };
              }
          case "Currency" :
              if (value !== undefined) {
                if (value.TAG === "Currency") {
                  return {
                          TAG: "Currency",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              } else {
                return {
                        TAG: "Currency",
                        _0: undefined
                      };
              }
          case "Textarea" :
              if (value !== undefined) {
                if (value.TAG === "String") {
                  return {
                          TAG: "String",
                          _0: value._0
                        };
                } else {
                  return ;
                }
              }
              break;
          default:
            return ;
        }
      } else {
        var dictUuid = type_._0;
        if (value !== undefined) {
          if (value.TAG === "Dictionary") {
            return {
                    TAG: "Dictionary",
                    _0: dictUuid,
                    _1: value._0
                  };
          } else {
            return ;
          }
        } else {
          return {
                  TAG: "Dictionary",
                  _0: dictUuid,
                  _1: undefined
                };
        }
      }
      switch (dataType) {
        case "Integer" :
            return {
                    TAG: "Integer",
                    _0: undefined
                  };
        case "Decimal" :
            return {
                    TAG: "Decimal",
                    _0: undefined
                  };
        default:
          return {
                  TAG: "String",
                  _0: undefined
                };
      }
    };
    return RCore.$$Array.filterMap(pickedFields, (function (field) {
                  return RCore.$$Option.map(RCore.$$Array.getBy(knownFields, (function (c) {
                                    if (field.enabled) {
                                      return Uuid.equal(c.uuid, field.typeUuid);
                                    } else {
                                      return false;
                                    }
                                  })), (function (info) {
                                return [
                                        info.uuid,
                                        info.name,
                                        makeFieldValue(info.type_, RCore.$$Option.map(RCore.$$Array.getBy(currentFilter, (function (param) {
                                                        return Uuid.equal(info.uuid, param.typeUuid);
                                                      })), (function (f) {
                                                    return f.value;
                                                  })), info.dataType)
                                      ];
                              }));
                }));
  };
  var renderCustomFilters = function (pickedFields, fieldWrapper, cmpWithField, onFilter, resetFilter) {
    return pickedFields.map(function (param) {
                var value = param[2];
                var name = param[1];
                var uuid = param[0];
                if (value === undefined) {
                  return null;
                }
                var exit = 0;
                switch (value.TAG) {
                  case "Dictionary" :
                      return React.createElement(JobIndexFilter_CustomDictionary.make, {
                                  label: name,
                                  resetFilter: (function () {
                                      resetFilter(uuid);
                                    }),
                                  toggleFilter: toggleFilter(fieldWrapper(uuid)),
                                  options: dictionaryValues(value._0),
                                  filterByField: (function (value) {
                                      onFilter(uuid, {
                                            TAG: "Dictionary",
                                            _0: value
                                          });
                                    }),
                                  value: value._1,
                                  show: activeFilters.some(cmpWithField(uuid)),
                                  disabled: filterDisabled,
                                  key: Uuid.toString(uuid)
                                });
                  case "Bool" :
                      return React.createElement(JobIndexFilter_CustomBoolean.make, {
                                  label: name,
                                  resetFilter: (function () {
                                      resetFilter(uuid);
                                    }),
                                  toggleFilter: toggleFilter(fieldWrapper(uuid)),
                                  filterByField: (function (value) {
                                      onFilter(uuid, {
                                            TAG: "Bool",
                                            _0: value
                                          });
                                    }),
                                  value: value._0,
                                  show: activeFilters.some(cmpWithField(uuid)),
                                  disabled: filterDisabled,
                                  type_: "Boolean",
                                  key: Uuid.toString(uuid)
                                });
                  case "Dates" :
                      return React.createElement(JobIndexFilter_Dates.make, {
                                  predefined: false,
                                  activeView: activeView,
                                  label: name,
                                  resetFilter: (function () {
                                      resetFilter(uuid);
                                    }),
                                  toggleFilter: toggleFilter(fieldWrapper(uuid)),
                                  filterByField: (function (value) {
                                      onFilter(uuid, {
                                            TAG: "Dates",
                                            _0: value
                                          });
                                    }),
                                  value: value._0,
                                  show: activeFilters.some(cmpWithField(uuid)),
                                  disabled: filterDisabled,
                                  key: Uuid.toString(uuid)
                                });
                  case "Integer" :
                  case "Decimal" :
                      exit = 1;
                      break;
                  case "String" :
                      return React.createElement(JobIndexFilter_CustomString.make, {
                                  label: name,
                                  resetFilter: (function () {
                                      resetFilter(uuid);
                                    }),
                                  toggleFilter: toggleFilter(fieldWrapper(uuid)),
                                  filterByField: (function (value) {
                                      onFilter(uuid, {
                                            TAG: "String",
                                            _0: value
                                          });
                                    }),
                                  value: value._0,
                                  show: activeFilters.some(cmpWithField(uuid)),
                                  disabled: filterDisabled,
                                  key: Uuid.toString(uuid)
                                });
                  default:
                    var value$1;
                    var exit$1 = 0;
                    switch (value.TAG) {
                      case "Barcode" :
                      case "Currency" :
                      case "Attachment" :
                          exit$1 = 2;
                          break;
                      default:
                        value$1 = undefined;
                    }
                    if (exit$1 === 2) {
                      var value$2 = value._0;
                      value$1 = value$2 !== undefined ? (
                          value$2 === "NotFilled" ? false : true
                        ) : undefined;
                    }
                    return React.createElement(JobIndexFilter_CustomBoolean.make, {
                                label: name,
                                resetFilter: (function () {
                                    resetFilter(uuid);
                                  }),
                                toggleFilter: toggleFilter(fieldWrapper(uuid)),
                                filterByField: (function (v) {
                                    var value$3 = JobViewTypes.Settings.Filter.Value.PolytypeAnyOrEmpty.fromBoolean(v);
                                    var tmp;
                                    switch (value.TAG) {
                                      case "Barcode" :
                                          tmp = {
                                            TAG: "Barcode",
                                            _0: value$3
                                          };
                                          break;
                                      case "Currency" :
                                          tmp = {
                                            TAG: "Currency",
                                            _0: value$3
                                          };
                                          break;
                                      case "Attachment" :
                                          tmp = {
                                            TAG: "Attachment",
                                            _0: value$3
                                          };
                                          break;
                                      default:
                                        tmp = undefined;
                                    }
                                    RCore.$$Option.forEach(tmp, (function (v) {
                                            onFilter(uuid, v);
                                          }));
                                  }),
                                value: value$1,
                                show: activeFilters.some(cmpWithField(uuid)),
                                disabled: filterDisabled,
                                type_: "PolytypeAnyOrEmpty",
                                key: Uuid.toString(uuid)
                              });
                }
                if (exit === 1) {
                  var dataType;
                  dataType = value.TAG === "Integer" ? "Integer" : "Decimal";
                  var filterByField = function (v) {
                    if (dataType === "Integer") {
                      return onFilter(uuid, {
                                  TAG: "Integer",
                                  _0: v
                                });
                    } else {
                      return onFilter(uuid, {
                                  TAG: "Decimal",
                                  _0: v
                                });
                    }
                  };
                  var numberValue;
                  var exit$2 = 0;
                  switch (value.TAG) {
                    case "Integer" :
                    case "Decimal" :
                        exit$2 = 2;
                        break;
                    default:
                      numberValue = undefined;
                  }
                  if (exit$2 === 2) {
                    var v = value._0;
                    numberValue = v !== undefined ? v : undefined;
                  }
                  return React.createElement(JobIndexFilter_CustomNumber.make, {
                              label: name,
                              toggleFilter: toggleFilter(fieldWrapper(uuid)),
                              show: activeFilters.some(cmpWithField(uuid)),
                              resetFilter: (function () {
                                  resetFilter(uuid);
                                }),
                              filterByField: filterByField,
                              dataType: dataType,
                              value: numberValue,
                              disabled: filterDisabled,
                              key: Uuid.toString(uuid)
                            });
                }
                
              });
  };
  var match$2 = RCore.$$Option.map(RCore.$$Array.getBy(currentFilter.predefinedFields, (function (param) {
              return "MobileNumber" === param.key;
            })), (function (f) {
          return f.value;
        }));
  var tmp;
  tmp = match$2 !== undefined && match$2.TAG === "String" ? match$2._0 : undefined;
  var field = RCore.$$Array.getBy(predefinedFields, (function (j) {
          if (j.enabled) {
            return "TypeCode" === j.key;
          } else {
            return false;
          }
        }));
  var tmp$1;
  if (field !== undefined) {
    var match$3 = RCore.$$Option.map(RCore.$$Array.getBy(currentFilter.predefinedFields, (function (param) {
                return field.key === param.key;
              })), (function (f) {
            return f.value;
          }));
    var tmp$2;
    tmp$2 = match$3 !== undefined && match$3.TAG === "Dictionary" ? match$3._0 : undefined;
    tmp$1 = React.createElement(JobIndexFilter_CustomDictionary.make, {
          label: typeCode$p(ctx),
          resetFilter: (function () {
              send({
                    TAG: "ResetPredefinedField",
                    _0: "TypeCode"
                  });
            }),
          toggleFilter: (function (show) {
              send({
                    TAG: "ToggleFilter",
                    _0: {
                      TAG: "PredefinedField",
                      _0: "TypeCode"
                    },
                    _1: show
                  });
            }),
          options: typesToDict(data.types),
          filterByField: (function (value) {
              send({
                    TAG: "FilterByJobField",
                    _0: "TypeCode",
                    _1: {
                      TAG: "Dictionary",
                      _0: value
                    }
                  });
            }),
          value: tmp$2,
          show: activeFilters.some(function (f) {
                return JobIndexFilter_Types.predefinedEql("TypeCode")(f);
              }),
          haveAnySection: false,
          disabled: filterDisabled
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: "j-filter"
                }, React.createElement(JobIndexFilter_Assignees.make, {
                      currentFilter: currentFilter,
                      teams: data.teams,
                      workers: data.workers,
                      resetFilter: (function () {
                          send("ResetAssignees");
                        }),
                      toggleFilter: toggleFilter,
                      filterByWorker: (function (worker, add) {
                          send({
                                TAG: "FilterByWorker",
                                _0: worker,
                                _1: add
                              });
                        }),
                      filterByTeam: (function (team, add) {
                          send({
                                TAG: "FilterByTeam",
                                _0: team,
                                _1: add
                              });
                        }),
                      filterDisabled: filterDisabled,
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("Assignee"))
                    }), React.createElement(JobIndexFilter_Statuses.make, {
                      currentFilter: currentFilter,
                      resolutions: data.resolutions,
                      resetFilter: (function () {
                          send("ResetStatuses");
                        }),
                      toggleFilter: toggleFilter,
                      resetResolutions: (function (statusType, resolutionType) {
                          send({
                                TAG: "ResetResolutions",
                                _0: statusType,
                                _1: resolutionType
                              });
                        }),
                      appendResolutions: (function (statusType, resolutionType) {
                          send({
                                TAG: "AppendResolutions",
                                _0: statusType,
                                _1: resolutionType
                              });
                        }),
                      filterByStatus: (function (status) {
                          return function (add) {
                            send({
                                  TAG: "FilterByStatus",
                                  _0: status,
                                  _1: add
                                });
                          };
                        }),
                      filterByResolution: (function (resolution, type_, add) {
                          send({
                                TAG: "FilterByResolution",
                                _0: resolution,
                                _1: type_,
                                _2: add
                              });
                        }),
                      selectAllResolutions: (function (status) {
                          send({
                                TAG: "SelectAllResolutions",
                                _0: status
                              });
                        }),
                      selectSuccessfulResolutions: (function () {
                          send("SelectSuccessfulResolutions");
                        }),
                      selectUnsuccessfulResolutions: (function (status) {
                          send({
                                TAG: "SelectUnsuccessfulResolutions",
                                _0: status
                              });
                        }),
                      filterDisabled: filterDisabled,
                      activeView: activeView,
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("Status"))
                    }), React.createElement(JobIndexFilter_Templates.make, {
                      currentFilter: currentFilter.templates,
                      templates: data.templates,
                      resetFilter: (function () {
                          send("ResetTemplates");
                        }),
                      filterByTemplate: (function (template, add) {
                          send({
                                TAG: "FilterByTemplate",
                                _0: template,
                                _1: add
                              });
                        }),
                      toggleFilter: toggleFilter,
                      filterDisabled: filterDisabled,
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("Template"))
                    }), React.createElement(JobIndexFilter_ScheduledAt.make, {
                      value: currentFilter.scheduledAt,
                      disabled: filterDisabled,
                      toggleFilter: (function (show) {
                          send({
                                TAG: "ToggleFilter",
                                _0: {
                                  TAG: "System",
                                  _0: "ScheduledAt"
                                },
                                _1: show
                              });
                        }),
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("ScheduledAt")),
                      filterByField: (function (value) {
                          send({
                                TAG: "FilterByScheduledAt",
                                _0: value
                              });
                        }),
                      resetFilter: (function () {
                          send({
                                TAG: "FilterByScheduledAt",
                                _0: undefined
                              });
                        }),
                      activeView: activeView
                    }), React.createElement(JobIndexFilter_FinishedAt.make, {
                      value: currentFilter.finishedAt,
                      disabled: filterDisabled,
                      toggleFilter: (function (show) {
                          send({
                                TAG: "ToggleFilter",
                                _0: {
                                  TAG: "System",
                                  _0: "FinishedAt"
                                },
                                _1: show
                              });
                        }),
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("FinishedAt")),
                      filterByField: (function (value) {
                          send({
                                TAG: "FilterByFinishedAt",
                                _0: value
                              });
                        }),
                      resetFilter: (function () {
                          send({
                                TAG: "FilterByFinishedAt",
                                _0: undefined
                              });
                        }),
                      activeView: activeView
                    }), React.createElement(JobIndexFilter_Clients.make, {
                      filterByClient: (function (client) {
                          send({
                                TAG: "FilterByClient",
                                _0: client
                              });
                        }),
                      resetFilter: (function () {
                          send("ResetClient");
                        }),
                      toggleFilter: toggleFilter,
                      show: activeFilters.some(JobIndexFilter_Types.systemEql("Client")),
                      currentFilter: currentFilter.client,
                      filterDisabled: filterDisabled
                    }), React.createElement(Optional.make, {
                      show: predefinedFields.some(function (j) {
                            if (j.enabled) {
                              return "MobileNumber" === j.key;
                            } else {
                              return false;
                            }
                          }),
                      children: React.createElement(JobIndexFilter_CustomString.make, {
                            label: mobileNumber$p(ctx),
                            resetFilter: (function () {
                                send({
                                      TAG: "ResetPredefinedField",
                                      _0: "MobileNumber"
                                    });
                              }),
                            toggleFilter: (function (show) {
                                send({
                                      TAG: "ToggleFilter",
                                      _0: {
                                        TAG: "PredefinedField",
                                        _0: "MobileNumber"
                                      },
                                      _1: show
                                    });
                              }),
                            filterByField: (function (value) {
                                send({
                                      TAG: "FilterByJobField",
                                      _0: "MobileNumber",
                                      _1: {
                                        TAG: "String",
                                        _0: value
                                      }
                                    });
                              }),
                            value: tmp,
                            show: activeFilters.some(JobIndexFilter_Types.predefinedEql("MobileNumber")),
                            disabled: filterDisabled
                          })
                    }), React.createElement(Optional.make, {
                      show: predefinedFields.some(function (j) {
                            if (j.enabled) {
                              return "Territory" === j.key;
                            } else {
                              return false;
                            }
                          }),
                      children: React.createElement(JobIndexFilter_Territories.make, {
                            currentFilter: currentFilter.territories,
                            territories: data.territories,
                            filterByTerritory: (function (territory, add) {
                                send({
                                      TAG: "FilterByTerritory",
                                      _0: territory,
                                      _1: add
                                    });
                              }),
                            filterDisabled: filterDisabled,
                            resetFilter: (function () {
                                send("ResetTerritories");
                              }),
                            toggleFilter: toggleFilter,
                            show: activeFilters.some(JobIndexFilter_Types.systemEql("Territory"))
                          })
                    }), tmp$1, React.createElement(Optional.make, {
                      show: predefinedFields.some(function (j) {
                            if (j.enabled) {
                              return "CreatedAt" === j.key;
                            } else {
                              return false;
                            }
                          }),
                      children: React.createElement(JobIndexFilter_CreatedAt.make, {
                            value: currentFilter.createdAt,
                            disabled: filterDisabled,
                            toggleFilter: (function (show) {
                                send({
                                      TAG: "ToggleFilter",
                                      _0: {
                                        TAG: "System",
                                        _0: "CreatedAt"
                                      },
                                      _1: show
                                    });
                              }),
                            show: activeFilters.some(JobIndexFilter_Types.systemEql("CreatedAt")),
                            filterByField: (function (value) {
                                send({
                                      TAG: "FilterByCreatedAt",
                                      _0: value
                                    });
                              }),
                            resetFilter: (function () {
                                send("ResetCreatedAt");
                              }),
                            activeView: activeView
                          })
                    }), React.createElement(Optional.make, {
                      show: predefinedFields.some(function (j) {
                            if (j.enabled) {
                              return "SuspendedAt" === j.key;
                            } else {
                              return false;
                            }
                          }),
                      children: React.createElement(JobIndexFilter_SuspendedAt.make, {
                            value: currentFilter.suspendedAt,
                            disabled: filterDisabled,
                            toggleFilter: (function (show) {
                                send({
                                      TAG: "ToggleFilter",
                                      _0: {
                                        TAG: "System",
                                        _0: "SuspendedAt"
                                      },
                                      _1: show
                                    });
                              }),
                            show: activeFilters.some(JobIndexFilter_Types.systemEql("SuspendedAt")),
                            filterByField: (function (value) {
                                send({
                                      TAG: "FilterBySuspendedAt",
                                      _0: value
                                    });
                              }),
                            resetFilter: (function () {
                                send("ResetSuspendedAt");
                              }),
                            activeView: activeView
                          })
                    }), React.createElement(Optional.make, {
                      show: predefinedFields.some(function (j) {
                            if (j.enabled) {
                              return "ResumedAt" === j.key;
                            } else {
                              return false;
                            }
                          }),
                      children: React.createElement(JobIndexFilter_ResumedAt.make, {
                            value: currentFilter.resumedAt,
                            disabled: filterDisabled,
                            toggleFilter: (function (show) {
                                send({
                                      TAG: "ToggleFilter",
                                      _0: {
                                        TAG: "System",
                                        _0: "ResumedAt"
                                      },
                                      _1: show
                                    });
                              }),
                            show: activeFilters.some(JobIndexFilter_Types.systemEql("ResumedAt")),
                            filterByField: (function (value) {
                                send({
                                      TAG: "FilterByResumedAt",
                                      _0: value
                                    });
                              }),
                            resetFilter: (function () {
                                send("ResetResumedAt");
                              }),
                            activeView: activeView
                          })
                    }), React.createElement(JobIndexFilter_PredefinedAnyOrEmpty.make, {
                      currentFilter: currentFilter,
                      activeFilters: activeFilters,
                      predefinedFields: predefinedFields,
                      ctx: ctx,
                      send: send,
                      toggleFilter: toggleFilter,
                      filterDisabled: filterDisabled
                    }), renderCustomFilters(fieldsWithValues(customFields, data.customFields, currentFilter.customFields), (function (value) {
                        return {
                                TAG: "CustomField",
                                _0: value
                              };
                      }), JobIndexFilter_Types.customEql, (function (field, value) {
                        send({
                              TAG: "FilterByCustomField",
                              _0: field,
                              _1: value
                            });
                      }), (function (uuid) {
                        send({
                              TAG: "ResetCustomField",
                              _0: uuid
                            });
                      })), renderCustomFilters(fieldsWithValues(reportFields, data.reportFields, currentFilter.reportFields), (function (value) {
                        return {
                                TAG: "ReportField",
                                _0: value
                              };
                      }), JobIndexFilter_Types.reportEql, (function (field, value) {
                        send({
                              TAG: "FilterByReportField",
                              _0: field,
                              _1: value
                            });
                      }), (function (uuid) {
                        send({
                              TAG: "ResetReportField",
                              _0: uuid
                            });
                      }))), React.createElement(JobIndexFilter_SettingsButton.make, {
                  data: data,
                  initialPredefinedFields: predefinedFields,
                  initialCustomFields: customFields,
                  initialReportFields: reportFields,
                  createFilters: (function (jobFields, customFields, reportFields, onFinish) {
                      send({
                            TAG: "CreateFilters",
                            _0: jobFields,
                            _1: customFields,
                            _2: reportFields,
                            _3: onFinish
                          });
                    }),
                  updateFilters: (function (uuid, jobFields, customFields, reportFields, onFinish) {
                      send({
                            TAG: "UpdateFilters",
                            _0: uuid,
                            _1: jobFields,
                            _2: customFields,
                            _3: reportFields,
                            _4: onFinish
                          });
                    })
                }));
}

var make = JobIndexFilter;

export {
  make ,
}
/* typeCode' Not a pure module */
