

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as MomentRe from "../../../bindings/moment/MomentRe.mjs";
import * as Optional from "../Optional.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as MultipleAssignees_Worker from "./MultipleAssignees_Worker.mjs";
import * as MultipleAssigneesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/MultipleAssignees/MultipleAssignees.module.css";

var styles = MultipleAssigneesModuleCss;

var AvailableTimestamp = {};

function MultipleAssignees_Team(Props) {
  var team = Props.team;
  var availableAt = Props.availableAt;
  var onWorkerDelete = Props.onWorkerDelete;
  var onDelete = Props.onDelete;
  var onTempWorkerDelete = Props.onTempWorkerDelete;
  var names = Props.names;
  var showTeamHeaderOpt = Props.showTeamHeader;
  var disabled = Props.disabled;
  var decoration = Props.decoration;
  var showTeamHeader = showTeamHeaderOpt !== undefined ? showTeamHeaderOpt : true;
  var match = React.useState(function () {
        return [];
      });
  var setTempWorkers = match[1];
  React.useEffect((function () {
          if (availableAt !== undefined) {
            if (typeof availableAt !== "object") {
              setTempWorkers(function (param) {
                    return team.temporaryWorkers;
                  });
            } else {
              var date = availableAt._0;
              setTempWorkers(function (param) {
                    return RCore.$$Array.keep(team.temporaryWorkers, (function (w) {
                                  if (Locale.T.equalOrLessThan(Locale.T.startOf("day", MomentRe.makeFromJsDate(w.dateFrom)), date)) {
                                    return Locale.T.moreThan(Locale.T.endOf("day", MomentRe.makeFromJsDate(w.dateTo)), date);
                                  } else {
                                    return false;
                                  }
                                }));
                  });
            }
          } else {
            setTempWorkers(function (param) {
                  return [];
                });
          }
        }), [
        availableAt,
        team.temporaryWorkers
      ]);
  var foreman = team.foreman;
  return React.createElement("div", {
              className: styles.teamContainer
            }, React.createElement(Optional.make, {
                  show: showTeamHeader,
                  children: React.createElement("div", {
                        className: styles.teamHeader
                      }, React.createElement("div", {
                            className: styles.teamName
                          }, team.name), React.createElement("i", {
                            className: El.Cn.concat(El.Cn.concat(Icon.style(undefined, undefined, undefined, "times-circle"), styles.deleteTeam), "delete-team"),
                            onClick: (function (param) {
                                onDelete();
                              })
                          }))
                }), React.createElement("div", {
                  className: styles.teamMembers
                }, foreman !== undefined ? React.createElement("div", {
                        className: styles.foremanOption
                      }, React.createElement(MultipleAssignees_Worker.make, {
                            worker: {
                              TAG: "TeamMember",
                              _0: {
                                TAG: "Edit",
                                _0: foreman
                              }
                            },
                            onDelete: (function () {
                                onWorkerDelete(foreman);
                              }),
                            teamMember: true,
                            disabled: disabled,
                            decoration: decoration
                          })) : null, React.createElement(React.Fragment, undefined, team.workers.map(function (w) {
                          return React.createElement(MultipleAssignees_Worker.make, {
                                      worker: {
                                        TAG: "TeamMember",
                                        _0: {
                                          TAG: "View",
                                          _0: w
                                        }
                                      },
                                      onDelete: (function () {
                                          onWorkerDelete(w);
                                        }),
                                      teamMember: true,
                                      disabled: disabled,
                                      decoration: decoration,
                                      key: Uuid.toString(w.uuid)
                                    });
                        }), match[0].map(function (temp, i) {
                          var worker = MultipleAssignee.Team.TemporaryWorker.toMember(temp);
                          var mbName = RCore.$$Option.getOr(names, [])[i];
                          return React.createElement(MultipleAssignees_Worker.make, {
                                      worker: {
                                        TAG: "TeamMember",
                                        _0: {
                                          TAG: "View",
                                          _0: worker
                                        }
                                      },
                                      onDelete: (function () {
                                          onTempWorkerDelete(temp);
                                        }),
                                      teamMember: true,
                                      disabled: disabled,
                                      decoration: decoration,
                                      displayedName: RCore.$$Option.getOr(mbName, "+" + worker.name),
                                      key: RCore.$$Option.getOr(mbName, Uuid.toString(worker.uuid))
                                    });
                        }))));
}

var make = MultipleAssignees_Team;

export {
  AvailableTimestamp ,
  make ,
}
/* styles Not a pure module */
