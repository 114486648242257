

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Select from "../../../inputs/Select.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Spinner from "../../../common/Spinner/Spinner.mjs";
import * as Optional from "../../../common/Optional.mjs";
import * as FormInput from "../../../inputs/FormInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Catalog_Lib from "../Catalog_Lib.mjs";
import * as CurrencyText from "../../../inputs/CurrencyText.mjs";
import * as Product_Form from "./Product_Form.mjs";
import * as Products_API from "./Products_API.mjs";
import * as Catalog_Types from "../Catalog_Types.mjs";
import * as Context_Types from "../../../../context/Context_Types.mjs";
import * as Shared_Lib_IO from "../../../../shared/lib/Shared_Lib_IO.mjs";
import * as Backend_Errors from "../../../../libraries/backend/Backend_Errors.mjs";
import * as Categories_API from "../categories/Categories_API.mjs";
import * as Categories_List from "../categories/Categories_List.mjs";
import * as CatalogUnits_API from "../units/CatalogUnits_API.mjs";
import * as UI_FeatureTeaser from "../../../../ui/UI_FeatureTeaser.mjs";
import * as CatalogUnits_List from "../units/CatalogUnits_List.mjs";
import * as Catalog_ListEntities from "../Catalog_ListEntities.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Catalog_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/catalog/Catalog_Index.module.css";

var catalog$p = Utils.Translations.tr("js.admin.settings.title.catalog");

var categories$p = Utils.Translations.tr("js.admin.catalog.categories");

var products$p = Utils.Translations.tr("js.admin.catalog.products");

var units$p = Utils.Translations.tr("js.admin.catalog.units");

var addProduct$p = Utils.Translations.tr("js.admin.catalog.add_product");

var noProducts$p = Utils.Translations.tr("js.admin.catalog.no_products");

var filter$p = Utils.Translations.tr("js.admin.catalog.filter");

var name$p = Utils.Translations.tr("js.admin.catalog.form.name");

var category$p = Utils.Translations.tr("js.admin.catalog.form.category");

var unit$p = Utils.Translations.tr("js.admin.catalog.form.unit");

var priceDefault$p = Utils.Translations.tr("js.admin.catalog.form.price_default");

var description$p = Utils.Translations.tr("js.admin.catalog.form.description");

var gtin$p = Utils.Translations.tr("js.admin.catalog.form.gtin_short");

var externalId$p = Utils.Translations.tr("js.admin.catalog.form.external_id");

var filterName$p = Utils.Translations.t("js.admin.catalog.form.name");

var filterCategory$p = Utils.Translations.t("js.admin.catalog.form.category");

var showMore$p = Utils.Translations.tr("js.admin.catalog.show_more");

var fakeUnitMeterName$p = Utils.Translations.t("js.admin.catalog.fake_data.units.meter.name");

var fakeUnitMeterCode$p = Utils.Translations.t("js.admin.catalog.fake_data.units.meter.code");

var fakeUnitItemName$p = Utils.Translations.t("js.admin.catalog.fake_data.units.item.name");

var fakeUnitItemCode$p = Utils.Translations.t("js.admin.catalog.fake_data.units.item.code");

var fakeUnitKiloName$p = Utils.Translations.t("js.admin.catalog.fake_data.units.kilo.name");

var fakeUnitKiloCode$p = Utils.Translations.t("js.admin.catalog.fake_data.units.kilo.code");

var fakeCategoryCables$p = Utils.Translations.t("js.admin.catalog.fake_data.product_categories.cables");

var fakeCategoryFasteners$p = Utils.Translations.t("js.admin.catalog.fake_data.product_categories.fasteners");

var fakeCategoryEquipment$p = Utils.Translations.t("js.admin.catalog.fake_data.product_categories.equipment");

var fakeProductCableName$p = Utils.Translations.t("js.admin.catalog.fake_data.products.cable.name");

var fakeProductConnectorName$p = Utils.Translations.t("js.admin.catalog.fake_data.products.connector.name");

var fakeProductConnectorDescription$p = Utils.Translations.t("js.admin.catalog.fake_data.products.connector.description");

var fakeProductRouterName$p = Utils.Translations.t("js.admin.catalog.fake_data.products.router.name");

var fakeProductRouterDescription$p = Utils.Translations.t("js.admin.catalog.fake_data.products.router.description");

var fakeProductRouterPrice$p = Utils.Translations.t("js.admin.catalog.fake_data.products.router.price");

var styles = Catalog_IndexModuleCss;

var default_categories = [];

var default_products = [];

var default_units = [];

var $$default = {
  loaded: false,
  page: "Products",
  categories: default_categories,
  products: default_products,
  units: default_units,
  fetching: false,
  hasMore: false,
  offset: 0,
  filterName: undefined,
  filterCategory: undefined
};

function Products_Index$Filter(Props) {
  var ctx = Props.ctx;
  var name = Props.name;
  var onNameChange = Props.onNameChange;
  var pickedCategory = Props.pickedCategory;
  var categories = Props.categories;
  var onCategoryPick = Props.onCategoryPick;
  var options = React.useMemo((function () {
          return categories.map(function (c) {
                      return Select.$$Option.make(c.name, Catalog_Types.uuidToId(c.uuid));
                    });
        }), [categories]);
  var selectValue = Catalog_Lib.parentSelectValue(pickedCategory, categories);
  return React.createElement("div", {
              className: styles.filter
            }, filter$p(ctx), React.createElement("div", undefined, React.createElement(FormInput.make, {
                      _type: "input",
                      value: RCore.$$Option.getOr(name, ""),
                      onValueChange: onNameChange,
                      wrapperClass: Js_dict.fromList({
                            hd: [
                              styles.filterName,
                              true
                            ],
                            tl: /* [] */0
                          }),
                      placeholder: filterName$p(ctx)
                    })), React.createElement("div", undefined, React.createElement(Select.make, {
                      placeholder: filterCategory$p(ctx),
                      onChange: onCategoryPick,
                      value: selectValue,
                      options: options
                    })));
}

function Products_Index$Products_List$Header(Props) {
  var ctx = Props.ctx;
  return React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, name$p(ctx)), React.createElement("th", undefined, category$p(ctx)), React.createElement("th", undefined, unit$p(ctx)), React.createElement("th", undefined, priceDefault$p(ctx)), React.createElement("th", undefined, description$p(ctx)), React.createElement("th", undefined, gtin$p(ctx)), React.createElement("th", undefined, externalId$p(ctx))));
}

function Products_Index$Products_List$ProductRow(Props) {
  var ctx = Props.ctx;
  var product = Props.product;
  var categories = Props.categories;
  var units = Props.units;
  var onOpen = Props.onOpen;
  var category = React.useMemo((function () {
          return Catalog_Lib.rednerParent(product.categoryUuid, categories);
        }), [
        product,
        categories
      ]);
  var unit = React.useMemo((function () {
          var uuid = product.unitUuid;
          if (uuid === undefined) {
            return "—";
          }
          var uuid$1 = Caml_option.valFromOption(uuid);
          return RCore.$$Option.mapWithDefault(RCore.$$Array.getBy(units, (function (c) {
                            return Uuid.equal(c.uuid, uuid$1);
                          })), "—", (function (c) {
                        return c.code;
                      }));
        }), [
        product,
        units
      ]);
  var onClick = function (e) {
    e.preventDefault();
    onOpen(product.uuid);
  };
  var rowCN = product.archived ? El.Cn.concat(styles.tableRow, styles.archived) : styles.tableRow;
  var value = product.priceDefault;
  return React.createElement("tr", {
              className: rowCN,
              onClick: onClick
            }, React.createElement("td", {
                  className: styles.link
                }, product.name), React.createElement("td", undefined, category), React.createElement("td", undefined, unit), React.createElement("td", undefined, value !== undefined ? React.createElement(CurrencyText.make, {
                        ctx: ctx,
                        currency: product.currency,
                        value: value
                      }) : null), React.createElement("td", undefined, RCore.$$Option.getOr(product.description, "")), React.createElement("td", undefined, RCore.$$Option.getOr(product.gtin, "")), React.createElement("td", undefined, RCore.$$Option.getOr(product.externalId, "")));
}

function Products_Index$Products_List(Props) {
  var param = Props.wire;
  var products = Props.products;
  var filterName = Props.filterName;
  var onFilterNameChange = Props.onFilterNameChange;
  var onFilterCategoryPick = Props.onFilterCategoryPick;
  var filterCategory = Props.filterCategory;
  var hasMore = Props.hasMore;
  var fetching = Props.fetching;
  var onLoadMore = Props.onLoadMore;
  var categories = Props.categories;
  var units = Props.units;
  var onCreate = Props.onCreate;
  var onUpdate = Props.onUpdate;
  var onDelete = Props.onDelete;
  var ctx = param.ctx;
  var match = React.useState(function () {
        
      });
  var setOpenedProduct = match[1];
  var onOpenEdit = function (uuid) {
    setOpenedProduct(function (param) {
          return Caml_option.some(uuid);
        });
  };
  var onCloseEdit = function () {
    setOpenedProduct(function (param) {
          
        });
  };
  var renderRow = function (product) {
    return React.createElement(Products_Index$Products_List$ProductRow, {
                ctx: ctx,
                product: product,
                categories: categories,
                units: units,
                onOpen: onOpenEdit,
                key: Uuid.toString(product.uuid)
              });
  };
  var renderNewForm = function (onCloseNew) {
    return React.createElement(Product_Form.make, {
                mode: "New",
                product: undefined,
                onClose: onCloseNew,
                categories: categories,
                units: units,
                onCreate: onCreate,
                onUpdate: onUpdate,
                onDelete: onDelete
              });
  };
  var renderEditForm = function (uuid) {
    return React.createElement(Product_Form.make, {
                mode: {
                  NAME: "Edit",
                  VAL: uuid
                },
                product: RCore.$$Array.getBy(products, (function (g) {
                        return Uuid.equal(g.uuid, uuid);
                      })),
                onClose: onCloseEdit,
                categories: categories,
                units: units,
                onCreate: onCreate,
                onUpdate: onUpdate,
                onDelete: onDelete
              });
  };
  var filterRequest = filterName !== undefined || filterCategory !== undefined || fetching ? true : false;
  return React.createElement(React.Fragment, undefined, React.createElement(Catalog_ListEntities.make, {
                  ctx: ctx,
                  entities: products,
                  title: addProduct$p(ctx),
                  noEntries: noProducts$p(ctx),
                  opened: match[0],
                  renderRow: renderRow,
                  renderNewForm: renderNewForm,
                  renderEditForm: renderEditForm,
                  header: React.createElement(Products_Index$Products_List$Header, {
                        ctx: ctx
                      }),
                  filter: React.createElement(Products_Index$Filter, {
                        ctx: ctx,
                        name: filterName,
                        onNameChange: onFilterNameChange,
                        pickedCategory: filterCategory,
                        categories: categories,
                        onCategoryPick: onFilterCategoryPick
                      }),
                  filterRequest: filterRequest
                }), React.createElement(Optional.make, {
                  show: hasMore,
                  children: React.createElement("div", {
                        className: styles.loadMore
                      }, fetching ? React.createElement(Spinner.make, {}) : React.createElement("div", {
                              className: styles.showMore,
                              onClick: onLoadMore
                            }, showMore$p(ctx)))
                }));
}

function Products_Index(Props) {
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default;
      });
  var setState = match[1];
  var state = match[0];
  var hasFlag = Context_Types.Features.hasFlag("catalog", ctx.features);
  var $$fetch = function () {
    var loaded = function (response) {
      if (response.TAG === "Ok") {
        var match = response._0;
        var units = match.units;
        var products = match.products;
        var categories = match.categories;
        return setState(function (prev) {
                    return {
                            loaded: true,
                            page: prev.page,
                            categories: categories,
                            products: products,
                            units: units,
                            fetching: prev.fetching,
                            hasMore: products.length === 50,
                            offset: prev.offset,
                            filterName: prev.filterName,
                            filterCategory: prev.filterCategory
                          };
                  });
      }
      console.log(response._0);
    };
    Backend.$$finally(Products_API.index(wire), loaded);
  };
  React.useEffect((function () {
          if (hasFlag) {
            $$fetch();
          } else {
            setState(function (param) {
                  var units = [
                    {
                      uuid: Uuid.createV4(),
                      name: fakeUnitMeterName$p(ctx),
                      code: fakeUnitMeterCode$p(ctx),
                      type_: "Products",
                      externalId: "meter",
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeUnitItemName$p(ctx),
                      code: fakeUnitItemCode$p(ctx),
                      type_: "Products",
                      externalId: undefined,
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeUnitKiloName$p(ctx),
                      code: fakeUnitKiloCode$p(ctx),
                      type_: "Products",
                      externalId: undefined,
                      archived: true
                    }
                  ];
                  var categories = [
                    {
                      uuid: Uuid.createV4(),
                      name: fakeCategoryCables$p(ctx),
                      type_: "Products",
                      description: undefined,
                      parentUuid: undefined,
                      externalId: undefined,
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeCategoryFasteners$p(ctx),
                      type_: "Products",
                      description: undefined,
                      parentUuid: undefined,
                      externalId: undefined,
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeCategoryEquipment$p(ctx),
                      type_: "Products",
                      description: undefined,
                      parentUuid: undefined,
                      externalId: "equipment",
                      archived: false
                    }
                  ];
                  var products = [
                    {
                      uuid: Uuid.createV4(),
                      name: fakeProductCableName$p(ctx),
                      description: undefined,
                      priceDefault: undefined,
                      currency: ctx.localizator.currency,
                      externalId: undefined,
                      categoryUuid: RCore.$$Option.map(categories[0], (function (c) {
                              return c.uuid;
                            })),
                      unitUuid: RCore.$$Option.map(units[0], (function (u) {
                              return u.uuid;
                            })),
                      gtin: undefined,
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeProductConnectorName$p(ctx),
                      description: fakeProductConnectorDescription$p(ctx),
                      priceDefault: undefined,
                      currency: ctx.localizator.currency,
                      externalId: undefined,
                      categoryUuid: RCore.$$Option.map(categories[1], (function (c) {
                              return c.uuid;
                            })),
                      unitUuid: RCore.$$Option.map(units[1], (function (u) {
                              return u.uuid;
                            })),
                      gtin: undefined,
                      archived: false
                    },
                    {
                      uuid: Uuid.createV4(),
                      name: fakeProductRouterName$p(ctx),
                      description: fakeProductRouterDescription$p(ctx),
                      priceDefault: fakeProductRouterPrice$p(ctx),
                      currency: ctx.localizator.currency,
                      externalId: "router",
                      categoryUuid: RCore.$$Option.map(categories[2], (function (c) {
                              return c.uuid;
                            })),
                      unitUuid: RCore.$$Option.map(units[1], (function (u) {
                              return u.uuid;
                            })),
                      gtin: "123456789012",
                      archived: false
                    }
                  ];
                  return {
                          loaded: true,
                          page: "Products",
                          categories: categories,
                          products: products,
                          units: units,
                          fetching: false,
                          hasMore: false,
                          offset: 0,
                          filterName: undefined,
                          filterCategory: undefined
                        };
                });
          }
        }), []);
  var setPage = function (page) {
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: page,
                  categories: prev.categories,
                  products: prev.products,
                  units: prev.units,
                  fetching: prev.fetching,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: prev.filterName,
                  filterCategory: prev.filterCategory
                };
        });
  };
  var handleProductsResponse = function (succCb, errorCb) {
    return function (response) {
      var products = Catalog_Lib.handleProductsResponse(state.products, response)(succCb, errorCb);
      setState(function (prev) {
            return {
                    loaded: prev.loaded,
                    page: prev.page,
                    categories: prev.categories,
                    products: products,
                    units: prev.units,
                    fetching: prev.fetching,
                    hasMore: prev.hasMore,
                    offset: prev.offset,
                    filterName: prev.filterName,
                    filterCategory: prev.filterCategory
                  };
          });
    };
  };
  var handleCategoriesResponse = function (succCb, errorCb) {
    return function (response) {
      var categories = Catalog_Lib.handleCategoriesResponse(state.categories, response)(succCb, errorCb);
      setState(function (prev) {
            return {
                    loaded: prev.loaded,
                    page: prev.page,
                    categories: categories,
                    products: prev.products,
                    units: prev.units,
                    fetching: prev.fetching,
                    hasMore: prev.hasMore,
                    offset: prev.offset,
                    filterName: prev.filterName,
                    filterCategory: prev.filterCategory
                  };
          });
    };
  };
  var handleUnitResponse = function (succCb, errorCb) {
    return function (response) {
      var units = Catalog_Lib.handleUnitResponse(state.units, response)(succCb, errorCb);
      setState(function (prev) {
            return {
                    loaded: prev.loaded,
                    page: prev.page,
                    categories: prev.categories,
                    products: prev.products,
                    units: units,
                    fetching: prev.fetching,
                    hasMore: prev.hasMore,
                    offset: prev.offset,
                    filterName: prev.filterName,
                    filterCategory: prev.filterCategory
                  };
          });
    };
  };
  var onProductCreate = function (data, succCb, errorCb) {
    Backend.$$finally(Products_API.create(wire, data), handleProductsResponse(succCb, errorCb));
  };
  var onProductUpdate = function (uuid, data, succCb, errorCb) {
    Backend.$$finally(Products_API.update(wire, uuid, data), handleProductsResponse(succCb, errorCb));
  };
  var onProductDelete = function (uuid, succCb) {
    Backend.$$finally(Products_API.$$delete(wire, uuid), (function (response) {
            if (response.TAG !== "Ok") {
              var e = response._0;
              if (typeof e !== "object") {
                console.log(e);
                return ;
              }
              if (e.TAG === "HTTPError") {
                var tmp = e._0[0];
                if (!(typeof tmp !== "object" && tmp === "NotFound")) {
                  console.log(e);
                  return ;
                }
                
              } else {
                console.log(e);
                return ;
              }
            }
            succCb();
            setState(function (prev) {
                  return {
                          loaded: prev.loaded,
                          page: prev.page,
                          categories: prev.categories,
                          products: RCore.$$Array.keep(prev.products, (function (p) {
                                  return !Uuid.equal(p.uuid, uuid);
                                })),
                          units: prev.units,
                          fetching: prev.fetching,
                          hasMore: prev.hasMore,
                          offset: prev.offset,
                          filterName: prev.filterName,
                          filterCategory: prev.filterCategory
                        };
                });
          }));
  };
  var onCategoryCreate = function (data, succCb, errorCb) {
    Backend.$$finally(Categories_API.create(wire, data), handleCategoriesResponse(succCb, errorCb));
  };
  var onCategoryUpdate = function (uuid, data, succCb, errorCb) {
    Backend.$$finally(Categories_API.update(wire, uuid, data), handleCategoriesResponse(succCb, errorCb));
  };
  var onCategoryDelete = function (uuid, succCb, errorCb) {
    Backend.$$finally(Categories_API.Delete.$$delete(wire, uuid), (function (response) {
            if (response.TAG === "Ok") {
              $$fetch();
              return succCb();
            }
            var errors = Backend.parseError(response._0);
            if (typeof errors !== "object") {
              console.log(errors);
              return ;
            }
            switch (errors.TAG) {
              case "HTTPError" :
                  var tmp = errors._0[0];
                  if (typeof tmp !== "object") {
                    if (tmp === "NotFound") {
                      $$fetch();
                      return succCb();
                    }
                    console.log(errors);
                    return ;
                  } else {
                    console.log(errors);
                    return ;
                  }
              case "ValidationError" :
                  var e = Json_Decode$JsonCombinators.decode(Backend_Errors.json(errors._0), Categories_API.Delete.$$Response.decode);
                  if (e.TAG === "Ok") {
                    var match = e._0;
                    return errorCb(match.descendants, match.products, match.services, match.templates);
                  }
                  console.log(e._0);
                  return ;
              default:
                console.log(errors);
                return ;
            }
          }));
  };
  var onUnitCreate = function (data, succCb, errorCb) {
    Backend.$$finally(CatalogUnits_API.create(wire, data), handleUnitResponse(succCb, errorCb));
  };
  var onUnitUpdate = function (uuid, data, succCb, errorCb) {
    Backend.$$finally(CatalogUnits_API.update(wire, uuid, data), handleUnitResponse(succCb, errorCb));
  };
  var onUnitDelete = function (uuid, succCb, errorCb) {
    Backend.$$finally(CatalogUnits_API.$$delete(wire, uuid), (function (response) {
            var succResp = function () {
              $$fetch();
              succCb();
            };
            if (response.TAG === "Ok") {
              return succResp();
            }
            var errors = Backend.parseError(response._0);
            if (typeof errors !== "object") {
              console.log(errors);
              return ;
            }
            switch (errors.TAG) {
              case "HTTPError" :
                  var tmp = errors._0[0];
                  if (typeof tmp !== "object") {
                    if (tmp === "NotFound") {
                      return succResp();
                    }
                    console.log(errors);
                    return ;
                  } else {
                    console.log(errors);
                    return ;
                  }
              case "ValidationError" :
                  var e = Json_Decode$JsonCombinators.decode(Backend_Errors.json(errors._0), CatalogUnits_API.Delete.$$Response.decode);
                  if (e.TAG === "Ok") {
                    return errorCb(e._0.products);
                  }
                  console.log(e._0);
                  return ;
              default:
                console.log(errors);
                return ;
            }
          }));
  };
  var newCats = function (prevCats, fetched) {
    return RCore.$$Array.keep(fetched, (function (c) {
                  return !prevCats.some(function (cc) {
                              return Uuid.equal(c.uuid, cc.uuid);
                            });
                }));
  };
  var handleFilterResponse = function (newState) {
    return function (response) {
      if (response !== undefined) {
        if (response.TAG === "Ok") {
          var match = response._0;
          var products = match.products;
          var categories = match.categories;
          return setState(function (prev) {
                      return newState(products, categories, prev);
                    });
        }
        console.log(response._0);
        return ;
      }
      console.log("Error filtering products");
    };
  };
  var filter = function (filterName, filterCategory) {
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: prev.page,
                  categories: prev.categories,
                  products: prev.products,
                  units: prev.units,
                  fetching: true,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: filterName,
                  filterCategory: filterCategory
                };
        });
    var bounds = Utils.objToJson({
          offset: state.offset,
          limit: 50
        });
    var newState = function (products, categories, prev) {
      return {
              loaded: prev.loaded,
              page: prev.page,
              categories: Belt_Array.concatMany([
                    prev.categories,
                    newCats(prev.categories, categories)
                  ]),
              products: products,
              units: prev.units,
              fetching: false,
              hasMore: products.length === 50,
              offset: prev.offset,
              filterName: prev.filterName,
              filterCategory: prev.filterCategory
            };
    };
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(Products_API.filter([
                  wire,
                  bounds,
                  filterName,
                  filterCategory
                ]), handleFilterResponse(newState), (function (prim) {
                
              })), (function (prim) {
            
          }));
  };
  var onFilterNameChange = function (input) {
    filter(Fun.optString(input), state.filterCategory);
  };
  var onFilterCategoryPick = function (input) {
    filter(state.filterName, RCore.$$Option.map(input === null ? undefined : Caml_option.some(input), Catalog_Types.idToUuid));
  };
  var onLoadMore = function (e) {
    e.preventDefault();
    setState(function (prev) {
          return {
                  loaded: prev.loaded,
                  page: prev.page,
                  categories: prev.categories,
                  products: prev.products,
                  units: prev.units,
                  fetching: true,
                  hasMore: prev.hasMore,
                  offset: prev.offset,
                  filterName: prev.filterName,
                  filterCategory: prev.filterCategory
                };
        });
    var bounds = Utils.objToJson({
          offset: state.offset + 50 | 0,
          limit: 50
        });
    var newState = function (products, categories, prev) {
      return {
              loaded: prev.loaded,
              page: prev.page,
              categories: Belt_Array.concatMany([
                    prev.categories,
                    newCats(prev.categories, categories)
                  ]),
              products: Belt_Array.concatMany([
                    prev.products,
                    products
                  ]),
              units: prev.units,
              fetching: false,
              hasMore: products.length === 50,
              offset: prev.offset + 50 | 0,
              filterName: prev.filterName,
              filterCategory: prev.filterCategory
            };
    };
    Shared_Lib_IO.unsafeRunAsync(Shared_Lib_IO.bimap(Products_API.filter([
                  wire,
                  bounds,
                  state.filterName,
                  state.filterCategory
                ]), handleFilterResponse(newState), (function (prim) {
                
              })), (function (prim) {
            
          }));
  };
  var tmp;
  if (state.loaded) {
    var match$1 = state.page;
    switch (match$1) {
      case "Categories" :
          tmp = React.createElement(Categories_List.make, {
                wire: wire,
                categories: state.categories,
                onCreate: onCategoryCreate,
                onUpdate: onCategoryUpdate,
                onDelete: onCategoryDelete,
                type_: "Products"
              });
          break;
      case "Products" :
          tmp = React.createElement(Products_Index$Products_List, {
                wire: wire,
                products: state.products,
                filterName: state.filterName,
                onFilterNameChange: onFilterNameChange,
                onFilterCategoryPick: onFilterCategoryPick,
                filterCategory: state.filterCategory,
                hasMore: state.hasMore,
                fetching: state.fetching,
                onLoadMore: onLoadMore,
                categories: state.categories,
                units: state.units,
                onCreate: onProductCreate,
                onUpdate: onProductUpdate,
                onDelete: onProductDelete
              });
          break;
      case "Units" :
          tmp = React.createElement(CatalogUnits_List.make, {
                wire: wire,
                units: state.units,
                type_: "Products",
                onCreate: onUnitCreate,
                onUpdate: onUnitUpdate,
                onDelete: onUnitDelete
              });
          break;
      
    }
  } else {
    tmp = React.createElement("div", {
          className: "panel-body"
        }, React.createElement(Spinner.make, {}));
  }
  return React.createElement("div", {
              id: "content-wrapper"
            }, React.createElement("div", {
                  className: "page-container narrow"
                }, React.createElement("div", {
                      className: "page-header row"
                    }, React.createElement("h1", {
                          className: "settings-page__hl"
                        }, catalog$p(ctx), React.createElement("span", undefined, products$p(ctx)))), React.createElement("div", {
                      className: "settings-page settings-page_template row"
                    }, React.createElement(Optional.make, {
                          show: !hasFlag,
                          children: React.createElement(UI_FeatureTeaser.Large.make, {
                                className: styles.teaser,
                                minimalPlan: "pro"
                              })
                        }), React.createElement("ul", {
                          className: "nav nav-tabs"
                        }, React.createElement("li", {
                              className: state.page === "Products" ? "active" : "",
                              onClick: (function (param) {
                                  setPage("Products");
                                })
                            }, React.createElement("a", undefined, products$p(ctx))), React.createElement("li", {
                              className: state.page === "Categories" ? "active" : "",
                              onClick: (function (param) {
                                  setPage("Categories");
                                })
                            }, React.createElement("a", undefined, categories$p(ctx))), React.createElement("li", {
                              className: state.page === "Units" ? "active" : "",
                              onClick: (function (param) {
                                  setPage("Units");
                                })
                            }, React.createElement("a", undefined, units$p(ctx)))), React.createElement("div", {
                          className: "tab-content tab-pane active panel"
                        }, tmp))));
}

var make = Products_Index;

export {
  make ,
}
/* catalog' Not a pure module */
