

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Tour from "../../../libraries/tours/Tour.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Panel from "../Panel/Panel.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as ModalQuery from "../../../types/ModalQuery.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as AddJobButtonTypes from "./AddJobButtonTypes.mjs";
import * as AddJobButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/AddJobButton/AddJobButton.module.css";

var decode = JsonDecode.object(function (field) {
      return field.required("templates", JsonDecode.array(Context_Types.Template.decode));
    });

function reloadTemplates(wire) {
  return Backend.decode(Backend.get(undefined, true, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Template",
                    VAL: "Index"
                  }
                }), decode);
}

var addJob$p = Utils.Translations.tr("js.components.job_list.add_job");

var withoutTemplate$p = Utils.Translations.tr("js.components.job_list.without_template");

var emptyTemplateList$p = Utils.Translations.tr("js.components.job_list.empty_template_list");

var styles = AddJobButtonModuleCss;

function AddJobButton$FilteredTemplates(Props) {
  var wire = Props.wire;
  var templates = Props.templates;
  var filterEnabled = Props.filterEnabled;
  var query = Props.query;
  var onClick = Props.onClick;
  var templateClassName = Props.templateClassName;
  var ctx = wire.ctx;
  var tour = Tour.use(wire);
  var filteredTemplates = RCore.$$Array.reduce(templates, [], (function (templates, t) {
          var onClick$1 = function (e) {
            if (tour.trigger({
                    NAME: "templateSelected",
                    VAL: t.uuid
                  })) {
              return onClick(t.uuid)(e);
            }
            
          };
          if (query === "" || !filterEnabled || filterEnabled && Fun.stringContains(query, t.name)) {
            return Belt_Array.concatMany([
                        templates,
                        [React.createElement("button", {
                                key: Uuid.toString(t.uuid),
                                className: El.Cn.concat(styles.item, templateClassName(t)),
                                type: "button",
                                onClick: onClick$1
                              }, t.name)]
                      ]);
          } else {
            return templates;
          }
        }));
  if (filterEnabled && Caml_obj.equal(filteredTemplates, [])) {
    return React.createElement("div", {
                className: styles.emptyList
              }, emptyTemplateList$p(ctx));
  } else {
    return filteredTemplates;
  }
}

function AddJobButton(Props) {
  var wire = Props.wire;
  var loadedTemplates = Props.templates;
  var darkOpt = Props.dark;
  var compactOpt = Props.compact;
  var filterEnabledOpt = Props.filterEnabled;
  var jobType = Props.jobType;
  var optionsOpt = Props.options;
  var buttonClassNameOpt = Props.buttonClassName;
  var templateClassNameOpt = Props.templateClassName;
  var dark = darkOpt !== undefined ? darkOpt : false;
  var compact = compactOpt !== undefined ? compactOpt : false;
  var filterEnabled = filterEnabledOpt !== undefined ? filterEnabledOpt : false;
  var options = optionsOpt !== undefined ? optionsOpt : AddJobButtonTypes.emptyButtonOptions;
  var buttonClassName = buttonClassNameOpt !== undefined ? buttonClassNameOpt : "";
  var templateClassName = templateClassNameOpt !== undefined ? templateClassNameOpt : (function (param) {
        return "";
      });
  var match = React.useState(function () {
        return loadedTemplates;
      });
  var setTemplates = match[1];
  var templates = match[0];
  var sizeOfTemplates = templates.length;
  var filterEnabled$1 = filterEnabled && sizeOfTemplates > 7;
  var templateUuid = options.templateUuid;
  var subscriptions = wire.subscriptions;
  var ctx = wire.ctx;
  var buttonUuid = React.useMemo((function () {
          return Uuid.createV4();
        }), []);
  var match$1 = React.useState(function () {
        return false;
      });
  var setShow = match$1[1];
  var show = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setQuery = match$2[1];
  var query = match$2[0];
  var keyDown = function (param) {
    var $$event = param[0];
    var match = $$event.code;
    if (match === "Escape") {
      return setShow(function (param) {
                  return false;
                });
    } else {
      return param[1]($$event);
    }
  };
  var popoverRef = React.useRef(null);
  var scroll = function (scroll$1) {
    return function ($$event) {
      var target = $$event.target;
      var match = scroll$1.contents;
      var match$1 = DomUtils.windowScrollY();
      if (match === match$1) {
        return ;
      } else {
        RCore.$$Option.map(Caml_option.nullable_to_opt(popoverRef.current), (function (node) {
                scroll$1.contents = match$1;
                if (DomUtils.contains(node, target)) {
                  return ;
                } else {
                  return setShow(function (param) {
                              return false;
                            });
                }
              }));
        return ;
      }
    };
  };
  var reloadTemplates$1 = function () {
    var reloaded = function (result) {
      if (result.TAG !== "Ok") {
        return ;
      }
      var templates = result._0;
      setTemplates(function (param) {
            return templates;
          });
    };
    Backend.$$finally(reloadTemplates(wire), reloaded);
  };
  var tour = Tour.use(wire);
  var showPopover = function ($$event) {
    $$event.stopPropagation();
    tour.trigger({
          NAME: "templateSelectShown",
          VAL: !show
        });
    setShow(function (param) {
          return !show;
        });
  };
  var onQueryChange = function ($$event) {
    var value = $$event.target.value;
    setQuery(function (param) {
          return value;
        });
  };
  var filterBlock = filterEnabled$1 ? React.createElement("div", {
          className: styles.filterWrapper
        }, React.createElement("input", {
              className: styles.filter,
              value: query,
              onChange: onQueryChange
            }), query.length === 0 ? React.createElement("div", {
                className: styles.filterIcon
              }) : React.createElement("button", {
                className: El.Cn.concat(styles.reset, Icon.style(undefined, undefined, undefined, "times")),
                type: "button",
                onClick: (function (param) {
                    setQuery(function (param) {
                          return "";
                        });
                  })
              })) : null;
  var addJob = function (templateUuid) {
    return function ($$event) {
      $$event.preventDefault();
      subscriptions.jobs.trigger({
            TAG: "AddJob",
            _0: {
              TAG: "RelatedEntity",
              _0: ModalQuery.Job.NewOptions.RelatedEntity.make(options.clientUuid, options.siteUuid, RCore.$$Option.isNone(templateUuid) ? options.templateUuid : templateUuid)
            }
          });
      setShow(function (param) {
            return false;
          });
    };
  };
  var onClick = function (uuid) {
    if (jobType === "RecurringJob") {
      return function (param) {
        wire.navigate(undefined, {
              NAME: "RecurringJob",
              VAL: {
                NAME: "New",
                VAL: {
                  client: options.clientUuid,
                  site: options.siteUuid,
                  template: uuid
                }
              }
            });
      };
    } else {
      return addJob(Caml_option.some(uuid));
    }
  };
  React.useEffect((function () {
          var unsubscribeFromTemplates = subscriptions.templates.subscribe(function ($$event) {
                reloadTemplates$1();
              });
          var unsubscribeFromClick = subscriptions.click.subscribe(function (_event) {
                setShow(function (param) {
                      return false;
                    });
              });
          var unsubscribeFromScroll;
          if (Utils.isIOS()) {
            unsubscribeFromScroll = (function (prim) {
                
              });
          } else {
            var scrollY = {
              contents: DomUtils.windowScrollY()
            };
            unsubscribeFromScroll = subscriptions.scroll.subscribe(scroll(scrollY));
          }
          return (function () {
                    unsubscribeFromTemplates();
                    unsubscribeFromClick();
                    unsubscribeFromScroll();
                  });
        }), []);
  React.useEffect((function () {
          if (show) {
            return subscriptions.keydown.subscribe(keyDown);
          }
          
        }), [show]);
  React.useEffect((function () {
          setTemplates(function (param) {
                return loadedTemplates;
              });
        }), [loadedTemplates]);
  if (templateUuid !== undefined) {
    var className = El.Cn.concat(El.Cn.concat(styles.withoutTemplates, dark ? styles.darkButton : (
                compact ? styles.compactButton : styles.button
              )), buttonClassName);
    return React.createElement("button", {
                className: className,
                onClick: onClick(Caml_option.valFromOption(templateUuid))
              }, addJob$p(ctx));
  }
  var className$1 = El.Cn.concat(dark ? styles.darkButton : (
          compact ? styles.compactButton : styles.button
        ), buttonClassName);
  return React.createElement(React.Fragment, undefined, React.createElement("button", {
                  className: className$1,
                  id: Uuid.toString(buttonUuid),
                  type: "button",
                  onClick: showPopover
                }, addJob$p(ctx)), React.createElement(Panel.make, {
                  footer: wire.footer,
                  show: show,
                  domId: Uuid.toString(buttonUuid),
                  className: styles.dropdown,
                  children: React.createElement("div", {
                        ref: popoverRef,
                        className: dark ? styles.darkWrapper : ""
                      }, filterBlock, React.createElement("div", {
                            className: styles.items
                          }, React.createElement(AddJobButton$FilteredTemplates, {
                                wire: wire,
                                templates: templates,
                                filterEnabled: filterEnabled$1,
                                query: query,
                                onClick: onClick,
                                templateClassName: templateClassName
                              }), React.createElement("div", {
                                className: styles.separator
                              }), React.createElement(Optional.make, {
                                show: jobType === "Job",
                                children: React.createElement("button", {
                                      className: styles.item,
                                      type: "button",
                                      onClick: addJob(undefined)
                                    }, withoutTemplate$p(ctx))
                              })))
                }));
}

var Template;

var make = AddJobButton;

export {
  Template ,
  make ,
}
/* decode Not a pure module */
