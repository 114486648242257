

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Router from "../../Router.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as UI_Form from "../../ui/UI_Form.mjs";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as Settings_API from "./Settings_API.mjs";
import * as Settings_Map from "./Settings_Map.mjs";
import * as Settings_SMS from "./sms/Settings_SMS.mjs";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../messenger/MessengerTypes.mjs";
import * as Settings_Owner from "./Settings_Owner.mjs";
import * as Settings_Types from "./Settings_Types.mjs";
import * as Settings_Locale from "./Settings_Locale.mjs";
import * as Settings_General from "./Settings_General.mjs";
import * as Settings_IndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/settings/Settings_Index.module.css";

var heading$p = Utils.Translations.tr("js.settings.heading");

var save$p = Utils.Translations.tr("js.settings.buttons.save");

var updated$p = Utils.Translations.t("js.settings.messages.settings_updated");

var styles = Settings_IndexModuleCss;

function Settings_Index$Footer(Props) {
  var disabled = Props.disabled;
  var onSubmit = Props.onSubmit;
  var ctx = Props.ctx;
  return React.createElement("div", {
              className: styles.footer
            }, React.createElement("div", {
                  className: styles.footerButtonWrapper
                }, React.createElement(UI_Button.make, {
                      purpose: "primary",
                      disabled: disabled,
                      onClick: onSubmit,
                      children: save$p(ctx)
                    })));
}

function Settings_Index(Props) {
  var wire = Props.wire;
  var match = React.useState(function () {
        
      });
  var setStateOptional = match[1];
  var stateOptional = match[0];
  var match$1 = React.useState(function () {
        return Settings_Types.Errors.empty;
      });
  var setErrors = match$1[1];
  var errors = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSendingForm = match$2[1];
  var onSubmit = function ($$event) {
    if (stateOptional !== undefined) {
      $$event.preventDefault();
      setSendingForm(function (param) {
            return true;
          });
      return Backend.$$finally(Settings_API.Update.patch(wire, stateOptional), (function (r) {
                    if (r.TAG === "Ok") {
                      wire.subscriptions.messenger.trigger({
                            TAG: "Show",
                            _0: MessengerTypes.Message.make(undefined, undefined, {
                                  TAG: "Text",
                                  _0: updated$p(wire.ctx)
                                })
                          });
                      Router.reload();
                    } else {
                      var e = r._0;
                      if (typeof e !== "object" || e.TAG !== "HTTPError") {
                        console.log(e);
                      } else {
                        var match = e._0;
                        var tmp = match[0];
                        if (typeof tmp !== "object" && tmp === "UnprocessableEntity") {
                          var parsed = Backend_Errors.make(JSON.parse(match[1]));
                          setErrors(function (current) {
                                return {
                                        owner: current.owner,
                                        businessHours: Backend_Errors.getArray(parsed, "businessHours"),
                                        language: Backend_Errors.getArray(parsed, "locale.language"),
                                        timezone: Backend_Errors.getArray(parsed, "locale.timezone"),
                                        country: Backend_Errors.getArray(parsed, "locale.country"),
                                        dateFormat: Backend_Errors.getArray(parsed, "locale.dateFormat"),
                                        measurementSystem: Backend_Errors.getArray(parsed, "locale.measurementSystem"),
                                        thousandsSeparator: Backend_Errors.getArray(parsed, "locale.thousandsSeparator"),
                                        decimalMark: Backend_Errors.getArray(parsed, "locale.decimalMark"),
                                        currency: Backend_Errors.getArray(parsed, "currency"),
                                        currencyFullFormat: Backend_Errors.getArray(parsed, "locale.currencyFullFormat"),
                                        smsNearest: current.smsNearest,
                                        smsEnRoute: current.smsEnRoute,
                                        smsFinished: current.smsFinished
                                      };
                              });
                        } else {
                          console.log(e);
                        }
                      }
                    }
                    setSendingForm(function (param) {
                          return false;
                        });
                  }));
    }
    
  };
  React.useEffect((function () {
          Backend.$$finally(Backend.decode(Settings_API.Index.index(wire), Settings_Types.State.decode), (function (result) {
                  if (result.TAG === "Ok") {
                    var r = result._0;
                    return setStateOptional(function (param) {
                                return r;
                              });
                  }
                  console.log(result._0);
                }));
        }), []);
  if (stateOptional === undefined) {
    return null;
  }
  var setState = function (fun) {
    setStateOptional(function (current) {
          return RCore.$$Option.map(current, fun);
        });
  };
  return React.createElement(UI_Page.make, {
              children: React.createElement("form", undefined, React.createElement(UI_Form.Header.make, {
                        children: React.createElement(UI_Form.HeaderTitle.make, {
                              children: heading$p(wire.ctx)
                            })
                      }), React.createElement(Settings_General.make, {
                        state: stateOptional,
                        setState: setState,
                        errors: errors,
                        setErrors: setErrors
                      }), React.createElement(Settings_Locale.make, {
                        locale: stateOptional.locale,
                        setLocale: (function (locale) {
                            setState(function (current) {
                                  return {
                                          loaded: current.loaded,
                                          subdomain: current.subdomain,
                                          companyName: current.companyName,
                                          officePhone: current.officePhone,
                                          dadataToken: current.dadataToken,
                                          mapType: current.mapType,
                                          international: current.international,
                                          region: current.region,
                                          hasSms: current.hasSms,
                                          hasSmsProvider: current.hasSmsProvider,
                                          userUuid: current.userUuid,
                                          owner: current.owner,
                                          users: current.users,
                                          trackingForms: current.trackingForms,
                                          businessHours: current.businessHours,
                                          smsSettings: current.smsSettings,
                                          locale: locale,
                                          lookupRestrictions: current.lookupRestrictions
                                        };
                                });
                          }),
                        lookupRestrictions: stateOptional.lookupRestrictions,
                        setLookupRestrictions: (function (lookupRestrictions) {
                            setState(function (current) {
                                  return {
                                          loaded: current.loaded,
                                          subdomain: current.subdomain,
                                          companyName: current.companyName,
                                          officePhone: current.officePhone,
                                          dadataToken: current.dadataToken,
                                          mapType: current.mapType,
                                          international: current.international,
                                          region: current.region,
                                          hasSms: current.hasSms,
                                          hasSmsProvider: current.hasSmsProvider,
                                          userUuid: current.userUuid,
                                          owner: current.owner,
                                          users: current.users,
                                          trackingForms: current.trackingForms,
                                          businessHours: current.businessHours,
                                          smsSettings: current.smsSettings,
                                          locale: current.locale,
                                          lookupRestrictions: lookupRestrictions
                                        };
                                });
                          }),
                        errors: errors,
                        setErrors: setErrors
                      }), React.createElement(Optional.make, {
                        show: stateOptional.hasSms,
                        children: React.createElement(Settings_SMS.make, {
                              smsSettings: stateOptional.smsSettings,
                              hasSmsProvider: stateOptional.hasSmsProvider,
                              setSmsSettings: (function (smsSettings) {
                                  setState(function (current) {
                                        return {
                                                loaded: current.loaded,
                                                subdomain: current.subdomain,
                                                companyName: current.companyName,
                                                officePhone: current.officePhone,
                                                dadataToken: current.dadataToken,
                                                mapType: current.mapType,
                                                international: current.international,
                                                region: current.region,
                                                hasSms: current.hasSms,
                                                hasSmsProvider: current.hasSmsProvider,
                                                userUuid: current.userUuid,
                                                owner: current.owner,
                                                users: current.users,
                                                trackingForms: current.trackingForms,
                                                businessHours: current.businessHours,
                                                smsSettings: smsSettings,
                                                locale: current.locale,
                                                lookupRestrictions: current.lookupRestrictions
                                              };
                                      });
                                }),
                              errors: errors,
                              setErrors: setErrors,
                              trackingForms: stateOptional.trackingForms
                            })
                      }), React.createElement(Optional.make, {
                        show: wire.ctx.localizator.region === "r0",
                        children: React.createElement(Settings_Map.make, {
                              setMapType: (function (mapType) {
                                  setState(function (current) {
                                        return {
                                                loaded: current.loaded,
                                                subdomain: current.subdomain,
                                                companyName: current.companyName,
                                                officePhone: current.officePhone,
                                                dadataToken: current.dadataToken,
                                                mapType: mapType,
                                                international: current.international,
                                                region: current.region,
                                                hasSms: current.hasSms,
                                                hasSmsProvider: current.hasSmsProvider,
                                                userUuid: current.userUuid,
                                                owner: current.owner,
                                                users: current.users,
                                                trackingForms: current.trackingForms,
                                                businessHours: current.businessHours,
                                                smsSettings: current.smsSettings,
                                                locale: current.locale,
                                                lookupRestrictions: current.lookupRestrictions
                                              };
                                      });
                                }),
                              mapType: stateOptional.mapType
                            })
                      }), React.createElement(Optional.make, {
                        show: wire.ctx.owner && !wire.ctx.disposable,
                        children: React.createElement(Settings_Owner.make, {
                              currentUserUuid: stateOptional.userUuid,
                              users: stateOptional.users,
                              errors: errors,
                              setErrors: setErrors
                            })
                      }), React.createElement(Settings_Index$Footer, {
                        disabled: wire.ctx.disposable || match$2[0] || Caml_obj.notequal(errors, Settings_Types.Errors.empty),
                        onSubmit: onSubmit,
                        ctx: wire.ctx
                      }))
            });
}

var make = Settings_Index;

export {
  make ,
}
/* heading' Not a pure module */
