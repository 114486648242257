

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JobIndexAPI from "./JobIndexAPI.mjs";
import * as JobFieldType from "./JobFieldType.mjs";
import * as JobViewTypes from "./views/JobViewTypes.mjs";
import * as Shared_Lib_IO from "../../../shared/lib/Shared_Lib_IO.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as Backend_Headers from "../../../libraries/backend/Backend_Headers.mjs";
import * as JobIndexIdToUuid from "./JobIndexIdToUuid.mjs";
import * as Shared_Lib_Array from "../../../shared/lib/Shared_Lib_Array.mjs";
import * as Belt_Lib_SortArray from "../../../libraries/Belt_Lib_SortArray.mjs";
import * as Shared_Lib_Reducer from "../../../shared/lib/Shared_Lib_Reducer.mjs";

var filteringUnavaliable$p = Utils.Translations.t("js.jobs.index.filtering_unavaliable");

var emptyFilter = JobViewTypes.Settings.Filter.makeEmpty;

var emptyData_teams = [];

var emptyData_workers = [];

var emptyData_types = [];

var emptyData_templates = [];

var emptyData_resolutions = [];

var emptyData_territories = [];

var emptyData_reportFields = [];

var emptyData_customFields = [];

var emptyData = {
  teams: emptyData_teams,
  workers: emptyData_workers,
  types: emptyData_types,
  templates: emptyData_templates,
  resolutions: emptyData_resolutions,
  territories: emptyData_territories,
  reportFields: emptyData_reportFields,
  customFields: emptyData_customFields,
  filters: undefined
};

var defaultBounds = {
  offset: 0,
  limit: 50
};

var initialState_activeFilters = [];

var initialState_views = [];

var initialState_currentColumns = JobViewTypes.Settings.Columns.makeEmpty;

var initialState_jobs = [];

var initialState_count = {
  TAG: "Approx",
  _0: 0
};

var initialState_dictionaries = [];

var initialState = {
  data: emptyData,
  currentFilter: emptyFilter,
  activeFilters: initialState_activeFilters,
  views: initialState_views,
  activeView: undefined,
  currentColumns: initialState_currentColumns,
  jobs: initialState_jobs,
  loadingMore: false,
  jobsRefreshing: false,
  count: initialState_count,
  dictionaries: initialState_dictionaries,
  bounds: defaultBounds,
  pickingForBulk: false,
  operationSet: undefined
};

function filterRequest(headers, wire, newState, bounds, onFinish) {
  return Shared_Lib_Reducer.updateWithIO(newState, Shared_Lib_IO.bimap(JobIndexAPI.Filter.filter(headers, wire, newState.currentFilter, bounds, newState.data.resolutions, newState.data.types, newState.data.filters), (function (result) {
                    if (result.TAG === "Ok") {
                      var match = result._0;
                      return onFinish(match.jobs, match.count);
                    }
                    var match$1 = result._0;
                    if (typeof match$1 !== "object") {
                      return onFinish([], {
                                  TAG: "Exact",
                                  _0: 0
                                });
                    }
                    if (match$1.TAG !== "HTTPError") {
                      return onFinish([], {
                                  TAG: "Exact",
                                  _0: 0
                                });
                    }
                    var tmp = match$1._0[0];
                    if (typeof tmp === "object") {
                      return onFinish([], {
                                  TAG: "Exact",
                                  _0: 0
                                });
                    }
                    if (tmp !== "ServiceUnavailble") {
                      return onFinish([], {
                                  TAG: "Exact",
                                  _0: 0
                                });
                    }
                    wire.subscriptions.messenger.trigger({
                          TAG: "Show",
                          _0: MessengerTypes.Message.make(undefined, "Danger", {
                                TAG: "Text",
                                _0: filteringUnavaliable$p(wire.ctx)
                              })
                        });
                    return onFinish([], {
                                TAG: "Exact",
                                _0: 0
                              });
                  }), (function (_error) {
                    return "NoOp";
                  })));
}

function updateJobsList(wire, newState) {
  Utils.scrollToCoords({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
  if (newState.jobsRefreshing) {
    return Shared_Lib_Reducer.noUpdate;
  } else {
    return filterRequest(undefined, wire, {
                data: newState.data,
                currentFilter: newState.currentFilter,
                activeFilters: newState.activeFilters,
                views: newState.views,
                activeView: newState.activeView,
                currentColumns: newState.currentColumns,
                jobs: newState.jobs,
                loadingMore: newState.loadingMore,
                jobsRefreshing: true,
                count: newState.count,
                dictionaries: newState.dictionaries,
                bounds: defaultBounds,
                pickingForBulk: newState.pickingForBulk,
                operationSet: undefined
              }, defaultBounds, (function (jobs, count) {
                  return {
                          TAG: "UpdateJobsListSuccess",
                          _0: jobs,
                          _1: count
                        };
                }));
  }
}

function updateViews(nextActiveView, onFinish, io) {
  return Shared_Lib_IO.bimap(io, (function (result) {
                if (result.TAG === "Ok") {
                  return {
                          TAG: "SaveViewsSuccess",
                          _0: result._0.views,
                          _1: nextActiveView,
                          _2: onFinish
                        };
                } else {
                  return {
                          TAG: "SaveViewsError",
                          _0: result._0
                        };
                }
              }), (function (_error) {
                return "NoOp";
              }));
}

function updateFilters(onFinish, io) {
  return Shared_Lib_IO.bimap(io, (function (result) {
                if (result.TAG === "Ok") {
                  return {
                          TAG: "SaveFiltersSuccess",
                          _0: result._0.filters,
                          _1: onFinish
                        };
                } else {
                  return {
                          TAG: "SaveFiltersError",
                          _0: result._0
                        };
                }
              }), (function (_error) {
                return "NoOp";
              }));
}

function modifyCurrentFilter(state, currentFilter) {
  return {
          data: state.data,
          currentFilter: currentFilter,
          activeFilters: state.activeFilters,
          views: state.views,
          activeView: undefined,
          currentColumns: state.currentColumns,
          jobs: state.jobs,
          loadingMore: state.loadingMore,
          jobsRefreshing: state.jobsRefreshing,
          count: state.count,
          dictionaries: state.dictionaries,
          bounds: state.bounds,
          pickingForBulk: state.pickingForBulk,
          operationSet: state.operationSet
        };
}

function modifyCurrentColumns(state, currentColumns) {
  return {
          data: state.data,
          currentFilter: state.currentFilter,
          activeFilters: state.activeFilters,
          views: state.views,
          activeView: undefined,
          currentColumns: currentColumns,
          jobs: state.jobs,
          loadingMore: state.loadingMore,
          jobsRefreshing: state.jobsRefreshing,
          count: state.count,
          dictionaries: state.dictionaries,
          bounds: state.bounds,
          pickingForBulk: state.pickingForBulk,
          operationSet: state.operationSet
        };
}

function toggle(array, value, add) {
  if (add) {
    return Belt_Array.concatMany([
                [value],
                array
              ]);
  } else {
    return RCore.$$Array.keep(array, (function (t) {
                  return Caml_obj.notequal(t, value);
                }));
  }
}

function toggleColumns(columns, column) {
  return toggle(columns, column, !columns.some(function (c) {
                  return Caml_obj.equal(c, column);
                }));
}

function addPredefinedField(fields, key, value) {
  return Belt_Array.concatMany([
              [{
                  key: key,
                  value: value
                }],
              RCore.$$Array.keep(fields, (function (f) {
                      return f.key !== key;
                    }))
            ]);
}

function addCustomField(fields, typeUuid, value) {
  return Belt_Array.concatMany([
              [{
                  typeUuid: typeUuid,
                  value: value
                }],
              RCore.$$Array.keep(fields, (function (f) {
                      return !Uuid.equal(f.typeUuid, typeUuid);
                    }))
            ]);
}

function shouldRefreshJobList(field) {
  switch (field.TAG) {
    case "Dictionary" :
        var match = field._0;
        if (typeof match !== "object") {
          return true;
        } else {
          return match._0.length !== 0;
        }
    case "String" :
        var match$1 = field._0;
        if (typeof match$1 !== "object") {
          return true;
        } else {
          return match$1._0 !== undefined;
        }
    default:
      return true;
  }
}

function keepResolutions(resolutions, type_) {
  return RCore.$$Array.filterMap(resolutions, (function (r) {
                if (r.type_ === type_) {
                  return Caml_option.some(r.uuid);
                }
                
              }));
}

function reducer(wire) {
  return function (param) {
    var action = param[1];
    var state = param[0];
    if (typeof action !== "object") {
      switch (action) {
        case "ReturnDefaultView" :
            var firstView = state.views[0];
            var init = firstView.settings;
            var view_uuid = firstView.uuid;
            var view_name = firstView.name;
            var view_settings = {
              columns: JobViewTypes.Settings.Columns.makeDefault,
              filter: init.filter,
              scheduled: init.scheduled,
              resolutions: init.resolutions,
              upToDate: init.upToDate
            };
            var view_orderNo = firstView.orderNo;
            var view_system = firstView.system;
            var view_destroy = firstView.destroy;
            var view = {
              uuid: view_uuid,
              name: view_name,
              settings: view_settings,
              orderNo: view_orderNo,
              system: view_system,
              destroy: view_destroy
            };
            return {
                    TAG: "IO",
                    _0: updateViews(Caml_option.some(view_uuid), (function () {
                            
                          }), JobIndexAPI.JobViewSettings.update(wire, [view]))
                  };
        case "SelectSuccessfulResolutions" :
            var init$1 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$1.teams,
                            client: init$1.client,
                            workers: init$1.workers,
                            statuses: init$1.statuses,
                            templates: init$1.templates,
                            resolutions: keepResolutions(state.data.resolutions, "Successful"),
                            canceledResolutions: init$1.canceledResolutions,
                            territories: init$1.territories,
                            finishedAt: init$1.finishedAt,
                            scheduledAt: init$1.scheduledAt,
                            createdAt: init$1.createdAt,
                            suspendedAt: init$1.suspendedAt,
                            resumedAt: init$1.resumedAt,
                            predefinedFields: init$1.predefinedFields,
                            customFields: init$1.customFields,
                            reportFields: init$1.reportFields
                          }));
        case "ResetAssignees" :
            var init$2 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: emptyFilter.teams,
                            client: init$2.client,
                            workers: emptyFilter.workers,
                            statuses: init$2.statuses,
                            templates: init$2.templates,
                            resolutions: init$2.resolutions,
                            canceledResolutions: init$2.canceledResolutions,
                            territories: init$2.territories,
                            finishedAt: init$2.finishedAt,
                            scheduledAt: init$2.scheduledAt,
                            createdAt: init$2.createdAt,
                            suspendedAt: init$2.suspendedAt,
                            resumedAt: init$2.resumedAt,
                            predefinedFields: init$2.predefinedFields,
                            customFields: init$2.customFields,
                            reportFields: init$2.reportFields
                          }));
        case "ResetStatuses" :
            var init$3 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$3.teams,
                            client: init$3.client,
                            workers: init$3.workers,
                            statuses: emptyFilter.statuses,
                            templates: init$3.templates,
                            resolutions: emptyFilter.resolutions,
                            canceledResolutions: emptyFilter.canceledResolutions,
                            territories: init$3.territories,
                            finishedAt: init$3.finishedAt,
                            scheduledAt: init$3.scheduledAt,
                            createdAt: init$3.createdAt,
                            suspendedAt: init$3.suspendedAt,
                            resumedAt: init$3.resumedAt,
                            predefinedFields: init$3.predefinedFields,
                            customFields: init$3.customFields,
                            reportFields: init$3.reportFields
                          }));
        case "ResetTemplates" :
            var init$4 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$4.teams,
                            client: init$4.client,
                            workers: init$4.workers,
                            statuses: init$4.statuses,
                            templates: emptyFilter.templates,
                            resolutions: init$4.resolutions,
                            canceledResolutions: init$4.canceledResolutions,
                            territories: init$4.territories,
                            finishedAt: init$4.finishedAt,
                            scheduledAt: init$4.scheduledAt,
                            createdAt: init$4.createdAt,
                            suspendedAt: init$4.suspendedAt,
                            resumedAt: init$4.resumedAt,
                            predefinedFields: init$4.predefinedFields,
                            customFields: init$4.customFields,
                            reportFields: init$4.reportFields
                          }));
        case "ResetScheduledAt" :
            var init$5 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$5.teams,
                            client: init$5.client,
                            workers: init$5.workers,
                            statuses: init$5.statuses,
                            templates: init$5.templates,
                            resolutions: init$5.resolutions,
                            canceledResolutions: init$5.canceledResolutions,
                            territories: init$5.territories,
                            finishedAt: init$5.finishedAt,
                            scheduledAt: emptyFilter.scheduledAt,
                            createdAt: init$5.createdAt,
                            suspendedAt: init$5.suspendedAt,
                            resumedAt: init$5.resumedAt,
                            predefinedFields: init$5.predefinedFields,
                            customFields: init$5.customFields,
                            reportFields: init$5.reportFields
                          }));
        case "ResetFinishedAt" :
            var init$6 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$6.teams,
                            client: init$6.client,
                            workers: init$6.workers,
                            statuses: init$6.statuses,
                            templates: init$6.templates,
                            resolutions: init$6.resolutions,
                            canceledResolutions: init$6.canceledResolutions,
                            territories: init$6.territories,
                            finishedAt: emptyFilter.finishedAt,
                            scheduledAt: init$6.scheduledAt,
                            createdAt: init$6.createdAt,
                            suspendedAt: init$6.suspendedAt,
                            resumedAt: init$6.resumedAt,
                            predefinedFields: init$6.predefinedFields,
                            customFields: init$6.customFields,
                            reportFields: init$6.reportFields
                          }));
        case "ResetTerritories" :
            var init$7 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$7.teams,
                            client: init$7.client,
                            workers: init$7.workers,
                            statuses: init$7.statuses,
                            templates: init$7.templates,
                            resolutions: init$7.resolutions,
                            canceledResolutions: init$7.canceledResolutions,
                            territories: emptyFilter.territories,
                            finishedAt: init$7.finishedAt,
                            scheduledAt: init$7.scheduledAt,
                            createdAt: init$7.createdAt,
                            suspendedAt: init$7.suspendedAt,
                            resumedAt: init$7.resumedAt,
                            predefinedFields: init$7.predefinedFields,
                            customFields: init$7.customFields,
                            reportFields: init$7.reportFields
                          }));
        case "ResetClient" :
            var init$8 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$8.teams,
                            client: emptyFilter.client,
                            workers: init$8.workers,
                            statuses: init$8.statuses,
                            templates: init$8.templates,
                            resolutions: init$8.resolutions,
                            canceledResolutions: init$8.canceledResolutions,
                            territories: init$8.territories,
                            finishedAt: init$8.finishedAt,
                            scheduledAt: init$8.scheduledAt,
                            createdAt: init$8.createdAt,
                            suspendedAt: init$8.suspendedAt,
                            resumedAt: init$8.resumedAt,
                            predefinedFields: init$8.predefinedFields,
                            customFields: init$8.customFields,
                            reportFields: init$8.reportFields
                          }));
        case "ResetCreatedAt" :
            var init$9 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$9.teams,
                            client: init$9.client,
                            workers: init$9.workers,
                            statuses: init$9.statuses,
                            templates: init$9.templates,
                            resolutions: init$9.resolutions,
                            canceledResolutions: init$9.canceledResolutions,
                            territories: init$9.territories,
                            finishedAt: init$9.finishedAt,
                            scheduledAt: init$9.scheduledAt,
                            createdAt: emptyFilter.createdAt,
                            suspendedAt: init$9.suspendedAt,
                            resumedAt: init$9.resumedAt,
                            predefinedFields: init$9.predefinedFields,
                            customFields: init$9.customFields,
                            reportFields: init$9.reportFields
                          }));
        case "ResetSuspendedAt" :
            var init$10 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$10.teams,
                            client: init$10.client,
                            workers: init$10.workers,
                            statuses: init$10.statuses,
                            templates: init$10.templates,
                            resolutions: init$10.resolutions,
                            canceledResolutions: init$10.canceledResolutions,
                            territories: init$10.territories,
                            finishedAt: init$10.finishedAt,
                            scheduledAt: init$10.scheduledAt,
                            createdAt: init$10.createdAt,
                            suspendedAt: emptyFilter.suspendedAt,
                            resumedAt: init$10.resumedAt,
                            predefinedFields: init$10.predefinedFields,
                            customFields: init$10.customFields,
                            reportFields: init$10.reportFields
                          }));
        case "ResetResumedAt" :
            var init$11 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$11.teams,
                            client: init$11.client,
                            workers: init$11.workers,
                            statuses: init$11.statuses,
                            templates: init$11.templates,
                            resolutions: init$11.resolutions,
                            canceledResolutions: init$11.canceledResolutions,
                            territories: init$11.territories,
                            finishedAt: init$11.finishedAt,
                            scheduledAt: init$11.scheduledAt,
                            createdAt: init$11.createdAt,
                            suspendedAt: init$11.suspendedAt,
                            resumedAt: emptyFilter.resumedAt,
                            predefinedFields: init$11.predefinedFields,
                            customFields: init$11.customFields,
                            reportFields: init$11.reportFields
                          }));
        case "RefreshJobs" :
            return updateJobsList(wire, state);
        case "PickForOperation" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: true,
                      operationSet: state.operationSet
                    }
                  };
        case "CancelOperationPicking" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: false,
                      operationSet: undefined
                    }
                  };
        case "PickFilterOperationSet" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: {
                        TAG: "CurrentFilter",
                        _0: []
                      }
                    }
                  };
        case "EmptyOperationSet" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: undefined
                    }
                  };
        case "NoOp" :
            return "NoUpdate";
        
      }
    } else {
      switch (action.TAG) {
        case "FetchJobs" :
            var data = action._0;
            var sortedViews = Belt_Lib_SortArray.stableSortBy(data.views, (function (a, b) {
                    return a.orderNo - b.orderNo | 0;
                  }));
            var firstView$1 = sortedViews[0];
            return {
                    TAG: "Update",
                    _0: {
                      data: {
                        teams: Utils.CompareLocale.sortAlphabetical(data.teams, (function (t) {
                                return t.name;
                              }), wire),
                        workers: Utils.CompareLocale.sortAlphabetical(data.workers, (function (w) {
                                return w.name;
                              }), wire),
                        types: data.types,
                        templates: data.templates,
                        resolutions: data.resolutions,
                        territories: data.territories,
                        reportFields: data.reportFields,
                        customFields: data.customFields,
                        filters: data.filters
                      },
                      currentFilter: firstView$1.settings.filter,
                      activeFilters: state.activeFilters,
                      views: sortedViews,
                      activeView: Caml_option.some(firstView$1.uuid),
                      currentColumns: firstView$1.settings.columns,
                      jobs: data.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: data.count,
                      dictionaries: data.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "SelectView" :
            var viewUuid = action._0;
            var activeView = RCore.$$Array.getBy(state.views, (function (param) {
                    return Uuid.equal(param.uuid, viewUuid);
                  }));
            if (activeView === undefined) {
              return "NoUpdate";
            }
            var filterWithLastWeek;
            if (Caml_obj.equal(activeView.settings.scheduled, "LastWeek")) {
              var start = Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                        NAME: "weeks",
                        VAL: -1
                      }));
              var finish = Locale.T.endOf("day", Locale.T.now(wire));
              var init$12 = activeView.settings.filter;
              filterWithLastWeek = {
                teams: init$12.teams,
                client: init$12.client,
                workers: init$12.workers,
                statuses: init$12.statuses,
                templates: init$12.templates,
                resolutions: init$12.resolutions,
                canceledResolutions: init$12.canceledResolutions,
                territories: init$12.territories,
                finishedAt: init$12.finishedAt,
                scheduledAt: {
                  TAG: "Interval",
                  _0: {
                    TAG: "Specific",
                    _0: [
                      start,
                      finish
                    ]
                  }
                },
                createdAt: init$12.createdAt,
                suspendedAt: init$12.suspendedAt,
                resumedAt: init$12.resumedAt,
                predefinedFields: init$12.predefinedFields,
                customFields: init$12.customFields,
                reportFields: init$12.reportFields
              };
            } else {
              filterWithLastWeek = activeView.settings.filter;
            }
            var filter = Caml_obj.equal(activeView.settings.resolutions, "Successful") ? ({
                  teams: filterWithLastWeek.teams,
                  client: filterWithLastWeek.client,
                  workers: filterWithLastWeek.workers,
                  statuses: Shared_Lib_Array.distinctBy((function (a, b) {
                          return a === b;
                        }), Belt_Array.concatMany([
                            ["Finished"],
                            activeView.settings.filter.statuses
                          ])),
                  templates: filterWithLastWeek.templates,
                  resolutions: Shared_Lib_Array.distinctBy(Caml_obj.equal, Belt_Array.concatMany([
                            activeView.settings.filter.resolutions,
                            keepResolutions(state.data.resolutions, "Successful")
                          ])),
                  canceledResolutions: filterWithLastWeek.canceledResolutions,
                  territories: filterWithLastWeek.territories,
                  finishedAt: filterWithLastWeek.finishedAt,
                  scheduledAt: filterWithLastWeek.scheduledAt,
                  createdAt: filterWithLastWeek.createdAt,
                  suspendedAt: filterWithLastWeek.suspendedAt,
                  resumedAt: filterWithLastWeek.resumedAt,
                  predefinedFields: filterWithLastWeek.predefinedFields,
                  customFields: filterWithLastWeek.customFields,
                  reportFields: filterWithLastWeek.reportFields
                }) : (
                Caml_obj.equal(activeView.settings.resolutions, "Unsuccessful") ? ({
                      teams: filterWithLastWeek.teams,
                      client: filterWithLastWeek.client,
                      workers: filterWithLastWeek.workers,
                      statuses: Shared_Lib_Array.distinctBy((function (a, b) {
                              return a === b;
                            }), Belt_Array.concatMany([
                                ["Finished"],
                                activeView.settings.filter.statuses
                              ])),
                      templates: filterWithLastWeek.templates,
                      resolutions: Shared_Lib_Array.distinctBy(Caml_obj.equal, Belt_Array.concatMany([
                                activeView.settings.filter.resolutions,
                                keepResolutions(state.data.resolutions, "Unsuccessful")
                              ])),
                      canceledResolutions: filterWithLastWeek.canceledResolutions,
                      territories: filterWithLastWeek.territories,
                      finishedAt: filterWithLastWeek.finishedAt,
                      scheduledAt: filterWithLastWeek.scheduledAt,
                      createdAt: filterWithLastWeek.createdAt,
                      suspendedAt: filterWithLastWeek.suspendedAt,
                      resumedAt: filterWithLastWeek.resumedAt,
                      predefinedFields: filterWithLastWeek.predefinedFields,
                      customFields: filterWithLastWeek.customFields,
                      reportFields: filterWithLastWeek.reportFields
                    }) : filterWithLastWeek
              );
            return updateJobsList(wire, {
                        data: state.data,
                        currentFilter: filter,
                        activeFilters: state.activeFilters,
                        views: state.views,
                        activeView: Caml_option.some(viewUuid),
                        currentColumns: activeView.settings.columns,
                        jobs: state.jobs,
                        loadingMore: state.loadingMore,
                        jobsRefreshing: state.jobsRefreshing,
                        count: state.count,
                        dictionaries: state.dictionaries,
                        bounds: state.bounds,
                        pickingForBulk: state.pickingForBulk,
                        operationSet: state.operationSet
                      });
        case "ToggleVirtualColumn" :
            var columns = toggleColumns(state.currentColumns.virtual, action._0);
            var init$13 = state.currentColumns;
            return {
                    TAG: "Update",
                    _0: modifyCurrentColumns(state, {
                          jobFields: init$13.jobFields,
                          customFields: init$13.customFields,
                          reportFields: init$13.reportFields,
                          virtual: RCore.$$Array.keep(JobViewTypes.Virtual.allFields, (function (r) {
                                  return columns.some(function (f) {
                                              return f === r;
                                            });
                                }))
                        })
                  };
        case "ToggleCustomColumn" :
            var columns$1 = toggleColumns(state.currentColumns.customFields, action._0);
            var init$14 = state.currentColumns;
            return {
                    TAG: "Update",
                    _0: modifyCurrentColumns(state, {
                          jobFields: init$14.jobFields,
                          customFields: RCore.$$Array.filterMap(state.data.customFields, (function (r) {
                                  if (columns$1.some(function (f) {
                                          return Uuid.equal(f, r.uuid);
                                        })) {
                                    return Caml_option.some(r.uuid);
                                  }
                                  
                                })),
                          reportFields: init$14.reportFields,
                          virtual: init$14.virtual
                        })
                  };
        case "ToggleReportColumn" :
            var columns$2 = toggleColumns(state.currentColumns.reportFields, action._0);
            var init$15 = state.currentColumns;
            return {
                    TAG: "Update",
                    _0: modifyCurrentColumns(state, {
                          jobFields: init$15.jobFields,
                          customFields: init$15.customFields,
                          reportFields: RCore.$$Array.filterMap(state.data.reportFields, (function (r) {
                                  if (columns$2.some(function (f) {
                                          return Uuid.equal(f, r.uuid);
                                        })) {
                                    return Caml_option.some(r.uuid);
                                  }
                                  
                                })),
                          virtual: init$15.virtual
                        })
                  };
        case "ToggleJobColumn" :
            var columns$3 = toggleColumns(state.currentColumns.jobFields, action._0);
            var init$16 = state.currentColumns;
            return {
                    TAG: "Update",
                    _0: modifyCurrentColumns(state, {
                          jobFields: RCore.$$Array.filterMap(JobFieldType.allFields, (function (param) {
                                  var r = param[0];
                                  if (columns$3.some(function (f) {
                                          return f === r;
                                        })) {
                                    return r;
                                  }
                                  
                                })),
                          customFields: init$16.customFields,
                          reportFields: init$16.reportFields,
                          virtual: init$16.virtual
                        })
                  };
        case "CreateView" :
            var viewUuid$1 = Uuid.createV4();
            return {
                    TAG: "IO",
                    _0: updateViews(Caml_option.some(viewUuid$1), action._1, JobIndexAPI.JobViewSettings.create(wire, {
                              uuid: viewUuid$1,
                              name: action._0,
                              settings: {
                                columns: state.currentColumns,
                                filter: state.currentFilter,
                                scheduled: undefined,
                                resolutions: undefined,
                                upToDate: true
                              },
                              orderNo: state.views.length + 1 | 0,
                              system: false,
                              destroy: false
                            }, JobIndexIdToUuid.make(state.data.resolutions.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }), state.data.workers.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }), state.data.teams.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }), state.data.templates.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }), state.data.customFields.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }), state.data.reportFields.map(function (r) {
                                      return [
                                              r.id,
                                              r.uuid
                                            ];
                                    }))))
                  };
        case "SaveView" :
            var viewUuid$2 = action._0;
            var view$1 = RCore.$$Array.getBy(state.views, (function (param) {
                    return Uuid.equal(viewUuid$2, param.uuid);
                  }));
            var views;
            if (view$1 !== undefined) {
              if (view$1.system) {
                var init$17 = view$1.settings;
                views = [{
                    uuid: view$1.uuid,
                    name: view$1.name,
                    settings: {
                      columns: state.currentColumns,
                      filter: init$17.filter,
                      scheduled: init$17.scheduled,
                      resolutions: init$17.resolutions,
                      upToDate: init$17.upToDate
                    },
                    orderNo: view$1.orderNo,
                    system: view$1.system,
                    destroy: view$1.destroy
                  }];
              } else {
                var init$18 = view$1.settings;
                views = [{
                    uuid: view$1.uuid,
                    name: view$1.name,
                    settings: {
                      columns: state.currentColumns,
                      filter: state.currentFilter,
                      scheduled: init$18.scheduled,
                      resolutions: init$18.resolutions,
                      upToDate: init$18.upToDate
                    },
                    orderNo: view$1.orderNo,
                    system: view$1.system,
                    destroy: view$1.destroy
                  }];
              }
            } else {
              views = [];
            }
            return {
                    TAG: "IO",
                    _0: updateViews(Caml_option.some(viewUuid$2), action._1, JobIndexAPI.JobViewSettings.update(wire, views))
                  };
        case "SaveViews" :
            return {
                    TAG: "IO",
                    _0: updateViews(state.activeView, action._1, JobIndexAPI.JobViewSettings.update(wire, action._0))
                  };
        case "CreateFilters" :
            return {
                    TAG: "IO",
                    _0: updateFilters(action._3, JobIndexAPI.JobFilterSettings.create(wire, action._0, action._1, action._2))
                  };
        case "UpdateFilters" :
            return {
                    TAG: "IO",
                    _0: updateFilters(action._4, JobIndexAPI.JobFilterSettings.update(wire, action._0, action._1, action._2, action._3))
                  };
        case "FilterByWorker" :
            var init$19 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$19.teams,
                            client: init$19.client,
                            workers: toggle(state.currentFilter.workers, action._0, action._1),
                            statuses: init$19.statuses,
                            templates: init$19.templates,
                            resolutions: init$19.resolutions,
                            canceledResolutions: init$19.canceledResolutions,
                            territories: init$19.territories,
                            finishedAt: init$19.finishedAt,
                            scheduledAt: init$19.scheduledAt,
                            createdAt: init$19.createdAt,
                            suspendedAt: init$19.suspendedAt,
                            resumedAt: init$19.resumedAt,
                            predefinedFields: init$19.predefinedFields,
                            customFields: init$19.customFields,
                            reportFields: init$19.reportFields
                          }));
        case "FilterByTeam" :
            var init$20 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: toggle(state.currentFilter.teams, action._0, action._1),
                            client: init$20.client,
                            workers: init$20.workers,
                            statuses: init$20.statuses,
                            templates: init$20.templates,
                            resolutions: init$20.resolutions,
                            canceledResolutions: init$20.canceledResolutions,
                            territories: init$20.territories,
                            finishedAt: init$20.finishedAt,
                            scheduledAt: init$20.scheduledAt,
                            createdAt: init$20.createdAt,
                            suspendedAt: init$20.suspendedAt,
                            resumedAt: init$20.resumedAt,
                            predefinedFields: init$20.predefinedFields,
                            customFields: init$20.customFields,
                            reportFields: init$20.reportFields
                          }));
        case "FilterByStatus" :
            var add = action._1;
            var status = action._0;
            var newState;
            if (!add && status === "Canceled") {
              var init$21 = state.currentFilter;
              newState = {
                teams: init$21.teams,
                client: init$21.client,
                workers: init$21.workers,
                statuses: toggle(state.currentFilter.statuses, status, add),
                templates: init$21.templates,
                resolutions: init$21.resolutions,
                canceledResolutions: [],
                territories: init$21.territories,
                finishedAt: init$21.finishedAt,
                scheduledAt: init$21.scheduledAt,
                createdAt: init$21.createdAt,
                suspendedAt: init$21.suspendedAt,
                resumedAt: init$21.resumedAt,
                predefinedFields: init$21.predefinedFields,
                customFields: init$21.customFields,
                reportFields: init$21.reportFields
              };
            } else if (!add && status === "Finished") {
              var init$22 = state.currentFilter;
              newState = {
                teams: init$22.teams,
                client: init$22.client,
                workers: init$22.workers,
                statuses: toggle(state.currentFilter.statuses, status, add),
                templates: init$22.templates,
                resolutions: [],
                canceledResolutions: init$22.canceledResolutions,
                territories: init$22.territories,
                finishedAt: init$22.finishedAt,
                scheduledAt: init$22.scheduledAt,
                createdAt: init$22.createdAt,
                suspendedAt: init$22.suspendedAt,
                resumedAt: init$22.resumedAt,
                predefinedFields: init$22.predefinedFields,
                customFields: init$22.customFields,
                reportFields: init$22.reportFields
              };
            } else {
              var init$23 = state.currentFilter;
              newState = {
                teams: init$23.teams,
                client: init$23.client,
                workers: init$23.workers,
                statuses: toggle(state.currentFilter.statuses, status, add),
                templates: init$23.templates,
                resolutions: init$23.resolutions,
                canceledResolutions: init$23.canceledResolutions,
                territories: init$23.territories,
                finishedAt: init$23.finishedAt,
                scheduledAt: init$23.scheduledAt,
                createdAt: init$23.createdAt,
                suspendedAt: init$23.suspendedAt,
                resumedAt: init$23.resumedAt,
                predefinedFields: init$23.predefinedFields,
                customFields: init$23.customFields,
                reportFields: init$23.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState));
        case "AppendResolutions" :
            var newState$1;
            if (action._0 === "Canceled") {
              var init$24 = state.currentFilter;
              newState$1 = {
                teams: init$24.teams,
                client: init$24.client,
                workers: init$24.workers,
                statuses: init$24.statuses,
                templates: init$24.templates,
                resolutions: init$24.resolutions,
                canceledResolutions: Shared_Lib_Array.distinctBy(Caml_obj.equal, Belt_Array.concatMany([
                          state.currentFilter.canceledResolutions,
                          keepResolutions(state.data.resolutions, "Unsuccessful")
                        ])),
                territories: init$24.territories,
                finishedAt: init$24.finishedAt,
                scheduledAt: init$24.scheduledAt,
                createdAt: init$24.createdAt,
                suspendedAt: init$24.suspendedAt,
                resumedAt: init$24.resumedAt,
                predefinedFields: init$24.predefinedFields,
                customFields: init$24.customFields,
                reportFields: init$24.reportFields
              };
            } else {
              var init$25 = state.currentFilter;
              newState$1 = {
                teams: init$25.teams,
                client: init$25.client,
                workers: init$25.workers,
                statuses: init$25.statuses,
                templates: init$25.templates,
                resolutions: Shared_Lib_Array.distinctBy(Caml_obj.equal, Belt_Array.concatMany([
                          state.currentFilter.resolutions,
                          keepResolutions(state.data.resolutions, action._1)
                        ])),
                canceledResolutions: init$25.canceledResolutions,
                territories: init$25.territories,
                finishedAt: init$25.finishedAt,
                scheduledAt: init$25.scheduledAt,
                createdAt: init$25.createdAt,
                suspendedAt: init$25.suspendedAt,
                resumedAt: init$25.resumedAt,
                predefinedFields: init$25.predefinedFields,
                customFields: init$25.customFields,
                reportFields: init$25.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState$1));
        case "SelectAllResolutions" :
            var newState$2;
            if (action._0 === "Canceled") {
              var init$26 = state.currentFilter;
              newState$2 = {
                teams: init$26.teams,
                client: init$26.client,
                workers: init$26.workers,
                statuses: init$26.statuses,
                templates: init$26.templates,
                resolutions: init$26.resolutions,
                canceledResolutions: keepResolutions(state.data.resolutions, "Unsuccessful"),
                territories: init$26.territories,
                finishedAt: init$26.finishedAt,
                scheduledAt: init$26.scheduledAt,
                createdAt: init$26.createdAt,
                suspendedAt: init$26.suspendedAt,
                resumedAt: init$26.resumedAt,
                predefinedFields: init$26.predefinedFields,
                customFields: init$26.customFields,
                reportFields: init$26.reportFields
              };
            } else {
              var init$27 = state.currentFilter;
              newState$2 = {
                teams: init$27.teams,
                client: init$27.client,
                workers: init$27.workers,
                statuses: init$27.statuses,
                templates: init$27.templates,
                resolutions: state.data.resolutions.map(function (r) {
                      return r.uuid;
                    }),
                canceledResolutions: init$27.canceledResolutions,
                territories: init$27.territories,
                finishedAt: init$27.finishedAt,
                scheduledAt: init$27.scheduledAt,
                createdAt: init$27.createdAt,
                suspendedAt: init$27.suspendedAt,
                resumedAt: init$27.resumedAt,
                predefinedFields: init$27.predefinedFields,
                customFields: init$27.customFields,
                reportFields: init$27.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState$2));
        case "SelectUnsuccessfulResolutions" :
            var newState$3;
            if (action._0 === "Canceled") {
              var init$28 = state.currentFilter;
              newState$3 = {
                teams: init$28.teams,
                client: init$28.client,
                workers: init$28.workers,
                statuses: init$28.statuses,
                templates: init$28.templates,
                resolutions: init$28.resolutions,
                canceledResolutions: keepResolutions(state.data.resolutions, "Unsuccessful"),
                territories: init$28.territories,
                finishedAt: init$28.finishedAt,
                scheduledAt: init$28.scheduledAt,
                createdAt: init$28.createdAt,
                suspendedAt: init$28.suspendedAt,
                resumedAt: init$28.resumedAt,
                predefinedFields: init$28.predefinedFields,
                customFields: init$28.customFields,
                reportFields: init$28.reportFields
              };
            } else {
              var init$29 = state.currentFilter;
              newState$3 = {
                teams: init$29.teams,
                client: init$29.client,
                workers: init$29.workers,
                statuses: init$29.statuses,
                templates: init$29.templates,
                resolutions: keepResolutions(state.data.resolutions, "Unsuccessful"),
                canceledResolutions: init$29.canceledResolutions,
                territories: init$29.territories,
                finishedAt: init$29.finishedAt,
                scheduledAt: init$29.scheduledAt,
                createdAt: init$29.createdAt,
                suspendedAt: init$29.suspendedAt,
                resumedAt: init$29.resumedAt,
                predefinedFields: init$29.predefinedFields,
                customFields: init$29.customFields,
                reportFields: init$29.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState$3));
        case "FilterByResolution" :
            var add$1 = action._2;
            var resolution = action._0;
            var newState$4;
            if (action._1 === "Canceled") {
              var init$30 = state.currentFilter;
              newState$4 = {
                teams: init$30.teams,
                client: init$30.client,
                workers: init$30.workers,
                statuses: init$30.statuses,
                templates: init$30.templates,
                resolutions: init$30.resolutions,
                canceledResolutions: toggle(state.currentFilter.canceledResolutions, resolution, add$1),
                territories: init$30.territories,
                finishedAt: init$30.finishedAt,
                scheduledAt: init$30.scheduledAt,
                createdAt: init$30.createdAt,
                suspendedAt: init$30.suspendedAt,
                resumedAt: init$30.resumedAt,
                predefinedFields: init$30.predefinedFields,
                customFields: init$30.customFields,
                reportFields: init$30.reportFields
              };
            } else {
              var init$31 = state.currentFilter;
              newState$4 = {
                teams: init$31.teams,
                client: init$31.client,
                workers: init$31.workers,
                statuses: init$31.statuses,
                templates: init$31.templates,
                resolutions: toggle(state.currentFilter.resolutions, resolution, add$1),
                canceledResolutions: init$31.canceledResolutions,
                territories: init$31.territories,
                finishedAt: init$31.finishedAt,
                scheduledAt: init$31.scheduledAt,
                createdAt: init$31.createdAt,
                suspendedAt: init$31.suspendedAt,
                resumedAt: init$31.resumedAt,
                predefinedFields: init$31.predefinedFields,
                customFields: init$31.customFields,
                reportFields: init$31.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState$4));
        case "FilterByTemplate" :
            var init$32 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$32.teams,
                            client: init$32.client,
                            workers: init$32.workers,
                            statuses: init$32.statuses,
                            templates: toggle(state.currentFilter.templates, action._0, action._1),
                            resolutions: init$32.resolutions,
                            canceledResolutions: init$32.canceledResolutions,
                            territories: init$32.territories,
                            finishedAt: init$32.finishedAt,
                            scheduledAt: init$32.scheduledAt,
                            createdAt: init$32.createdAt,
                            suspendedAt: init$32.suspendedAt,
                            resumedAt: init$32.resumedAt,
                            predefinedFields: init$32.predefinedFields,
                            customFields: init$32.customFields,
                            reportFields: init$32.reportFields
                          }));
        case "FilterByScheduledAt" :
            var init$33 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$33.teams,
                            client: init$33.client,
                            workers: init$33.workers,
                            statuses: init$33.statuses,
                            templates: init$33.templates,
                            resolutions: init$33.resolutions,
                            canceledResolutions: init$33.canceledResolutions,
                            territories: init$33.territories,
                            finishedAt: init$33.finishedAt,
                            scheduledAt: action._0,
                            createdAt: init$33.createdAt,
                            suspendedAt: init$33.suspendedAt,
                            resumedAt: init$33.resumedAt,
                            predefinedFields: init$33.predefinedFields,
                            customFields: init$33.customFields,
                            reportFields: init$33.reportFields
                          }));
        case "FilterByFinishedAt" :
            var init$34 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$34.teams,
                            client: init$34.client,
                            workers: init$34.workers,
                            statuses: init$34.statuses,
                            templates: init$34.templates,
                            resolutions: init$34.resolutions,
                            canceledResolutions: init$34.canceledResolutions,
                            territories: init$34.territories,
                            finishedAt: action._0,
                            scheduledAt: init$34.scheduledAt,
                            createdAt: init$34.createdAt,
                            suspendedAt: init$34.suspendedAt,
                            resumedAt: init$34.resumedAt,
                            predefinedFields: init$34.predefinedFields,
                            customFields: init$34.customFields,
                            reportFields: init$34.reportFields
                          }));
        case "FilterByTerritory" :
            var init$35 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$35.teams,
                            client: init$35.client,
                            workers: init$35.workers,
                            statuses: init$35.statuses,
                            templates: init$35.templates,
                            resolutions: init$35.resolutions,
                            canceledResolutions: init$35.canceledResolutions,
                            territories: toggle(state.currentFilter.territories, action._0, action._1),
                            finishedAt: init$35.finishedAt,
                            scheduledAt: init$35.scheduledAt,
                            createdAt: init$35.createdAt,
                            suspendedAt: init$35.suspendedAt,
                            resumedAt: init$35.resumedAt,
                            predefinedFields: init$35.predefinedFields,
                            customFields: init$35.customFields,
                            reportFields: init$35.reportFields
                          }));
        case "FilterByClient" :
            var init$36 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$36.teams,
                            client: action._0,
                            workers: init$36.workers,
                            statuses: init$36.statuses,
                            templates: init$36.templates,
                            resolutions: init$36.resolutions,
                            canceledResolutions: init$36.canceledResolutions,
                            territories: init$36.territories,
                            finishedAt: init$36.finishedAt,
                            scheduledAt: init$36.scheduledAt,
                            createdAt: init$36.createdAt,
                            suspendedAt: init$36.suspendedAt,
                            resumedAt: init$36.resumedAt,
                            predefinedFields: init$36.predefinedFields,
                            customFields: init$36.customFields,
                            reportFields: init$36.reportFields
                          }));
        case "FilterByCreatedAt" :
            var init$37 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$37.teams,
                            client: init$37.client,
                            workers: init$37.workers,
                            statuses: init$37.statuses,
                            templates: init$37.templates,
                            resolutions: init$37.resolutions,
                            canceledResolutions: init$37.canceledResolutions,
                            territories: init$37.territories,
                            finishedAt: init$37.finishedAt,
                            scheduledAt: init$37.scheduledAt,
                            createdAt: action._0,
                            suspendedAt: init$37.suspendedAt,
                            resumedAt: init$37.resumedAt,
                            predefinedFields: init$37.predefinedFields,
                            customFields: init$37.customFields,
                            reportFields: init$37.reportFields
                          }));
        case "FilterBySuspendedAt" :
            var init$38 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$38.teams,
                            client: init$38.client,
                            workers: init$38.workers,
                            statuses: init$38.statuses,
                            templates: init$38.templates,
                            resolutions: init$38.resolutions,
                            canceledResolutions: init$38.canceledResolutions,
                            territories: init$38.territories,
                            finishedAt: init$38.finishedAt,
                            scheduledAt: init$38.scheduledAt,
                            createdAt: init$38.createdAt,
                            suspendedAt: action._0,
                            resumedAt: init$38.resumedAt,
                            predefinedFields: init$38.predefinedFields,
                            customFields: init$38.customFields,
                            reportFields: init$38.reportFields
                          }));
        case "FilterByResumedAt" :
            var init$39 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$39.teams,
                            client: init$39.client,
                            workers: init$39.workers,
                            statuses: init$39.statuses,
                            templates: init$39.templates,
                            resolutions: init$39.resolutions,
                            canceledResolutions: init$39.canceledResolutions,
                            territories: init$39.territories,
                            finishedAt: init$39.finishedAt,
                            scheduledAt: init$39.scheduledAt,
                            createdAt: init$39.createdAt,
                            suspendedAt: init$39.suspendedAt,
                            resumedAt: action._0,
                            predefinedFields: init$39.predefinedFields,
                            customFields: init$39.customFields,
                            reportFields: init$39.reportFields
                          }));
        case "FilterByBounds" :
            var bounds = action._0;
            return filterRequest(undefined, wire, {
                        data: state.data,
                        currentFilter: state.currentFilter,
                        activeFilters: state.activeFilters,
                        views: state.views,
                        activeView: state.activeView,
                        currentColumns: state.currentColumns,
                        jobs: state.jobs,
                        loadingMore: true,
                        jobsRefreshing: state.jobsRefreshing,
                        count: state.count,
                        dictionaries: state.dictionaries,
                        bounds: bounds,
                        pickingForBulk: state.pickingForBulk,
                        operationSet: state.operationSet
                      }, bounds, (function (jobs, count) {
                          return {
                                  TAG: "LoadMoreSuccess",
                                  _0: jobs,
                                  _1: count
                                };
                        }));
        case "FilterByJobField" :
            var value = action._1;
            var init$40 = state.currentFilter;
            var nextState = modifyCurrentFilter(state, {
                  teams: init$40.teams,
                  client: init$40.client,
                  workers: init$40.workers,
                  statuses: init$40.statuses,
                  templates: init$40.templates,
                  resolutions: init$40.resolutions,
                  canceledResolutions: init$40.canceledResolutions,
                  territories: init$40.territories,
                  finishedAt: init$40.finishedAt,
                  scheduledAt: init$40.scheduledAt,
                  createdAt: init$40.createdAt,
                  suspendedAt: init$40.suspendedAt,
                  resumedAt: init$40.resumedAt,
                  predefinedFields: addPredefinedField(state.currentFilter.predefinedFields, action._0, value),
                  customFields: init$40.customFields,
                  reportFields: init$40.reportFields
                });
            if (shouldRefreshJobList(value)) {
              return updateJobsList(wire, nextState);
            } else {
              return {
                      TAG: "Update",
                      _0: nextState
                    };
            }
        case "FilterByCustomField" :
            var value$1 = action._1;
            var init$41 = state.currentFilter;
            var nextState$1 = modifyCurrentFilter(state, {
                  teams: init$41.teams,
                  client: init$41.client,
                  workers: init$41.workers,
                  statuses: init$41.statuses,
                  templates: init$41.templates,
                  resolutions: init$41.resolutions,
                  canceledResolutions: init$41.canceledResolutions,
                  territories: init$41.territories,
                  finishedAt: init$41.finishedAt,
                  scheduledAt: init$41.scheduledAt,
                  createdAt: init$41.createdAt,
                  suspendedAt: init$41.suspendedAt,
                  resumedAt: init$41.resumedAt,
                  predefinedFields: init$41.predefinedFields,
                  customFields: addCustomField(state.currentFilter.customFields, action._0, value$1),
                  reportFields: init$41.reportFields
                });
            if (shouldRefreshJobList(value$1)) {
              return updateJobsList(wire, nextState$1);
            } else {
              return {
                      TAG: "Update",
                      _0: nextState$1
                    };
            }
        case "FilterByReportField" :
            var value$2 = action._1;
            var init$42 = state.currentFilter;
            var nextState$2 = modifyCurrentFilter(state, {
                  teams: init$42.teams,
                  client: init$42.client,
                  workers: init$42.workers,
                  statuses: init$42.statuses,
                  templates: init$42.templates,
                  resolutions: init$42.resolutions,
                  canceledResolutions: init$42.canceledResolutions,
                  territories: init$42.territories,
                  finishedAt: init$42.finishedAt,
                  scheduledAt: init$42.scheduledAt,
                  createdAt: init$42.createdAt,
                  suspendedAt: init$42.suspendedAt,
                  resumedAt: init$42.resumedAt,
                  predefinedFields: init$42.predefinedFields,
                  customFields: init$42.customFields,
                  reportFields: addCustomField(state.currentFilter.reportFields, action._0, value$2)
                });
            if (shouldRefreshJobList(value$2)) {
              return updateJobsList(wire, nextState$2);
            } else {
              return {
                      TAG: "Update",
                      _0: nextState$2
                    };
            }
        case "ResetResolutions" :
            var resolutionType = action._1;
            var newState$5;
            if (action._0 === "Canceled") {
              var init$43 = state.currentFilter;
              newState$5 = {
                teams: init$43.teams,
                client: init$43.client,
                workers: init$43.workers,
                statuses: init$43.statuses,
                templates: init$43.templates,
                resolutions: init$43.resolutions,
                canceledResolutions: RCore.$$Array.keep(state.currentFilter.canceledResolutions, (function (r) {
                        return !state.data.resolutions.some(function (d) {
                                    return Uuid.equal(d.uuid, r);
                                  });
                      })),
                territories: init$43.territories,
                finishedAt: init$43.finishedAt,
                scheduledAt: init$43.scheduledAt,
                createdAt: init$43.createdAt,
                suspendedAt: init$43.suspendedAt,
                resumedAt: init$43.resumedAt,
                predefinedFields: init$43.predefinedFields,
                customFields: init$43.customFields,
                reportFields: init$43.reportFields
              };
            } else {
              var init$44 = state.currentFilter;
              newState$5 = {
                teams: init$44.teams,
                client: init$44.client,
                workers: init$44.workers,
                statuses: init$44.statuses,
                templates: init$44.templates,
                resolutions: RCore.$$Array.keep(state.currentFilter.resolutions, (function (r) {
                        return !state.data.resolutions.some(function (d) {
                                    if (Uuid.equal(d.uuid, r)) {
                                      return d.type_ === resolutionType;
                                    } else {
                                      return false;
                                    }
                                  });
                      })),
                canceledResolutions: init$44.canceledResolutions,
                territories: init$44.territories,
                finishedAt: init$44.finishedAt,
                scheduledAt: init$44.scheduledAt,
                createdAt: init$44.createdAt,
                suspendedAt: init$44.suspendedAt,
                resumedAt: init$44.resumedAt,
                predefinedFields: init$44.predefinedFields,
                customFields: init$44.customFields,
                reportFields: init$44.reportFields
              };
            }
            return updateJobsList(wire, modifyCurrentFilter(state, newState$5));
        case "ResetPredefinedField" :
            var field = action._0;
            var init$45 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$45.teams,
                            client: init$45.client,
                            workers: init$45.workers,
                            statuses: init$45.statuses,
                            templates: init$45.templates,
                            resolutions: init$45.resolutions,
                            canceledResolutions: init$45.canceledResolutions,
                            territories: init$45.territories,
                            finishedAt: init$45.finishedAt,
                            scheduledAt: init$45.scheduledAt,
                            createdAt: init$45.createdAt,
                            suspendedAt: init$45.suspendedAt,
                            resumedAt: init$45.resumedAt,
                            predefinedFields: RCore.$$Array.keep(state.currentFilter.predefinedFields, (function (c) {
                                    return field !== c.key;
                                  })),
                            customFields: init$45.customFields,
                            reportFields: init$45.reportFields
                          }));
        case "ResetCustomField" :
            var uuid = action._0;
            var init$46 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$46.teams,
                            client: init$46.client,
                            workers: init$46.workers,
                            statuses: init$46.statuses,
                            templates: init$46.templates,
                            resolutions: init$46.resolutions,
                            canceledResolutions: init$46.canceledResolutions,
                            territories: init$46.territories,
                            finishedAt: init$46.finishedAt,
                            scheduledAt: init$46.scheduledAt,
                            createdAt: init$46.createdAt,
                            suspendedAt: init$46.suspendedAt,
                            resumedAt: init$46.resumedAt,
                            predefinedFields: init$46.predefinedFields,
                            customFields: RCore.$$Array.keep(state.currentFilter.customFields, (function (c) {
                                    return !Uuid.equal(uuid, c.typeUuid);
                                  })),
                            reportFields: init$46.reportFields
                          }));
        case "ResetReportField" :
            var uuid$1 = action._0;
            var init$47 = state.currentFilter;
            return updateJobsList(wire, modifyCurrentFilter(state, {
                            teams: init$47.teams,
                            client: init$47.client,
                            workers: init$47.workers,
                            statuses: init$47.statuses,
                            templates: init$47.templates,
                            resolutions: init$47.resolutions,
                            canceledResolutions: init$47.canceledResolutions,
                            territories: init$47.territories,
                            finishedAt: init$47.finishedAt,
                            scheduledAt: init$47.scheduledAt,
                            createdAt: init$47.createdAt,
                            suspendedAt: init$47.suspendedAt,
                            resumedAt: init$47.resumedAt,
                            predefinedFields: init$47.predefinedFields,
                            customFields: init$47.customFields,
                            reportFields: RCore.$$Array.keep(state.currentFilter.reportFields, (function (c) {
                                    return !Uuid.equal(uuid$1, c.typeUuid);
                                  }))
                          }));
        case "ToggleFilter" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: toggle(state.activeFilters, action._0, action._1),
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "SaveViewsSuccess" :
            var onFinish = action._2;
            var nextActiveView = action._1;
            var views$1 = action._0;
            var view$2 = RCore.$$Array.getBy(views$1, (function (param) {
                    return Caml_obj.equal(nextActiveView, Caml_option.some(param.uuid));
                  }));
            if (view$2 !== undefined) {
              return {
                      TAG: "UpdateWithSideEffect",
                      _0: {
                        data: state.data,
                        currentFilter: view$2.settings.filter,
                        activeFilters: state.activeFilters,
                        views: Belt_Lib_SortArray.stableSortBy(views$1, (function (a, b) {
                                return a.orderNo - b.orderNo | 0;
                              })),
                        activeView: nextActiveView,
                        currentColumns: view$2.settings.columns,
                        jobs: state.jobs,
                        loadingMore: state.loadingMore,
                        jobsRefreshing: state.jobsRefreshing,
                        count: state.count,
                        dictionaries: state.dictionaries,
                        bounds: state.bounds,
                        pickingForBulk: state.pickingForBulk,
                        operationSet: state.operationSet
                      },
                      _1: (function (_ctx) {
                          onFinish();
                        })
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffect",
                      _0: {
                        data: state.data,
                        currentFilter: state.currentFilter,
                        activeFilters: state.activeFilters,
                        views: Belt_Lib_SortArray.stableSortBy(views$1, (function (a, b) {
                                return a.orderNo - b.orderNo | 0;
                              })),
                        activeView: undefined,
                        currentColumns: state.currentColumns,
                        jobs: state.jobs,
                        loadingMore: state.loadingMore,
                        jobsRefreshing: state.jobsRefreshing,
                        count: state.count,
                        dictionaries: state.dictionaries,
                        bounds: state.bounds,
                        pickingForBulk: state.pickingForBulk,
                        operationSet: state.operationSet
                      },
                      _1: (function (_ctx) {
                          onFinish();
                        })
                    };
            }
        case "SaveFiltersSuccess" :
            var onFinish$1 = action._1;
            var init$48 = state.data;
            return {
                    TAG: "UpdateWithSideEffect",
                    _0: {
                      data: {
                        teams: init$48.teams,
                        workers: init$48.workers,
                        types: init$48.types,
                        templates: init$48.templates,
                        resolutions: init$48.resolutions,
                        territories: init$48.territories,
                        reportFields: init$48.reportFields,
                        customFields: init$48.customFields,
                        filters: action._0
                      },
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    },
                    _1: (function (_ctx) {
                        onFinish$1();
                      })
                  };
        case "RefreshJobsWithLog" :
            Utils.scrollToCoords({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
            return filterRequest(Belt_Array.concatMany([
                            Backend_Headers.contentJson,
                            [[
                                "X_PLANADO_SUBMITTED_JOB_UUID",
                                Uuid.toString(action._0)
                              ]]
                          ]), wire, {
                        data: state.data,
                        currentFilter: state.currentFilter,
                        activeFilters: state.activeFilters,
                        views: state.views,
                        activeView: state.activeView,
                        currentColumns: state.currentColumns,
                        jobs: state.jobs,
                        loadingMore: state.loadingMore,
                        jobsRefreshing: true,
                        count: state.count,
                        dictionaries: state.dictionaries,
                        bounds: state.bounds,
                        pickingForBulk: state.pickingForBulk,
                        operationSet: state.operationSet
                      }, defaultBounds, (function (jobs, count) {
                          return {
                                  TAG: "UpdateJobsListSuccess",
                                  _0: jobs,
                                  _1: count
                                };
                        }));
        case "UpdateJobsListSuccess" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: action._0,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: false,
                      count: action._1,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "RefreshJobsError" :
            console.log(action._0);
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: false,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "SaveViewsError" :
        case "SaveBackgroundTasksError" :
            console.log(action._0);
            return "NoUpdate";
        case "SaveFiltersError" :
            console.log(action._0);
            var init$49 = state.data;
            return {
                    TAG: "Update",
                    _0: {
                      data: {
                        teams: init$49.teams,
                        workers: init$49.workers,
                        types: init$49.types,
                        templates: init$49.templates,
                        resolutions: init$49.resolutions,
                        territories: init$49.territories,
                        reportFields: init$49.reportFields,
                        customFields: init$49.customFields,
                        filters: undefined
                      },
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "LoadMoreSuccess" :
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: Belt_Array.concatMany([
                            state.jobs,
                            action._0
                          ]),
                      loadingMore: false,
                      jobsRefreshing: state.jobsRefreshing,
                      count: action._1,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: state.operationSet
                    }
                  };
        case "PickUuidForOperation" :
            var uuid$2 = action._0;
            var match = state.operationSet;
            var operationSet;
            if (match !== undefined) {
              if (match.TAG === "Uuids") {
                var uuids = match._0;
                operationSet = {
                  TAG: "Uuids",
                  _0: toggle(uuids, uuid$2, !uuids.some(function (u) {
                            return Uuid.equal(uuid$2, u);
                          }))
                };
              } else {
                var uuids$1 = match._0;
                operationSet = {
                  TAG: "CurrentFilter",
                  _0: toggle(uuids$1, uuid$2, !uuids$1.some(function (u) {
                            return Uuid.equal(uuid$2, u);
                          }))
                };
              }
            } else {
              operationSet = {
                TAG: "Uuids",
                _0: [uuid$2]
              };
            }
            return {
                    TAG: "Update",
                    _0: {
                      data: state.data,
                      currentFilter: state.currentFilter,
                      activeFilters: state.activeFilters,
                      views: state.views,
                      activeView: state.activeView,
                      currentColumns: state.currentColumns,
                      jobs: state.jobs,
                      loadingMore: state.loadingMore,
                      jobsRefreshing: state.jobsRefreshing,
                      count: state.count,
                      dictionaries: state.dictionaries,
                      bounds: state.bounds,
                      pickingForBulk: state.pickingForBulk,
                      operationSet: operationSet
                    }
                  };
        
      }
    }
  };
}

export {
  emptyFilter ,
  emptyData ,
  defaultBounds ,
  initialState ,
  reducer ,
}
/* filteringUnavaliable' Not a pure module */
