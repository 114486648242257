

import * as RCore from "../libraries/RCore.mjs";
import * as Cookies from "../libraries/Cookies.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as JsonDecode from "../libraries/JsonDecode.mjs";
import * as ModalQuery from "./ModalQuery.mjs";

var WebsocketParams = {};

function make(input) {
  var match = input === "yandexMapsRequest" ? [
      "yandex_maps_requests",
      1,
      undefined
    ] : (
      input === "googleMapsRequest" ? [
          "google_maps_requests",
          1,
          undefined
        ] : (
          input === "googleAutocompleteRequest" ? [
              "google_autocomplete_requests",
              1,
              undefined
            ] : (
              input === "dadataRequest" ? [
                  "dadata_requests",
                  1,
                  undefined
                ] : [
                  "google_places_requests",
                  1,
                  undefined
                ]
            )
        )
    );
  return {
          resourceType: match[0],
          diff: match[1],
          unit: match[2]
        };
}

function mergeDiffs(baseRes, addedDiff) {
  return {
          resourceType: baseRes.resourceType,
          diff: baseRes.diff + addedDiff | 0,
          unit: baseRes.unit
        };
}

var Resource = {
  make: make,
  mergeDiffs: mergeDiffs
};

var UsedResources = {
  Resource: Resource
};

var decode = JsonDecode.object(function (field) {
      return {
              isSystem: field.required("is_system", JsonDecode.bool),
              exp: field.required("exp", JsonDecode.$$int)
            };
    });

var TokenPayload = {
  decode: decode
};

function ctx(wire) {
  return wire.ctx;
}

function subscriptions(wire) {
  return wire.subscriptions;
}

function footer(wire) {
  return wire.footer;
}

function businessHours(wire) {
  return wire.ctx.businessHours;
}

function assignees(wire) {
  return wire.ctx.assignees;
}

function closeModal(wire) {
  wire.navigate(RCore.$$Option.getOr(Core__List.tail(wire.route.modals), /* [] */0), wire.route.path);
}

function replaceModal(wire, modal) {
  var match = wire.route.modals;
  if (match) {
    return wire.navigate({
                hd: modal,
                tl: match.tl
              }, wire.route.path);
  } else {
    return wire.openModal(modal);
  }
}

function hasRightsToEditJobs(wire) {
  return RCore.$$Option.mapWithDefault(wire.ctx.permissions, false, (function (param) {
                var jobs = param.jobs;
                if (jobs === "ViewEdit") {
                  return true;
                } else {
                  return jobs === "ViewEditDelete";
                }
              }));
}

function hasTag(wire, tag) {
  return wire.ctx.tags.some(function (t) {
              return t === tag;
            });
}

function openJob(jobResponse, showLinkedJobs, wire, uuid) {
  var options = ModalQuery.Job.EditOptions.make(jobResponse, showLinkedJobs, uuid);
  var modal_1 = {
    NAME: "Edit",
    VAL: options
  };
  var modal = {
    NAME: "Job",
    VAL: modal_1
  };
  var match = Core__List.head(wire.route.modals);
  if (match !== undefined && typeof match === "object" && match.NAME === "Job") {
    return replaceModal(wire, modal);
  } else {
    return wire.openModal(modal);
  }
}

function refreshToken(wire) {
  wire.token.contents = Cookies.get("_planado_token");
}

function isSystem(wire) {
  return RCore.$$Option.getOr(RCore.$$Option.map(wire.tokenPayload.contents, (function (param) {
                    return param.isSystem === true;
                  })), false);
}

export {
  WebsocketParams ,
  UsedResources ,
  TokenPayload ,
  ctx ,
  subscriptions ,
  footer ,
  businessHours ,
  assignees ,
  closeModal ,
  replaceModal ,
  hasRightsToEditJobs ,
  hasTag ,
  openJob ,
  refreshToken ,
  isSystem ,
}
/* decode Not a pure module */
