

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var FilterEntity = {
  decode: decode
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              templates: field.required("templates", JsonDecode.array(decode)),
              resolutions: field.required("resolutions", JsonDecode.array(decode)),
              types: field.required("types", JsonDecode.array(decode))
            };
    });

function index(wire) {
  return Backend.decode(Backend.get(undefined, undefined, undefined, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Reports",
                    VAL: "Jobs"
                  }
                }), decode$1);
}

var Jobs = {
  decode: decode$1,
  index: index
};

function buildReportsUrl(ctx, url) {
  var match = ctx.localizator.lang;
  return url + (
          match === "en" ? "?lang=en" : (
              match === "es" ? "?lang=es" : (
                  match === "ru" ? "?lang=ru" : ""
                )
            )
        );
}

export {
  FilterEntity ,
  Jobs ,
  buildReportsUrl ,
}
/* decode Not a pure module */
