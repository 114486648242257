

import * as Icon from "../../../../../Icon.mjs";
import * as Uuid from "../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Optional from "../../../../common/Optional.mjs";
import * as FormInput from "../../../../inputs/FormInput.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JobViewTypes from "../JobViewTypes.mjs";
import * as UI_SortableList from "../../../../../ui/UI_SortableList.mjs";
import * as SettingsListModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/views/settings/SettingsList.module.css";

var styles = SettingsListModuleCss;

function getId(view) {
  return view.uuid;
}

var SortableList = UI_SortableList.Make({
      getId: getId
    });

function SettingsList$Item(Props) {
  var view = Props.view;
  var changeNameHandler = Props.changeNameHandler;
  var deleteHandler = Props.deleteHandler;
  return React.createElement(Optional.make, {
              show: !view.destroy,
              children: view.system ? React.createElement("div", {
                      className: "JobsViews-settings__item"
                    }, React.createElement("div", {
                          className: "JobsViews-settings__item-name"
                        }, React.createElement(FormInput.make, {
                              _type: "text",
                              value: view.name,
                              onValueChange: (function (prim) {
                                  
                                }),
                              disabled: true,
                              wrapperClass: Js_dict.fromList({
                                    hd: [
                                      "JobsViews-settings__item-input",
                                      true
                                    ],
                                    tl: {
                                      hd: [
                                        "pd-form-group-sm",
                                        true
                                      ],
                                      tl: /* [] */0
                                    }
                                  })
                            }))) : React.createElement(SortableList.Item.make, {
                      hasActivator: true,
                      id: view.uuid,
                      children: React.createElement("div", {
                            className: "JobsViews-settings__item"
                          }, React.createElement(SortableList.Activator.make, {
                                id: view.uuid,
                                children: React.createElement("div", {
                                      className: "JobsViews-settings__item-drag"
                                    })
                              }), React.createElement("div", {
                                className: "JobsViews-settings__item-name"
                              }, React.createElement(FormInput.make, {
                                    _type: "text",
                                    value: view.name,
                                    onValueChange: (function (v) {
                                        changeNameHandler(view.uuid, v);
                                      }),
                                    maxLength: JobViewTypes.maxViewNameLength,
                                    wrapperClass: Js_dict.fromList({
                                          hd: [
                                            "JobsViews-settings__item-input",
                                            true
                                          ],
                                          tl: {
                                            hd: [
                                              "pd-form-group-sm",
                                              true
                                            ],
                                            tl: /* [] */0
                                          }
                                        })
                                  })), React.createElement("div", {
                                className: "JobsViews-settings__item-delete",
                                onClick: (function (param) {
                                    deleteHandler(view.uuid);
                                  })
                              }, React.createElement("span", {
                                    className: Icon.style(undefined, undefined, undefined, "trash-alt")
                                  })))
                    })
            });
}

function SettingsList(Props) {
  var views = Props.views;
  var changeNameHandler = Props.changeNameHandler;
  var deleteHandler = Props.deleteHandler;
  var onDragEnd = Props.onDragEnd;
  var match = RCore.$$Array.partition(views, (function (v) {
          return v.system;
        }));
  var items = Belt_Array.concatMany([
        match[0],
        match[1]
      ]);
  return React.createElement("div", {
              className: styles.list
            }, React.createElement(SortableList.make, {
                  items: items,
                  onDragEnd: onDragEnd,
                  children: items.map(function (view) {
                        return React.createElement(SettingsList$Item, {
                                    view: view,
                                    changeNameHandler: changeNameHandler,
                                    deleteHandler: deleteHandler,
                                    key: Uuid.toString(view.uuid)
                                  });
                      })
                }));
}

var make = SettingsList;

export {
  make ,
}
/* styles Not a pure module */
