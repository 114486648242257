

import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_Reset from "../../common/JobIndexFilter_Reset.mjs";
import * as JobIndexFilter_Wrapper from "../../common/JobIndexFilter_Wrapper.mjs";
import * as JobIndexFilter_Checkbox from "../../common/JobIndexFilter_Checkbox.mjs";

var label$p = Utils.Translations.t("js.jobs.index.filters.assignees");

var noAssignee$p = Utils.Translations.tr("js.jobs.index.empty.assignee");

var emptySearchAssignee$p = Utils.Translations.tr("js.jobs.index.empty.search_assignee");

var teams$p = Utils.Translations.tr("js.jobs.index.filters.teams");

var workers$p = Utils.Translations.tr("js.jobs.index.filters.workers");

var selected$p = Utils.Translations.txr("js.jobs.index.selected");

function JobIndexFilter_Assignees(Props) {
  var currentFilter = Props.currentFilter;
  var teams = Props.teams;
  var workers = Props.workers;
  var resetFilter = Props.resetFilter;
  var toggleFilter = Props.toggleFilter;
  var filterByWorker = Props.filterByWorker;
  var filterByTeam = Props.filterByTeam;
  var filterDisabled = Props.filterDisabled;
  var show = Props.show;
  var match = React.useState(function () {
        
      });
  var setQuery = match[1];
  var query = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setSearchFocused = match$1[1];
  var searchFocused = match$1[0];
  var match$2 = AppContext.useWire();
  var ctx = match$2.ctx;
  var numberOfItems = teams.length + workers.length | 0;
  var numberOfSelectedFilters = currentFilter.teams.length + currentFilter.workers.length | 0;
  var showPreview = numberOfSelectedFilters > 0;
  var onSearchInputChange = function (evt) {
    var q = evt.target.value;
    setQuery(function (param) {
          return Fun.optString(q);
        });
  };
  var onResetClick = function (param) {
    resetFilter();
    toggleFilter({
            TAG: "System",
            _0: "Assignee"
          })(false);
  };
  var match$3 = query !== undefined ? [
      RCore.$$Array.keep(teams, (function (t) {
              return Fun.stringContains(query, t.name);
            })),
      RCore.$$Array.keep(workers, (function (w) {
              return Fun.stringContains(query, w.fullName);
            }))
    ] : [
      teams,
      workers
    ];
  var filteredWorkers = match$3[1];
  var filteredTeams = match$3[0];
  var searchBlock = function () {
    var classes = searchFocused || RCore.$$Option.isSome(query) ? "j-filter__search focused" : "j-filter__search";
    return React.createElement("div", {
                className: classes
              }, React.createElement(Optional.make, {
                    show: RCore.$$Option.isSome(query),
                    children: React.createElement("span", {
                          className: Icon.style(undefined, undefined, undefined, "times"),
                          onClick: (function (param) {
                              setQuery(function (param) {
                                    
                                  });
                            })
                        })
                  }), React.createElement("input", {
                    className: "j-filter__search-input",
                    value: RCore.$$Option.getOr(query, ""),
                    onFocus: (function (param) {
                        setSearchFocused(function (param) {
                              return true;
                            });
                      }),
                    onBlur: (function (param) {
                        setSearchFocused(function (param) {
                              return false;
                            });
                      }),
                    onChange: onSearchInputChange
                  }));
  };
  var teamsBlock = function () {
    return React.createElement(Optional.make, {
                show: filteredTeams.length > 0,
                children: null
              }, React.createElement("div", {
                    className: "j-filter__name_section"
                  }, teams$p(ctx)), filteredTeams.map(function (t) {
                    var checked = currentFilter.teams.some(function (f) {
                          return Uuid.equal(f, t.uuid);
                        });
                    return React.createElement(JobIndexFilter_Checkbox.make, {
                                name: t.name,
                                onToggle: (function (param) {
                                    filterByTeam(t.uuid, !checked);
                                  }),
                                checked: checked,
                                disabled: filterDisabled,
                                key: Uuid.toString(t.uuid)
                              });
                  }));
  };
  var workersBlock = function () {
    return React.createElement(Optional.make, {
                show: filteredWorkers.length > 0,
                children: React.createElement(React.Fragment, undefined, React.createElement("div", {
                          className: "j-filter__name_section"
                        }, workers$p(ctx)), filteredWorkers.map(function (w) {
                          var checked = currentFilter.workers.some(function (f) {
                                return Uuid.equal(f, w.uuid);
                              });
                          return React.createElement(JobIndexFilter_Checkbox.make, {
                                      name: w.name,
                                      onToggle: (function (param) {
                                          filterByWorker(w.uuid, !checked);
                                        }),
                                      checked: checked,
                                      disabled: filterDisabled,
                                      key: Uuid.toString(w.uuid)
                                    });
                        }))
              });
  };
  var renderSelected = function () {
    return React.createElement(Optional.make, {
                show: showPreview,
                children: React.createElement("div", {
                      className: "j-filter__selected"
                    }, selected$p({
                          count: numberOfSelectedFilters
                        }, ctx))
              });
  };
  return React.createElement(JobIndexFilter_Wrapper.make, {
              toggleFilter: toggleFilter({
                    TAG: "System",
                    _0: "Assignee"
                  }),
              show: show,
              label: label$p(ctx),
              children: numberOfItems > 0 ? React.createElement(React.Fragment, undefined, React.createElement(Optional.make, {
                          show: showPreview,
                          children: React.createElement(JobIndexFilter_Reset.make, {
                                onResetClick: onResetClick
                              })
                        }), React.createElement("div", {
                          className: "j-filter__list"
                        }, React.createElement(Optional.make, {
                              show: numberOfItems > 15,
                              children: searchBlock()
                            }), RCore.$$Option.isSome(query) && (filteredTeams.length + filteredWorkers.length | 0) === 0 ? React.createElement("div", {
                                className: "j-filter__list_empty"
                              }, emptySearchAssignee$p(ctx)) : React.createElement(React.Fragment, undefined, teamsBlock(), workersBlock())), renderSelected()) : React.createElement("div", {
                      className: "j-filter__list_empty"
                    }, noAssignee$p(ctx))
            });
}

var make = JobIndexFilter_Assignees;

export {
  make ,
}
/* label' Not a pure module */
