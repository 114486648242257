

import * as El from "../libraries/El.mjs";
import * as Icon from "../Icon.mjs";
import * as RCore from "../libraries/RCore.mjs";
import * as React from "react";

function UI_Icon(Props) {
  var onClick = Props.onClick;
  var size = Props.size;
  var spin = Props.spin;
  var className = Props.className;
  var icon = Props.icon;
  return React.createElement("i", {
              className: El.Cn.concatOpt(Icon.style(size, spin, undefined, icon), className),
              onClick: RCore.$$Option.getOr(onClick, (function (param) {
                      
                    }))
            });
}

var make = UI_Icon;

export {
  make ,
}
/* El Not a pure module */
