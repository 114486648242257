

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as React from "react";

function formatMoney$p(money) {
  return I18n.toNumber(money / 100.0, {
              precision: 2
            });
}

var rouble$p = React.createElement("i", {
      className: Icon.style("sm", undefined, undefined, "ruble-sign")
    });

function money$p(m) {
  var match = m.currency;
  return React.createElement("span", undefined, match === "usd" ? React.createElement(React.Fragment, undefined, "$", formatMoney$p(m.amount)) : React.createElement(React.Fragment, undefined, formatMoney$p(m.amount), El.nbsp, rouble$p));
}

var Format = {
  formatMoney$p: formatMoney$p,
  rouble$p: rouble$p,
  money$p: money$p
};

export {
  Format ,
}
/* rouble' Not a pure module */
