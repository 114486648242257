import React from 'react'
import { Select } from 'planado/components/input'
import Link from 'rscrpt/components/common/Link/Link.mjs'
import { territoriesPath, billingPath } from 'planado/routes.js'
import { withContext } from 'planado/utils/contextConsumer.jsx'

const territoriesToOptions = (territories) =>
  territories.map((t) => ({
    value: t.uuid,
    label: t.name,
  }))

const placeholderElement = (
  ctx,
  noAvailableTerritories,
  placeholderNoTerritories,
  placeholder
) => {
  if (noAvailableTerritories) {
    return placeholderNoTerritories
  } else if (ctx.features.flags.includes('territories')) {
    return placeholder
  } else if (ctx.owner) {
    return (
      <a href={billingPath} target="_blank">
        {ctx.t('js.feature_teaser.available_on_other_plans')}
      </a>
    )
  } else {
    return ctx.t('js.feature_teaser.available_on_other_plans')
  }
}

export const TerritoriesSelect = withContext(
  ({
    id,
    inputName,
    selectedTerritoryUuid,
    availableTerritories,
    onChange,
    placeholder,
    disabled,
    compactPlaceholder,
    ctx,
  }) => {
    const noAvailableTerritories = availableTerritories.length === 0

    let placeholderNoTerritories

    if (compactPlaceholder) {
      placeholderNoTerritories = ctx.t(
        'js.components.territories_select.compact_territories_not_created'
      )
    } else if (ctx.permissions.admin) {
      placeholderNoTerritories = (
        <p>
          {ctx.t(
            'js.components.territories_select.territories_not_created_add_them_in'
          )}
          <Link wire={ctx.wire} pathname={territoriesPath}>
            {ctx.t('js.components.territories_select.settings')}
          </Link>
        </p>
      )
    } else {
      placeholderNoTerritories = ctx.t(
        'js.components.territories_select.territories_not_created'
      )
    }

    return (
      <Select
        autosize={false}
        id={id}
        name={inputName}
        value={selectedTerritoryUuid}
        multi={false}
        options={territoriesToOptions(availableTerritories)}
        onChange={onChange}
        placeholder={placeholderElement(
          ctx,
          noAvailableTerritories,
          placeholderNoTerritories,
          placeholder
        )}
        disabled={disabled || noAvailableTerritories}
      />
    )
  }
)
