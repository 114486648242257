

import * as Icon from "../../../Icon.mjs";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Optional from "../../../components/common/Optional.mjs";
import * as FormInput from "../../../components/inputs/FormInput.mjs";
import * as Pages_Skills_SortableList from "../Pages_Skills_SortableList.mjs";
import * as Pages_Skills_Form_ItemModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/skills/form/Pages_Skills_Form_Item.module.css";

var styles = Pages_Skills_Form_ItemModuleCss;

function Pages_Skills_Form_Item(Props) {
  var skill = Props.skill;
  var updateSkill = Props.updateSkill;
  var deleteSkill = Props.deleteSkill;
  var locked = Props.locked;
  return React.createElement(Optional.make, {
              show: skill.visible,
              children: React.createElement(Pages_Skills_SortableList.Item.make, {
                    hasActivator: true,
                    id: skill.uuid,
                    children: React.createElement("div", {
                          className: styles.skill
                        }, React.createElement(Pages_Skills_SortableList.Activator.make, {
                              id: skill.uuid,
                              children: React.createElement("div", {
                                    className: styles.dragButton
                                  })
                            }), React.createElement(FormInput.make, {
                              _type: "text",
                              value: skill.name,
                              onValueChange: (function (name) {
                                  updateSkill({
                                        uuid: skill.uuid,
                                        name: name,
                                        visible: skill.visible,
                                        orderNo: skill.orderNo,
                                        errors: skill.errors,
                                        isNew: skill.isNew
                                      });
                                }),
                              onBlur: (function (_event) {
                                  updateSkill({
                                        uuid: skill.uuid,
                                        name: skill.name.trim(),
                                        visible: skill.visible,
                                        orderNo: skill.orderNo,
                                        errors: skill.errors,
                                        isNew: skill.isNew
                                      });
                                }),
                              maxLength: 200,
                              errors: skill.errors,
                              wrapperClass: Js_dict.fromArray([[
                                      "pd-form-group-full",
                                      true
                                    ]]),
                              autoFocus: skill.isNew
                            }), React.createElement("button", {
                              className: styles.deleteButton,
                              disabled: locked,
                              onClick: (function (evt) {
                                  evt.preventDefault();
                                  deleteSkill(skill);
                                })
                            }, React.createElement("span", {
                                  className: Icon.style(undefined, undefined, undefined, "trash-alt")
                                })))
                  })
            });
}

var make = Pages_Skills_Form_Item;

export {
  make ,
}
/* styles Not a pure module */
