

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Link from "../Link/Link.mjs";
import * as React from "react";
import * as ButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/Button.module.css";
import * as AddButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/AddButton/AddButton.module.css";

var styles = AddButtonModuleCss;

var buttonStyles = ButtonModuleCss;

function AddButton(Props) {
  var wire = Props.wire;
  var route = Props.route;
  var disabledOpt = Props.disabled;
  var children = Props.children;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var classess = disabled ? buttonStyles.btn + " " + buttonStyles.disabled : buttonStyles.btn;
  return React.createElement("div", {
              className: styles.add
            }, React.createElement(Link.make, {
                  wire: wire,
                  route: route,
                  disabled: disabled,
                  className: classess,
                  children: null
                }, React.createElement("i", {
                      className: El.Cn.concat(Icon.style(undefined, undefined, undefined, "plus"), styles.addLinkColor)
                    }), El.space, children));
}

var make = AddButton;

export {
  make ,
}
/* styles Not a pure module */
