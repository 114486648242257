

import * as RCore from "../../libraries/RCore.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Context_Types from "../../context/Context_Types.mjs";

var decode = JsonDecode.object(function (field) {
      return {
              code: field.required("code", JsonDecode.string),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$1 = JsonDecode.object(function (field) {
      return {
              password: RCore.$$Option.flatten(field.optional("password", JsonDecode.option(JsonDecode.array(JsonDecode.string)))),
              passwordConfirmation: RCore.$$Option.flatten(field.optional("passwordConfirmation", JsonDecode.option(JsonDecode.array(JsonDecode.string)))),
              termsAccepted: RCore.$$Option.flatten(field.optional("termsAccepted", JsonDecode.option(JsonDecode.array(JsonDecode.string)))),
              activation: RCore.$$Option.flatten(field.optional("activation", JsonDecode.option(JsonDecode.array(JsonDecode.string))))
            };
    });

var User = {
  decode: decode$1
};

var decode$2 = JsonDecode.object(function (field) {
      return {
              timezone: RCore.$$Option.flatten(field.optional("timezone", JsonDecode.option(JsonDecode.array(JsonDecode.string)))),
              country: RCore.$$Option.flatten(field.optional("country", JsonDecode.option(JsonDecode.array(JsonDecode.string)))),
              language: RCore.$$Option.flatten(field.optional("language", JsonDecode.option(JsonDecode.array(JsonDecode.string))))
            };
    });

var Tenant = {
  decode: decode$2
};

var decode$3 = JsonDecode.object(function (field) {
      return {
              user: RCore.$$Option.flatten(field.optional("user", JsonDecode.option(decode$1))),
              tenant: RCore.$$Option.flatten(field.optional("tenant", JsonDecode.option(decode$2)))
            };
    });

var Errors = {
  User: User,
  Tenant: Tenant,
  decode: decode$3
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              message: field.required("message", JsonDecode.string),
              errors: RCore.$$Option.flatten(field.optional("errors", JsonDecode.option(decode$3)))
            };
    });

var $$Response = {
  decode: decode$4
};

var Patch = {
  $$Response: $$Response
};

function country(countryVariants, preferredCountry, international) {
  var country$1 = RCore.$$Array.getBy(countryVariants, (function (c) {
          return c.code === RCore.$$Option.getOr(preferredCountry, "");
        }));
  if (country$1 !== undefined) {
    return country$1.code;
  } else if (international) {
    return "gb";
  } else {
    return "ru";
  }
}

function timezone(timezones, preferredTimezone) {
  var tz = RCore.$$Array.getBy(timezones, (function (t) {
          return t.code === preferredTimezone;
        }));
  if (tz !== undefined) {
    return tz.code;
  } else {
    return RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(timezones, (function (t) {
                          return Js_string.endsWith(t.name, "UTC+0");
                        })), (function (t) {
                      return t.code;
                    })), "Europe/London");
  }
}

function language(languages, preferredLanguage, international) {
  var l = RCore.$$Array.getBy(languages, (function (l) {
          return l.code === preferredLanguage;
        }));
  if (l !== undefined) {
    return l.code;
  } else if (international) {
    return "en";
  } else {
    return "ru";
  }
}

function decode$5(international) {
  return JsonDecode.object(function (field) {
              var timezoneVariants = field.required("timezoneVariants", JsonDecode.array(decode));
              var countryVariants = field.required("countryVariants", JsonDecode.array(decode));
              var languageVariants = field.required("languageVariants", JsonDecode.array(decode));
              return {
                      email: field.required("email", JsonDecode.string),
                      password: "",
                      passwordConfirmation: "",
                      isChecked: false,
                      activationCode: field.required("activationCode", JsonDecode.string),
                      timezone: timezone(timezoneVariants, field.required("timezone", JsonDecode.string)),
                      country: country(countryVariants, field.required("country", JsonDecode.option(JsonDecode.string)), international),
                      language: language(languageVariants, field.required("language", JsonDecode.string), international),
                      timezoneVariants: timezoneVariants,
                      countryVariants: countryVariants,
                      languageVariants: languageVariants,
                      branding: RCore.$$Option.flatten(field.optional("branding", JsonDecode.option(Context_Types.Branding.decode)))
                    };
            });
}

function Variant_idToString(prim) {
  return prim;
}

function Variant_stringToId(prim) {
  return prim;
}

var Variant = {
  idToString: Variant_idToString,
  stringToId: Variant_stringToId
};

var State = {
  decode: decode$5
};

export {
  Variant ,
  Errors ,
  Patch ,
  State ,
}
/* decode Not a pure module */
