

import * as El from "../../../../../../libraries/El.mjs";
import * as Fun from "../../../../../../libraries/Fun.mjs";
import * as Icon from "../../../../../../Icon.mjs";
import * as React from "react";
import * as Optional from "../../../../../common/Optional.mjs";
import * as JobIndexFilter_SettingsPopover from "./JobIndexFilter_SettingsPopover.mjs";
import * as JobIndexFilter_SettingsButtonModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/index/job_index_filter/components/settings/JobIndexFilter_SettingsButton.module.css";

var styles = JobIndexFilter_SettingsButtonModuleCss;

function JobIndexFilter_SettingsButton(Props) {
  var data = Props.data;
  var initialPredefinedFields = Props.initialPredefinedFields;
  var initialCustomFields = Props.initialCustomFields;
  var initialReportFields = Props.initialReportFields;
  var createFilters = Props.createFilters;
  var updateFilters = Props.updateFilters;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("div", {
                  className: styles.rectangle
                }, React.createElement("div", {
                      className: El.Cn.concat(styles.cog, Icon.style(undefined, undefined, undefined, "cog")),
                      onClick: (function (param) {
                          Fun.defer(function () {
                                setShowModal(function (param) {
                                      return true;
                                    });
                              });
                        })
                    })), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement(JobIndexFilter_SettingsPopover.make, {
                        data: data,
                        initialPredefinedFields: initialPredefinedFields,
                        initialCustomFields: initialCustomFields,
                        initialReportFields: initialReportFields,
                        hidePopover: (function () {
                            setShowModal(function (param) {
                                  return false;
                                });
                          }),
                        createFilters: (function (jobFields, customFields, reportFields) {
                            createFilters(jobFields, customFields, reportFields, (function () {
                                    setShowModal(function (param) {
                                          return false;
                                        });
                                  }));
                          }),
                        updateFilters: (function (uuid) {
                            return function (jobFields, customFields, reportFields) {
                              updateFilters(uuid, jobFields, customFields, reportFields, (function () {
                                      setShowModal(function (param) {
                                            return false;
                                          });
                                    }));
                            };
                          })
                      })
                }));
}

var make = JobIndexFilter_SettingsButton;

export {
  make ,
}
/* styles Not a pure module */
