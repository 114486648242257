

import * as El from "../../libraries/El.mjs";
import * as Fun from "../../libraries/Fun.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Errors from "../../Errors.mjs";
import * as Router from "../../Router.mjs";
import * as Script from "../../Script.mjs";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as DomUtils from "../../DomUtils.mjs";
import * as LoginAPI from "./LoginAPI.mjs";
import * as Optional from "../common/Optional.mjs";
import * as PromiseF from "../../libraries/PromiseF.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Context_Types from "../../context/Context_Types.mjs";
import * as LoginModuleCss from "/home/runner/work/planado/planado/client/rescript/components/login/Login.module.css";

var styles = LoginModuleCss;

var email$p = Utils.Translations.t("js.login.email");

var password$p = Utils.Translations.t("js.login.password");

var submit$p = Utils.Translations.tr("js.login.submit");

var forgotPassword$p = Utils.Translations.tr("js.login.forgot_password");

var logIn$p = Utils.Translations.tr("js.login.log_in");

var captchaCallback = {
  contents: (function () {
      
    })
};

function onCaptchaSolve() {
  captchaCallback.contents();
}

function Login(Props) {
  var wire = Props.wire;
  var navigate = wire.navigate;
  var ctx = wire.ctx;
  var captchaNeeded = Context_Types.Features.hasFlag("captcha", ctx.features);
  var match = React.useState(function () {
        return "";
      });
  var setEmail = match[1];
  var email = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setErrors = match$2[1];
  var match$3 = Hooks.useLocked(true);
  var unlock = match$3[2];
  var lock = match$3[1];
  var locked = match$3[0];
  var emailFieldRef = React.useRef(null);
  var goToRecover = function ($$event) {
    $$event.preventDefault();
    navigate(undefined, "Recover");
  };
  var inputFocus = function () {
    unlock();
    var emailField = emailFieldRef.current;
    if (!(emailField == null)) {
      return Fun.defer(function () {
                  Utils.focus(emailField);
                });
    }
    
  };
  var logInResponse = function (data) {
    if (data.TAG === "Ok") {
      return Router.redirect(data._0.redirect);
    } else {
      var e = data._0;
      inputFocus();
      if (typeof e !== "object") {
        return ;
      }
      if (e.TAG !== "HTTPError") {
        return ;
      }
      var match = e._0;
      var tmp = match[0];
      if (typeof tmp === "object") {
        return ;
      }
      if (tmp !== "Unauthorized") {
        return ;
      }
      var text = match[1];
      var api = LoginAPI.Captcha.getCaptchaApi();
      if (api !== undefined && captchaNeeded) {
        LoginAPI.Captcha.reset(Caml_option.valFromOption(api));
      }
      return setErrors(function (param) {
                  return Errors.parse(text);
                });
    }
  };
  var submit = function (email, password) {
    lock();
    Backend.$$finally(LoginAPI.Post.create(wire, email, password), logInResponse);
  };
  var handleSubmit = function ($$event) {
    $$event.preventDefault();
    submit(email, password);
  };
  var buttonStyles = El.Cn.concat(El.Cn.concat(styles.button, locked ? styles.loader : ""), captchaNeeded ? "g-recaptcha" : "");
  var disabled = email === "" || password === "" || locked;
  React.useEffect((function () {
          inputFocus();
          Backend.$$finally((function (__x) {
                    return PromiseF.$$catch((function (error) {
                                  var message = "Login promise resolved, result: " + RCore.$$Option.getOr(error.message, "");
                                  console.log(message, error);
                                  Backend.log(message, wire);
                                }), __x);
                  })(LoginAPI.show(wire)), (function (result) {
                  console.log("Login promise resolved", result);
                }));
        }), []);
  React.useEffect((function () {
          if (captchaNeeded) {
            captchaCallback.contents = (function () {
                submit(email, password);
              });
          }
          
        }), [
        email,
        password
      ]);
  var submitButtonEl = React.createElement("button", {
        className: buttonStyles,
        disabled: disabled,
        type: "submit"
      }, React.createElement("span", undefined, submit$p(ctx)));
  var submitButton = captchaNeeded ? React.createElement(DomUtils.Spread.make, {
          props: {
            "data-sitekey": ctx.googleCaptchaSiteKey,
            "data-callback": "onCaptchaSolve",
            "data-size": "invisible"
          },
          children: submitButtonEl
        }) : submitButtonEl;
  var match$4 = ctx.branding;
  var tmp;
  var exit = 0;
  if (match$4 !== undefined) {
    var logo = match$4.logo;
    if (logo !== undefined) {
      tmp = React.createElement("img", {
            className: styles.whiteLabelLogo,
            src: logo
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: styles.logo
        });
  }
  var errors = Errors.getMany({
        hd: "email",
        tl: /* [] */0
      }, match$2[0]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: styles.wrapper
                }, React.createElement("div", {
                      className: styles.container
                    }, React.createElement("div", {
                          className: styles.logoContainer
                        }, tmp), React.createElement("div", {
                          className: styles.signinText
                        }, React.createElement("span", undefined, logIn$p(ctx))), errors !== undefined ? El.listi((function (msg, i) {
                              return React.createElement("div", {
                                          key: i.toString(),
                                          className: "alert alert-danger"
                                        }, msg);
                            }), errors) : null, React.createElement("form", {
                          className: styles.form,
                          onSubmit: handleSubmit
                        }, React.createElement("div", {
                              className: El.Cn.concat(styles.field, styles.fieldEmail)
                            }, React.createElement("input", {
                                  ref: emailFieldRef,
                                  className: styles.fieldInput,
                                  disabled: locked,
                                  placeholder: email$p(ctx),
                                  type: "text",
                                  value: email,
                                  onChange: (function (v) {
                                      var email = El.getStringValueFromEvent(v);
                                      setEmail(function (param) {
                                            return email;
                                          });
                                    })
                                })), React.createElement("div", {
                              className: El.Cn.concat(styles.field, styles.fieldPassword)
                            }, React.createElement("input", {
                                  className: styles.fieldInput,
                                  disabled: locked,
                                  placeholder: password$p(ctx),
                                  type: "password",
                                  value: password,
                                  onChange: (function (v) {
                                      var pswd = El.getStringValueFromEvent(v);
                                      setPassword(function (param) {
                                            return pswd;
                                          });
                                    })
                                })), React.createElement("div", {
                              className: styles.actions
                            }, submitButton, React.createElement("button", {
                                  className: styles.forgotPassword,
                                  onClick: goToRecover
                                }, forgotPassword$p(ctx)))))), React.createElement(Optional.make, {
                  show: captchaNeeded,
                  children: React.createElement(Script.make, {
                        src: "https://www.google.com/recaptcha/api.js",
                        root: wire.footer,
                        onload: (function (param) {
                            
                          })
                      })
                }));
}

var make = Login;

export {
  captchaCallback ,
  onCaptchaSolve ,
  make ,
}
/* styles Not a pure module */
