

import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as PromiseF from "../../../../libraries/PromiseF.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi2_File from "../../../../libraries/webapi/Webapi2_File.mjs";
import * as Webapi__Fetch from "rescript-webapi/lib/es6/src/Webapi/Webapi__Fetch.mjs";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Decode.mjs";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/lib/es6/src/Json_Encode.mjs";

function encode(file, checksum, mimeType) {
  var base = [
    [
      "uuid",
      Uuid.toString(file.uuid)
    ],
    [
      "name",
      file.name
    ],
    [
      "checksum",
      checksum
    ],
    [
      "mime_type",
      mimeType
    ]
  ];
  var size = file.size;
  var size$1 = size !== undefined ? [[
        "size",
        size
      ]] : [];
  var w = file.width;
  var dimensions;
  if (w !== undefined) {
    var h = file.height;
    dimensions = h !== undefined ? [
        [
          "width",
          w
        ],
        [
          "height",
          h
        ]
      ] : [];
  } else {
    dimensions = [];
  }
  return {
          NAME: "Obj",
          VAL: Utils.jsonToObj(Json_Encode$JsonCombinators.object(Belt_Array.concatMany([
                        base,
                        size$1,
                        dimensions
                      ])))
        };
}

var decode = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              remoteUrl: field.required("remoteUrl", JsonDecode.string),
              previewUrl: field.required("previewUrl", JsonDecode.option(JsonDecode.string)),
              uploadUrl: field.optional("uploadUrl", JsonDecode.string)
            };
    });

function presign(wire, file, checksum, mimeType) {
  return Backend.decode(Backend.post(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Files",
                    VAL: "Index"
                  }
                }, encode(file, checksum, mimeType)), decode);
}

function upload(uploadUrl, jsFile) {
  var body = Webapi2_File.makeBodyInit(jsFile);
  var headers = {
    "content-type": jsFile.type
  };
  return PromiseF.fromJs(fetch(uploadUrl, Webapi__Fetch.RequestInit.make("Put", Caml_option.some(headers), Caml_option.some(body), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
}

var Upload = {
  upload: upload
};

function setUploaded(wire, uuid) {
  return Backend.decode(Backend.patch(undefined, true, undefined, true, wire, {
                  NAME: "API",
                  VAL: {
                    NAME: "Files",
                    VAL: {
                      NAME: "Uploaded",
                      VAL: Uuid.toString(uuid)
                    }
                  }
                }, {
                  NAME: "Obj",
                  VAL: Utils.jsonToObj(null)
                }), Json_Decode$JsonCombinators.custom(function (_json) {
                  
                }));
}

var SetUploaded = {
  setUploaded: setUploaded
};

var Presign_Response = {};

var Presign = {
  $$Response: Presign_Response,
  presign: presign
};

export {
  Presign ,
  Upload ,
  SetUploaded ,
}
/* decode Not a pure module */
