

import * as Icon from "../../Icon.mjs";
import * as Hooks from "../../libraries/hooks/Hooks.mjs";
import * as Route from "../../types/Route.mjs";
import * as Title from "../common/Title.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as TeamAPI from "./TeamAPI.mjs";
import * as TeamForm from "./TeamForm.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.mjs";
import * as Backend_Errors from "../../libraries/backend/Backend_Errors.mjs";
import * as MessengerTypes from "../messenger/MessengerTypes.mjs";
import * as TeamEditFooter from "./TeamEditFooter.mjs";
import * as TeamModuleCss from "/home/runner/work/planado/planado/client/rescript/components/team/Team.module.css";

var editing$p = Utils.Translations.tr("js.teams.show.editing");

var newTeam$p = Utils.Translations.tr("js.teams.show.new_team");

var createdMessage$p = Utils.Translations.t("js.teams.messages.created");

var destroyedMessage$p = Utils.Translations.t("js.teams.messages.destroyed");

var updatedMessage$p = Utils.Translations.t("js.teams.messages.updated");

var styles = TeamModuleCss;

function TeamEdit$Wrapper(Props) {
  var isModal = Props.isModal;
  var children = Props.children;
  if (isModal) {
    return React.createElement("div", {
                id: "content-wrapper"
              }, React.createElement("div", {
                    className: styles.wrapper
                  }, children));
  } else {
    return React.createElement("div", {
                className: styles.wrapper
              }, children);
  }
}

function TeamEdit(Props) {
  var wire = Props.wire;
  var mode = Props.mode;
  var isModal = Props.isModal;
  var onDelete = Props.onDelete;
  var onClose = Props.onClose;
  var onCancel = Props.onCancel;
  var navigate = wire.navigate;
  var route = wire.route;
  var messenger = wire.subscriptions.messenger;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return {
                uuid: typeof mode === "object" ? Caml_option.some(mode.VAL) : undefined,
                name: "",
                foremanUuid: undefined,
                workerUuids: [],
                description: undefined,
                skillUuids: [],
                territoryUuid: undefined,
                temporaryWorkers: []
              };
      });
  var setTeam = match[1];
  var team = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPersistedTeamName = match$1[1];
  var persistedTeamName = match$1[0];
  var match$2 = Hooks.useLocked(false);
  var unlock = match$2[2];
  var lock = match$2[1];
  var match$3 = React.useState(function () {
        return Backend_Errors.empty;
      });
  var setErrors = match$3[1];
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowDestroyConfirmation = match$4[1];
  var showDestroyConfirmation = match$4[0];
  var match$5 = React.useState(function () {
        
      });
  var setAllowedChoices = match$5[1];
  var allowedChoices = match$5[0];
  var closeAndDisplayMessage = function (text) {
    messenger.trigger({
          TAG: "Show",
          _0: MessengerTypes.Message.make(undefined, undefined, {
                TAG: "Text",
                _0: text
              })
        });
    onClose();
  };
  var response = function (data) {
    if (data.TAG === "Ok") {
      var match = data._0;
      switch (match.TAG) {
        case "NewTeam" :
            var match$1 = match._0;
            var availableTerritories = match$1.availableTerritories;
            var allowedWorkers = match$1.allowedWorkers;
            var allowedForemen = match$1.allowedForemen;
            return setAllowedChoices(function (param) {
                        return {
                                allowedForemen: allowedForemen,
                                allowedWorkers: allowedWorkers,
                                availableTerritories: availableTerritories
                              };
                      });
        case "ShowTeam" :
            var match$2 = match._0;
            var availableTerritories$1 = match$2.availableTerritories;
            var allowedWorkers$1 = match$2.allowedWorkers;
            var allowedForemen$1 = match$2.allowedForemen;
            var team = match$2.team;
            setPersistedTeamName(function (param) {
                  return team.name;
                });
            setTeam(function (param) {
                  return team;
                });
            return setAllowedChoices(function (param) {
                        return {
                                allowedForemen: allowedForemen$1,
                                allowedWorkers: allowedWorkers$1,
                                availableTerritories: availableTerritories$1
                              };
                      });
        case "Created" :
            return closeAndDisplayMessage(createdMessage$p(ctx));
        case "Updated" :
            return closeAndDisplayMessage(updatedMessage$p(ctx));
        case "Destroyed" :
            return closeAndDisplayMessage(destroyedMessage$p(ctx));
        
      }
    } else {
      var e = data._0;
      console.log(e);
      if (typeof e !== "object") {
        console.error(e);
        return ;
      }
      switch (e.TAG) {
        case "HTTPError" :
            var tmp = e._0[0];
            if (typeof tmp !== "object") {
              if (tmp === "NotFound") {
                return navigate(undefined, "Unknown");
              }
              console.error(e);
              return ;
            } else {
              console.error(e);
              return ;
            }
        case "ValidationError" :
            var errors = e._0;
            unlock();
            return setErrors(function (param) {
                        return errors;
                      });
        default:
          console.error(e);
          return ;
      }
    }
  };
  var newResponse = function (v) {
    response(Core__Result.map(v, (function (t) {
                return {
                        TAG: "NewTeam",
                        _0: t
                      };
              })));
  };
  var showResponse = function (v) {
    response(Core__Result.map(v, (function (t) {
                return {
                        TAG: "ShowTeam",
                        _0: t
                      };
              })));
  };
  var createResponse = function (v) {
    response(Core__Result.map(v, (function (t) {
                return {
                        TAG: "Created",
                        _0: t
                      };
              })));
  };
  var updateResponse = function (v) {
    response(Core__Result.map(v, (function (t) {
                return {
                        TAG: "Updated",
                        _0: t
                      };
              })));
  };
  var destroyResponse = function (v) {
    response(Core__Result.map(v, (function (t) {
                return {
                        TAG: "Destroyed",
                        _0: t
                      };
              })));
  };
  var onUpdate = function (team) {
    setTeam(function (param) {
          return team;
        });
  };
  var submitAction = function () {
    lock();
    var uuid = team.uuid;
    if (uuid !== undefined) {
      Backend.$$finally(TeamAPI.Edit.update(wire, Caml_option.valFromOption(uuid), team), updateResponse);
    } else {
      Backend.$$finally(TeamAPI.Edit.create(wire, team), createResponse);
    }
    unlock();
    wire.reloadContext();
  };
  var submit = function ($$event) {
    $$event.preventDefault();
    submitAction();
  };
  var destroyTeam = function (uuid) {
    return function ($$event) {
      $$event.preventDefault();
      lock();
      Backend.$$finally(TeamAPI.Edit.destroy(wire, uuid), destroyResponse);
      onDelete(uuid);
    };
  };
  var confirmDestroy = function ($$event) {
    $$event.preventDefault();
    setShowDestroyConfirmation(function (param) {
          return !showDestroyConfirmation;
        });
  };
  var close = function ($$event) {
    $$event.preventDefault();
    if (onCancel !== undefined) {
      onCancel();
    }
    onClose();
  };
  var isNewRecord = typeof mode === "object" ? false : true;
  React.useEffect((function () {
          var uuid = team.uuid;
          if (uuid !== undefined) {
            Backend.$$finally(TeamAPI.Edit.show(wire, Caml_option.valFromOption(uuid)), showResponse);
          } else {
            Backend.$$finally(TeamAPI.Edit.new_(wire), newResponse);
          }
        }), []);
  return React.createElement(TeamEdit$Wrapper, {
              isModal: isModal,
              children: React.createElement("form", {
                    action: Route.pagePath(undefined, route.path),
                    method: "POST",
                    onSubmit: submit
                  }, allowedChoices !== undefined ? React.createElement("div", {
                          className: "form team-form"
                        }, React.createElement("header", {
                              className: "form-header"
                            }, React.createElement("h1", {
                                  className: "form-header__title"
                                }, isNewRecord ? React.createElement("span", undefined, newTeam$p(ctx)) : React.createElement(React.Fragment, undefined, React.createElement(Title.make, {
                                            route: route,
                                            ctx: ctx,
                                            values: {
                                              name: persistedTeamName
                                            },
                                            wire: wire
                                          }), React.createElement("span", undefined, persistedTeamName, React.createElement("span", {
                                                className: "text-light"
                                              }, editing$p(ctx))))), React.createElement("div", {
                                  className: "team-form-heading__right"
                                }, React.createElement(UI_Button.make, {
                                      flavor: "close",
                                      onClick: close,
                                      children: React.createElement("span", {
                                            className: Icon.style(undefined, undefined, undefined, "times")
                                          })
                                    }))), React.createElement("section", {
                              className: "form-section"
                            }, React.createElement(TeamForm.make, {
                                  team: team,
                                  mode: mode,
                                  errors: match$3[0],
                                  foremen: allowedChoices.allowedForemen,
                                  workers: allowedChoices.allowedWorkers,
                                  availableTerritories: allowedChoices.availableTerritories,
                                  onUpdate: onUpdate
                                })), React.createElement(TeamEditFooter.make, {
                              showDestroyConfirmation: showDestroyConfirmation,
                              confirmDestroy: confirmDestroy,
                              destroyTeam: destroyTeam,
                              toggleDestroyFooter: (function (param) {
                                  setShowDestroyConfirmation(function (param) {
                                        return !showDestroyConfirmation;
                                      });
                                }),
                              close: close,
                              ctx: ctx,
                              team: team,
                              locked: match$2[0]
                            })) : null)
            });
}

var make = TeamEdit;

export {
  make ,
}
/* editing' Not a pure module */
