

import * as Icon from "../../../../../../../../Icon.mjs";
import * as Uuid from "../../../../../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../../../../../libraries/RCore.mjs";
import * as Utils from "../../../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../../../../../common/Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Types_Spatial from "../../../../../../../../types/Types_Spatial.mjs";
import * as MapReComponent from "../../../../../../../common/mapRe/MapReComponent.mjs";
import * as JobForm_HistoryMovement from "./movement/JobForm_HistoryMovement.mjs";
import * as JobForm_HistoryMapMarker from "./marker/JobForm_HistoryMapMarker.mjs";
import * as JobForm_HistoryMapDateSelector from "./date_selector/JobForm_HistoryMapDateSelector.mjs";
import * as JobForm_HistoryMapModuleCss from "/home/runner/work/planado/planado/client/rescript/components/job/edit/form/components/history/components/map/JobForm_HistoryMap.module.css";

var noMovements$p = Utils.Translations.tr("js.jobs.history.map.no_movements");

var eventsCondition$p = Utils.Translations.tr("js.jobs.history.map.events_condition");

function meter$p(distance, c) {
  return Utils.Translations.tx("js.jobs.history.distance.meter")({
              distance: distance
            }, c);
}

function kilometer$p(distance, c) {
  return Utils.Translations.tx("js.jobs.history.distance.kilometer")({
              distance: distance
            }, c);
}

function foot$p(distance, c) {
  return Utils.Translations.tx("js.jobs.history.distance.foot")({
              distance: distance
            }, c);
}

function mile$p(distance, c) {
  return Utils.Translations.tx("js.jobs.history.distance.mile")({
              distance: distance
            }, c);
}

var styles = JobForm_HistoryMapModuleCss;

function getFirstAndLast(array) {
  return [
          array[0],
          array[array.length - 1 | 0]
        ];
}

function calculateInitialRegion(coords) {
  var len = coords.length;
  if (len !== 1) {
    if (len === 0) {
      return ;
    }
    var latitudes = Utils.sortFloatArray(coords.map(function (x) {
                return Types_Spatial.Latitude.toNumber(x.latitude);
              })).map(function (x) {
          return Types_Spatial.Latitude.fromNumber(x);
        });
    var longitudes = Utils.sortFloatArray(coords.map(function (x) {
                return Types_Spatial.Longitude.toNumber(x.longitude);
              })).map(function (x) {
          return Types_Spatial.Longitude.fromNumber(x);
        });
    var match = getFirstAndLast(latitudes);
    var match$1 = getFirstAndLast(longitudes);
    return {
            NAME: "BoundingBox",
            VAL: {
              southWest: {
                longitude: match$1[0],
                latitude: match[0]
              },
              northEast: {
                longitude: match$1[1],
                latitude: match[1]
              }
            }
          };
  }
  var coordinate = coords[0];
  return {
          NAME: "Center",
          VAL: coordinate
        };
}

function JobForm_HistoryMap$Map(Props) {
  var wire = Props.wire;
  var containerRef = Props.containerRef;
  var region = Props.region;
  var fallbackRegion = Props.fallbackRegion;
  var eventsGrouped = Props.eventsGrouped;
  var selectedDate = Props.selectedDate;
  var addressMarkerLabel = Props.addressMarkerLabel;
  var addressGeolocation = Props.addressGeolocation;
  var locations = Props.locations;
  var mapEventsSubscription = Props.mapEventsSubscription;
  var addressMarker = addressGeolocation !== undefined ? React.createElement(JobForm_HistoryMapMarker.AddressMarker.make, {
          text: addressMarkerLabel,
          wire: wire,
          coordinates: addressGeolocation
        }) : null;
  var dayEvents = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Array.getBy(eventsGrouped, (function (group) {
                  return Locale.T.equal(group.date, selectedDate);
                })), (function (group) {
              return group.events;
            })), []);
  var eventCoords = RCore.$$Array.filterMap(dayEvents, (function (e) {
          var match = e.value;
          var match$1 = e.deviceState;
          if (typeof match === "object" && match.NAME === "MovementFinished") {
            return ;
          }
          if (match$1 !== undefined) {
            return [
                    e.uuid,
                    match$1.geolocation
                  ];
          }
          
        }));
  var eventMarkers = eventCoords.map(function (param, idx) {
        var key = idx.toString() + Uuid.toString(param[0]);
        return React.createElement(JobForm_HistoryMapMarker.EventMarker.make, {
                    text: undefined,
                    wire: wire,
                    coordinates: param[1],
                    key: key
                  });
      });
  var onMouseEnterPolyline = Utils.debounce1((function (tuple) {
          mapEventsSubscription.trigger({
                TAG: "ShowDistance",
                _0: tuple
              });
        }), 300, false);
  var onMouseLeavePolyline = Utils.debounce0((function () {
          mapEventsSubscription.trigger("HideDistance");
        }), 300, false);
  var polylinesMovements = RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(RCore.$$Array.getBy(locations, (function (group) {
                        return Locale.T.equal(group.date, selectedDate);
                      })), (function (group) {
                    return group.locations;
                  })), (function (ls) {
                return ls.movements;
              })), []).map(function (movement) {
        var movementFinishedUuid = movement.movementFinishedUuid;
        var distance = RCore.$$Option.flatMap(RCore.$$Option.map(RCore.$$Array.getBy(dayEvents, (function (e) {
                        return Caml_obj.equal(e.uuid, movementFinishedUuid);
                      })), (function (e) {
                    return e.value;
                  })), (function (v) {
                if (typeof v !== "object") {
                  return ;
                }
                if (v.NAME !== "MovementFinished") {
                  return ;
                }
                var distance = v.VAL;
                switch (distance.TAG) {
                  case "Meters" :
                      return meter$p(distance._0, wire.ctx);
                  case "Kilometers" :
                      return kilometer$p(distance._0, wire.ctx);
                  case "Feet" :
                      return foot$p(distance._0, wire.ctx);
                  case "Miles" :
                      return mile$p(distance._0, wire.ctx);
                  
                }
              }));
        var onMouseEnter = distance !== undefined ? (function (coord) {
              onMouseEnterPolyline([
                    coord,
                    distance
                  ]);
            }) : undefined;
        var onMouseLeave = distance !== undefined ? (function (param) {
              onMouseLeavePolyline();
            }) : undefined;
        var tmp = {
          movement: movement,
          strokeOpacity: 0.7,
          mapType: wire.ctx.mapType,
          type_: "Movement"
        };
        if (onMouseEnter !== undefined) {
          tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
        }
        if (onMouseLeave !== undefined) {
          tmp.onMouseLeave = Caml_option.valFromOption(onMouseLeave);
        }
        return React.createElement(Optional.make, {
                    show: movement.locations.length >= 2,
                    children: React.createElement(JobForm_HistoryMovement.make, tmp),
                    key: Uuid.toString(movementFinishedUuid)
                  });
      });
  var polylinesOtherLocations = RCore.$$Array.filterMap(RCore.$$Option.getOr(RCore.$$Option.map(RCore.$$Option.flatMap(RCore.$$Array.getBy(locations, (function (group) {
                          return Locale.T.equal(group.date, selectedDate);
                        })), (function (group) {
                      return group.locations;
                    })), (function (ls) {
                  return ls.other;
                })), []), (function (movement) {
          if (movement.locations.length >= 2) {
            return Caml_option.some(React.createElement(JobForm_HistoryMovement.make, {
                            movement: movement,
                            strokeOpacity: 0.7,
                            mapType: wire.ctx.mapType,
                            type_: "Other",
                            key: Uuid.toString(movement.movementFinishedUuid)
                          }));
          }
          
        }));
  return React.createElement(MapReComponent.make, {
              wire: wire,
              containerRef: containerRef,
              region: region,
              fallbackRegion: fallbackRegion,
              mapEventsSubscription: mapEventsSubscription,
              controls: [
                "Ruler",
                "Zoom",
                "Views"
              ],
              children: null
            }, addressMarker, eventMarkers, polylinesMovements, polylinesOtherLocations);
}

function JobForm_HistoryMap$CloseButton(Props) {
  var toggleHistory = Props.toggleHistory;
  var isMapDummyOpt = Props.isMapDummy;
  var isMapDummy = isMapDummyOpt !== undefined ? isMapDummyOpt : false;
  var onClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    toggleHistory();
  };
  if (isMapDummy) {
    return React.createElement("div", {
                className: styles.closeButtonDummy,
                onClick: onClick
              }, React.createElement("span", {
                    className: Icon.style(undefined, undefined, undefined, "times")
                  }));
  } else {
    return React.createElement("div", {
                className: styles.closeButton,
                onClick: onClick
              }, React.createElement("span", {
                    className: Icon.style(undefined, undefined, undefined, "times")
                  }));
  }
}

function className(mapType) {
  if (typeof mapType === "object" || mapType === "Yandex") {
    return styles.centerButtonYandex;
  } else {
    return styles.centerButtonGoogle;
  }
}

function JobForm_HistoryMap$CenterOnJobButton(Props) {
  var mapType = Props.mapType;
  var triggerMapEvent = Props.triggerMapEvent;
  var region = Props.region;
  return React.createElement("span", {
              className: className(mapType),
              onClick: (function (param) {
                  RCore.$$Option.forEach(region, (function (r) {
                          triggerMapEvent({
                                TAG: "PanToRegion",
                                _0: r
                              });
                        }));
                })
            }, React.createElement("svg", {
                  height: "14px",
                  width: "16px",
                  fill: "none",
                  viewBox: "0 0 14 16",
                  xmlns: "http://www.w3.org/2000/svg"
                }, React.createElement("path", {
                      d: "m 9.9975 9.602 C 9.9975 9.8812 9.7738 10.1074 9.4976 10.1074 H 6.4984 C 6.2222 10.1074 5.9985 9.8812 5.9985 9.602 V 8.0859 H 0 V 12.6342 C 0 13.4428 0.6998 14.1503 1.4996 14.1503 H 14.4964 C 15.2962 14.1503 15.996 13.4428 15.996 12.6342 V 8.0859 H 9.9975 V 9.602 Z M 14.4964 3.0322 H 11.997 V 1.5161 C 11.997 0.7075 11.2972 0 10.4974 0 H 5.4986 C 4.6988 0 3.999 0.7075 3.999 1.5161 V 3.0322 H 1.4996 C 0.6998 3.0322 0 3.7397 0 4.5483 V 7.0752 H 15.996 V 4.5483 C 15.996 3.7397 15.2962 3.0322 14.4964 3.0322 Z M 9.9975 3.0322 H 5.9985 V 2.0215 H 9.9975 V 3.0322 Z",
                      fill: "#555555"
                    })));
}

function JobForm_HistoryMap$AddressButton(Props) {
  var addressGeolocation = Props.addressGeolocation;
  var mapEventsSubscription = Props.mapEventsSubscription;
  if (addressGeolocation === undefined) {
    return null;
  }
  var onClick = function ($$event) {
    $$event.preventDefault();
    $$event.stopPropagation();
    mapEventsSubscription.trigger({
          TAG: "Pan",
          _0: addressGeolocation
        });
  };
  return React.createElement("div", {
              className: styles.addressButton,
              onClick: onClick
            }, React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, "map-marker-alt")
                }));
}

function JobForm_HistoryMap$DummyContainer(Props) {
  var param = Props.wire;
  var toggleHistory = Props.toggleHistory;
  var ctx = param.ctx;
  return React.createElement("div", {
              className: styles.mapDummyContainer
            }, React.createElement(JobForm_HistoryMap$CloseButton, {
                  toggleHistory: toggleHistory,
                  isMapDummy: true
                }), React.createElement("p", {
                  className: styles.noMovements
                }, noMovements$p(ctx)), React.createElement("p", {
                  className: styles.eventsCondition
                }, eventsCondition$p(ctx)));
}

function filterEventCoordinatesByDate(eventsGrouped, dates) {
  return eventsGrouped.flatMap(function (group) {
              return dates.flatMap(function (date) {
                          if (Locale.T.equal(date, group.date)) {
                            return RCore.$$Array.filterMap(group.events, (function ($$event) {
                                          return RCore.$$Option.map($$event.deviceState, (function (e) {
                                                        return e.geolocation;
                                                      }));
                                        }));
                          } else {
                            return [];
                          }
                        });
            });
}

function filterLocationCoordinatesByDate(locations, dates) {
  return locations.flatMap(function (movementGroup) {
              return dates.flatMap(function (date) {
                          if (!Locale.T.equal(date, movementGroup.date)) {
                            return [];
                          }
                          var l = movementGroup.locations;
                          if (l !== undefined) {
                            return Belt_Array.concatMany([
                                        l.other.flatMap(function (o) {
                                              return o.locations.map(function (l) {
                                                          return l.geolocation;
                                                        });
                                            }),
                                        l.movements.flatMap(function (m) {
                                              return m.locations.map(function (l) {
                                                          return l.geolocation;
                                                        });
                                            })
                                      ]);
                          } else {
                            return [];
                          }
                        });
            });
}

function JobForm_HistoryMap(Props) {
  var wire = Props.wire;
  var eventsGrouped = Props.eventsGrouped;
  var addressMarkerLabel = Props.addressMarkerLabel;
  var addressGeolocation = Props.addressGeolocation;
  var locations = Props.locations;
  var mapEventsSubscription = Props.mapEventsSubscription;
  var toggleHistory = Props.toggleHistory;
  var selectedDate = Props.selectedDate;
  var onSelectDate = Props.onSelectDate;
  var dates = Props.dates;
  var triggerMapEvent = Props.triggerMapEvent;
  var match = React.useState(function () {
        
      });
  var setRegion = match[1];
  var region = match[0];
  React.useEffect((function () {
          if (selectedDate !== undefined) {
            var selectedDate$1 = Caml_option.valFromOption(selectedDate);
            if (eventsGrouped.length > 0 || locations.length > 0) {
              var coordinates = Belt_Array.concatMany([
                    filterEventCoordinatesByDate(eventsGrouped, [selectedDate$1]),
                    filterLocationCoordinatesByDate(locations, [selectedDate$1])
                  ]);
              setRegion(function (param) {
                    return calculateInitialRegion(coordinates);
                  });
            } else {
              setRegion(function (param) {
                    
                  });
            }
          } else {
            setRegion(function (param) {
                  
                });
          }
        }), [
        selectedDate,
        eventsGrouped,
        locations
      ]);
  if (selectedDate === undefined) {
    return React.createElement(JobForm_HistoryMap$DummyContainer, {
                wire: wire,
                toggleHistory: toggleHistory
              });
  }
  var selectedDate$1 = Caml_option.valFromOption(selectedDate);
  var containerRef = React.useRef(null);
  var allCoordinates = Belt_Array.concatMany([
        filterEventCoordinatesByDate(eventsGrouped, dates),
        filterLocationCoordinatesByDate(locations, dates)
      ]);
  if (allCoordinates.length <= 0) {
    return React.createElement(JobForm_HistoryMap$DummyContainer, {
                wire: wire,
                toggleHistory: toggleHistory
              });
  }
  var fallbackRegion = RCore.$$Option.getExn(calculateInitialRegion(allCoordinates), undefined);
  return React.createElement("div", {
              className: styles.mapContainer
            }, React.createElement(JobForm_HistoryMapDateSelector.make, {
                  wire: wire,
                  dates: dates,
                  selectedDate: selectedDate$1,
                  onSelectDate: onSelectDate
                }), React.createElement(JobForm_HistoryMap$CloseButton, {
                  toggleHistory: toggleHistory
                }), React.createElement(JobForm_HistoryMap$CenterOnJobButton, {
                  mapType: wire.ctx.mapType,
                  triggerMapEvent: triggerMapEvent,
                  region: region
                }), React.createElement(JobForm_HistoryMap$AddressButton, {
                  addressGeolocation: addressGeolocation,
                  mapEventsSubscription: mapEventsSubscription
                }), React.createElement("div", {
                  ref: containerRef,
                  className: styles.map
                }, React.createElement(JobForm_HistoryMap$Map, {
                      wire: wire,
                      containerRef: containerRef,
                      region: region,
                      fallbackRegion: fallbackRegion,
                      eventsGrouped: eventsGrouped,
                      selectedDate: selectedDate$1,
                      addressMarkerLabel: addressMarkerLabel,
                      addressGeolocation: addressGeolocation,
                      locations: locations,
                      mapEventsSubscription: mapEventsSubscription
                    })));
}

var make = JobForm_HistoryMap;

export {
  make ,
}
/* noMovements' Not a pure module */
