

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Caml from "rescript/lib/es6/caml.js";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Wire from "../../../types/Wire.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Filters_Button from "../../common/Filters/Filters_Button.mjs";
import * as Belt_Lib_SortArray from "../../../libraries/Belt_Lib_SortArray.mjs";
import * as ShiftsShowInterval from "./ShiftsShowInterval.mjs";
import * as Filters_ButtonGroup from "../../common/Filters/Filters_ButtonGroup.mjs";
import * as ShiftsShowFormModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/show/ShiftsShowForm.module.css";

var dayoff$p = Utils.Translations.tr("js.shifts.show.dayoff");

var workday$p = Utils.Translations.tr("js.shifts.show.workday");

var specifyHoursTerritories$p = Utils.Translations.t("js.shifts.show.specify_hours_territories");

var specifyHours$p = Utils.Translations.t("js.shifts.show.specify_hours");

var addInterval$p = Utils.Translations.tr("js.shifts.show.add_interval");

var min30minutes$p = Utils.Translations.t("js.shifts.show.min_30_minutes");

var haveOverlap$p = Utils.Translations.t("js.shifts.show.have_overlap");

var styles = ShiftsShowFormModuleCss;

function newInterval(prevIntervals, ctx) {
  if (prevIntervals.length === 0) {
    var match = ctx.businessHours;
    var match$1 = match !== undefined ? [
        Types.Time.HoursMinutes.roundMinutes(Types.Time.HoursMinutes.fromDuration(match.start), 30),
        Types.Time.HoursMinutes.roundMinutes(Types.Time.HoursMinutes.fromDuration(match.finish), 30)
      ] : [
        Types.Time.HoursMinutes.unsafeFromPair(9, 0),
        Types.Time.HoursMinutes.unsafeFromPair(13, 0)
      ];
    return [{
              timeFrom: match$1[0],
              timeTo: match$1[1],
              jobTypeUuids: [],
              territoryUuids: [],
              errors: [],
              teamUuid: undefined
            }];
  }
  var match$2 = prevIntervals[prevIntervals.length - 1 | 0];
  var timeTo = match$2.timeTo;
  var match$3 = Caml_obj.equal(timeTo, Types.Time.HoursMinutes.unsafeFromPair(0, 0)) ? (
      Caml_obj.equal(match$2.timeFrom, Types.Time.HoursMinutes.unsafeFromPair(0, 0)) ? [
          Types.Time.HoursMinutes.unsafeFromPair(9, 0),
          Types.Time.HoursMinutes.unsafeFromPair(13, 0)
        ] : [
          Types.Time.HoursMinutes.unsafeFromPair(0, 0),
          Types.Time.HoursMinutes.unsafeFromPair(0, 0)
        ]
    ) : [
      timeTo,
      timeTo
    ];
  return Belt_Lib_SortArray.stableSortBy(Belt_Array.concatMany([
                  prevIntervals,
                  [{
                      timeFrom: match$3[0],
                      timeTo: match$3[1],
                      jobTypeUuids: [],
                      territoryUuids: [],
                      errors: [],
                      teamUuid: undefined
                    }]
                ]), (function (a, b) {
                return Types.Time.HoursMinutes.minutesFromStartOfDay(a.timeFrom) - Types.Time.HoursMinutes.minutesFromStartOfDay(b.timeFrom) | 0;
              }));
}

function isSmallRange(param) {
  return (param[1] - param[0] | 0) < 30;
}

function haveIntersection(range1, ranges) {
  return ranges.some(function (range2) {
              if (range1 !== range2) {
                return Caml.int_max(range1[0], range2[0]) < Caml.int_min(range1[1], range2[1]);
              } else {
                return false;
              }
            });
}

function validateIntervals(ctx, intervals) {
  var minuteRanges = intervals.map(function (param) {
        return [
                Types.Time.HoursMinutes.minutesFromStartOfDay(param.timeFrom),
                Types.Time.HoursMinutes.minutesFromStartOfDay(param.timeTo)
              ];
      });
  var validatedIntervals = intervals.map(function (interval, index) {
        var range = minuteRanges[index];
        var haveSmallRange = isSmallRange(range) ? min30minutes$p(ctx) : undefined;
        var haveIntersection$1 = haveIntersection(range, minuteRanges) ? haveOverlap$p(ctx) : undefined;
        var errors = RCore.$$Array.filterMap([
              haveSmallRange,
              haveIntersection$1
            ], Fun.id);
        return {
                timeFrom: interval.timeFrom,
                timeTo: interval.timeTo,
                jobTypeUuids: interval.jobTypeUuids,
                territoryUuids: interval.territoryUuids,
                errors: errors,
                teamUuid: interval.teamUuid
              };
      });
  var haveErrors = validatedIntervals.some(function (i) {
        return i.errors.length > 0;
      });
  return [
          haveErrors,
          validatedIntervals
        ];
}

function ShiftsShowForm$Intervals(Props) {
  var onClickAddInterval = Props.onClickAddInterval;
  var onClickRemoveInterval = Props.onClickRemoveInterval;
  var onUpdateInterval = Props.onUpdateInterval;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var intervals = Props.intervals;
  var onCloseTimePicker = Props.onCloseTimePicker;
  var disabled = Props.disabled;
  var workerTerritoryUuid = Props.workerTerritoryUuid;
  var isTeam = Props.isTeam;
  var match = AppContext.useWire();
  var workerTerritory = React.useMemo((function () {
          return RCore.$$Option.flatMap(workerTerritoryUuid, (function (territoryUuid) {
                        return RCore.$$Array.getBy(territories, (function (t) {
                                      return Caml_obj.equal(t.value, territoryUuid);
                                    }));
                      }));
        }), []);
  return React.createElement("div", {
              className: styles.intervals
            }, intervals.map(function (interval, index) {
                  return React.createElement(ShiftsShowInterval.make, {
                              interval: interval,
                              jobTypes: jobTypes,
                              territories: territories,
                              onClickRemoveInterval: (function () {
                                  onClickRemoveInterval(index);
                                }),
                              onUpdateInterval: onUpdateInterval(index),
                              onCloseTimePicker: onCloseTimePicker,
                              workerTerritory: workerTerritory,
                              isTeam: isTeam,
                              key: index.toString()
                            });
                }), React.createElement(Optional.make, {
                  show: intervals.length < 10,
                  children: React.createElement("button", {
                        className: disabled ? El.Cn.concat(styles.addInterval, styles.addIntervalDisabled) : styles.addInterval,
                        disabled: disabled,
                        onClick: (function (param) {
                            onClickAddInterval();
                          })
                      }, React.createElement("span", {
                            className: Icon.style(undefined, undefined, undefined, "plus")
                          }), addInterval$p(match.ctx))
                }));
}

function ShiftsShowForm(Props) {
  var selectedDay = Props.selectedDay;
  var initialShift = Props.shift;
  var workerTerritoryUuid = Props.workerTerritoryUuid;
  var jobTypes = Props.jobTypes;
  var territories = Props.territories;
  var submitShift = Props.submitShift;
  var locked = Props.locked;
  var popoverRef = Props.popoverRef;
  var close = Props.close;
  var isTeam = Props.isTeam;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        if (initialShift !== undefined) {
          return !initialShift.useBusinessHours;
        } else {
          return false;
        }
      });
  var setSpecifyHours = match[1];
  var specifyHours = match[0];
  var match$1 = React.useState(function () {
        return initialShift;
      });
  var setShift = match$1[1];
  var shift = match$1[0];
  var match$2 = React.useState(function () {
        if (initialShift !== undefined) {
          return "Workday";
        } else {
          return "DayOff";
        }
      });
  var setFilters = match$2[1];
  var filters = match$2[0];
  var intervals = RCore.$$Option.getOr(RCore.$$Option.map(shift, (function (s) {
              return s.intervals;
            })), []);
  var persistUuid = function () {
    return RCore.$$Option.getOr(RCore.$$Option.map(initialShift, (function (s) {
                      return s.uuid;
                    })), Uuid.createV6());
  };
  var initBusinessIntervals = function () {
    if (shift !== undefined) {
      return setShift(function (param) {
                  return {
                          uuid: shift.uuid,
                          date: shift.date,
                          useBusinessHours: RCore.$$Option.isSome(Wire.businessHours(wire)),
                          intervals: []
                        };
                });
    } else {
      return setShift(function (param) {
                  return {
                          uuid: persistUuid(),
                          date: selectedDay,
                          useBusinessHours: RCore.$$Option.isSome(Wire.businessHours(wire)),
                          intervals: []
                        };
                });
    }
  };
  var initIntervals = function () {
    if (shift === undefined) {
      return setShift(function (param) {
                  return {
                          uuid: persistUuid(),
                          date: selectedDay,
                          useBusinessHours: false,
                          intervals: newInterval([], ctx)
                        };
                });
    }
    var useBusinessHours = shift.useBusinessHours;
    setShift(function (param) {
          return {
                  uuid: shift.uuid,
                  date: shift.date,
                  useBusinessHours: false,
                  intervals: useBusinessHours ? newInterval([], ctx) : shift.intervals
                };
        });
  };
  var onClickAddInterval = function () {
    var newShift = shift !== undefined ? ({
          uuid: shift.uuid,
          date: shift.date,
          useBusinessHours: shift.useBusinessHours,
          intervals: newInterval(shift.intervals, ctx)
        }) : ({
          uuid: persistUuid(),
          date: selectedDay,
          useBusinessHours: false,
          intervals: newInterval([], ctx)
        });
    setShift(function (param) {
          return newShift;
        });
  };
  var onClickRemoveInterval = function (intervalIndex) {
    if (shift === undefined) {
      return ;
    }
    var filteredIntervals = shift.intervals.filter(function (param, idx) {
          return intervalIndex !== idx;
        });
    var match = validateIntervals(ctx, filteredIntervals);
    if (filteredIntervals.length === 0) {
      return setShift(function (param) {
                  
                });
    }
    var updatedShift_uuid = shift.uuid;
    var updatedShift_date = shift.date;
    var updatedShift_useBusinessHours = shift.useBusinessHours;
    var updatedShift_intervals = match[1];
    var updatedShift = {
      uuid: updatedShift_uuid,
      date: updatedShift_date,
      useBusinessHours: updatedShift_useBusinessHours,
      intervals: updatedShift_intervals
    };
    setShift(function (param) {
          return updatedShift;
        });
  };
  var onUpdateInterval = function (intervalIndex) {
    return function (newInterval) {
      if (shift === undefined) {
        return ;
      }
      var updatedShift_uuid = shift.uuid;
      var updatedShift_date = shift.date;
      var updatedShift_useBusinessHours = shift.useBusinessHours;
      var updatedShift_intervals = shift.intervals.map(function (interval, idx) {
            if (intervalIndex === idx) {
              return newInterval;
            } else {
              return interval;
            }
          });
      var updatedShift = {
        uuid: updatedShift_uuid,
        date: updatedShift_date,
        useBusinessHours: updatedShift_useBusinessHours,
        intervals: updatedShift_intervals
      };
      setShift(function (param) {
            return updatedShift;
          });
    };
  };
  var updateStateWithValidatedIntervals = function () {
    if (shift === undefined) {
      return [
              {
                TAG: "Ok",
                _0: undefined
              },
              undefined
            ];
    }
    var match = validateIntervals(ctx, shift.intervals);
    var validatedIntervals = match[1];
    var shift$1 = validatedIntervals.length === 0 && !shift.useBusinessHours ? undefined : ({
          uuid: shift.uuid,
          date: shift.date,
          useBusinessHours: shift.useBusinessHours,
          intervals: validatedIntervals
        });
    if (match[0]) {
      return [
              {
                TAG: "Error",
                _0: undefined
              },
              shift$1
            ];
    } else {
      return [
              {
                TAG: "Ok",
                _0: undefined
              },
              shift$1
            ];
    }
  };
  var onClickSubmit = function () {
    var match = updateStateWithValidatedIntervals();
    if (match[0].TAG === "Ok") {
      var updated = match[1];
      setShift(function (param) {
            return updated;
          });
      submitShift(updated);
      return close();
    }
    var updated$1 = match[1];
    setShift(function (param) {
          return updated$1;
        });
  };
  Hooks.useOnClickOutside(undefined, popoverRef, (function () {
          onClickSubmit();
        }));
  Hooks.useOnEscPress(undefined, (function () {
          onClickSubmit();
        }));
  var haveErrors = intervals.some(function (i) {
        return i.errors.length > 0;
      });
  var disabled = locked || haveErrors;
  var onCloseTimePicker = function () {
    updateStateWithValidatedIntervals();
  };
  var showIntervals = (RCore.$$Option.isNone(ctx.businessHours) || specifyHours) && (RCore.$$Option.isSome(shift) || filters === "Workday");
  return React.createElement("div", {
              ref: popoverRef,
              className: styles.content
            }, React.createElement(Filters_ButtonGroup.make, {
                  children: null
                }, React.createElement(Filters_Button.make, {
                      onClick: (function (param) {
                          setFilters(function (param) {
                                return "DayOff";
                              });
                          setShift(function (param) {
                                
                              });
                        }),
                      active: filters === "DayOff",
                      children: dayoff$p(ctx)
                    }), React.createElement(Filters_Button.make, {
                      onClick: (function (param) {
                          initBusinessIntervals();
                          setFilters(function (param) {
                                return "Workday";
                              });
                        }),
                      active: filters === "Workday",
                      children: workday$p(ctx)
                    })), React.createElement(Optional.make, {
                  show: filters === "Workday" && RCore.$$Option.isSome(ctx.businessHours),
                  children: React.createElement("div", {
                        className: styles.checkbox
                      }, React.createElement(Checkbox.make, {
                            onToggle: (function (use) {
                                if (use) {
                                  initIntervals();
                                } else {
                                  initBusinessIntervals();
                                }
                                setSpecifyHours(function (param) {
                                      return use;
                                    });
                              }),
                            checked: specifyHours,
                            label: Context_Types.Features.hasFlag("territories", ctx.features) ? specifyHoursTerritories$p(ctx) : specifyHours$p(ctx)
                          }))
                }), showIntervals ? React.createElement(ShiftsShowForm$Intervals, {
                    onClickAddInterval: onClickAddInterval,
                    onClickRemoveInterval: onClickRemoveInterval,
                    onUpdateInterval: onUpdateInterval,
                    jobTypes: jobTypes,
                    territories: territories,
                    intervals: intervals,
                    onCloseTimePicker: onCloseTimePicker,
                    disabled: disabled,
                    workerTerritoryUuid: workerTerritoryUuid,
                    isTeam: isTeam
                  }) : null);
}

var make = ShiftsShowForm;

export {
  make ,
}
/* dayoff' Not a pure module */
