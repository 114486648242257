

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Uuid from "../../../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Optional from "../../../common/Optional.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ShiftsIndexCellModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/ShiftsIndexCell.module.css";

var styles = ShiftsIndexCellModuleCss;

function ShiftsIndexTeamMembersCell$Cell(Props) {
  var mode = Props.mode;
  var worker = Props.worker;
  var day = Props.day;
  var teamUuid = Props.teamUuid;
  var isPicked = Props.isPicked;
  var shift = React.useMemo((function () {
          return RCore.$$Array.getBy(worker.shifts, (function (s) {
                        return Locale.T.equal(s.date, day);
                      }));
        }), [worker.shifts]);
  var cellShapeCN = React.useMemo((function () {
          if (shift === undefined) {
            return [styles.emptyDay];
          }
          var teamUuids = RCore.$$Array.filterMap(shift.intervals, (function (i) {
                  return i.teamUuid;
                }));
          return RCore.$$Array.filterMap([
                      [
                        teamUuids.length !== shift.intervals.length,
                        styles.workday
                      ],
                      [
                        teamUuids.some(function (u) {
                              return !Uuid.equal(u, teamUuid);
                            }),
                        styles.otherTeam
                      ],
                      [
                        teamUuids.some(function (u) {
                              return Uuid.equal(u, teamUuid);
                            }),
                        styles.memberTeamDay
                      ]
                    ], (function (r) {
                        if (r[0]) {
                          return r[1];
                        }
                        
                      }));
        }), [shift]);
  var sharedCN;
  sharedCN = mode === "View" ? styles.view : styles.constructor;
  return React.createElement(React.Fragment, undefined, cellShapeCN.map(function (cn) {
                    if (isPicked) {
                      return El.Cn.concat(sharedCN, El.Cn.concat(cn, styles.picked));
                    } else {
                      return El.Cn.concat(cn, sharedCN);
                    }
                  }).map(function (cn, idx) {
                  return React.createElement("div", {
                              key: idx.toString(),
                              className: cn
                            });
                }), React.createElement(Optional.make, {
                  show: isPicked,
                  children: React.createElement("i", {
                        className: Icon.style(undefined, undefined, undefined, "check")
                      })
                }));
}

function ShiftsIndexTeamMembersCell(Props) {
  var mode = Props.mode;
  var day = Props.day;
  var workers = Props.workers;
  var team = Props.team;
  var isPicked = Props.isPicked;
  var nonForemanWorkerUuids = RCore.$$Array.reduce(Belt_Array.concatMany([
            team.workerUuids,
            team.temporaryWorkers.map(function (tw) {
                  return tw.userUuid;
                })
          ]), [], (function (acc, current) {
          if (acc.some(function (e) {
                  return Caml_obj.equal(e, current);
                })) {
            return acc;
          } else {
            return Belt_Array.concatMany([
                        acc,
                        [current]
                      ]);
          }
        }));
  var sortedWorkers = RCore.$$Array.keepMap(Belt_Array.concatMany([
            RCore.$$Option.mapWithDefault(team.foremanUuid, [], (function (u) {
                    return [u];
                  })),
            nonForemanWorkerUuids
          ]), (function (uuid) {
          return RCore.$$Array.getBy(workers, (function (w) {
                        return Uuid.equal(w.uuid, uuid);
                      }));
        }));
  return sortedWorkers.map(function (worker) {
              return React.createElement("div", {
                          key: Uuid.toString(worker.uuid),
                          className: styles.memberCell
                        }, React.createElement(ShiftsIndexTeamMembersCell$Cell, {
                              mode: mode,
                              worker: worker,
                              day: day,
                              teamUuid: team.uuid,
                              isPicked: isPicked
                            }));
            });
}

var make = ShiftsIndexTeamMembersCell;

export {
  make ,
}
/* styles Not a pure module */
