

import * as Utils from "../../../../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../../../../libraries/Locale.mjs";
import * as AppContext from "../../../../../../context/AppContext.mjs";
import * as JobIndexFilter_Dates from "./JobIndexFilter_Dates.mjs";

var label$p = Utils.Translations.t("js.jobs.index.filters.resumed_at");

var yesterday$p = Utils.Translations.tr("js.jobs.index.finished_at.yesterday");

var today$p = Utils.Translations.tr("js.jobs.index.finished_at.today");

var prevWeek$p = Utils.Translations.tr("js.jobs.index.finished_at.prev_week");

var thisWeek$p = Utils.Translations.tr("js.jobs.index.finished_at.this_week");

function prevMonth$p(wire) {
  return Utils.Translations.tr("js.month." + Locale.T.month(Locale.T.prevMonth(Locale.T.now(wire))).toString())(wire.ctx);
}

function currentMonth$p(wire) {
  return Utils.Translations.tr("js.month." + Locale.T.month(Locale.T.now(wire)).toString())(wire.ctx);
}

function JobIndexFilter_ResumedAt(Props) {
  var value = Props.value;
  var disabled = Props.disabled;
  var toggleFilter = Props.toggleFilter;
  var show = Props.show;
  var filterByField = Props.filterByField;
  var resetFilter = Props.resetFilter;
  var activeView = Props.activeView;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var buttons = [
    [
      "yesterday",
      yesterday$p(ctx),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "days",
                      VAL: -1
                    });
        })
    ],
    [
      "today",
      today$p(ctx),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "days",
                      VAL: 0
                    });
        })
    ],
    [
      "prev-week",
      prevWeek$p(ctx),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -1
                    });
        })
    ],
    [
      "this-week",
      thisWeek$p(ctx),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: 0
                    });
        })
    ],
    [
      "prev-month",
      prevMonth$p(wire),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "months",
                      VAL: -1
                    });
        })
    ],
    [
      "current-month",
      currentMonth$p(wire),
      (function () {
          return Locale.T.makeRange(Locale.T.now(wire), {
                      NAME: "months",
                      VAL: 0
                    });
        })
    ]
  ];
  return React.createElement(JobIndexFilter_Dates.make, {
              predefined: true,
              activeView: activeView,
              label: label$p(ctx),
              resetFilter: resetFilter,
              toggleFilter: toggleFilter,
              filterByField: filterByField,
              value: value,
              buttons: buttons,
              show: show,
              disabled: disabled
            });
}

var make = JobIndexFilter_ResumedAt;

export {
  make ,
}
/* label' Not a pure module */
