

import * as Note from "../../common/Note/Note.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as UI_Form from "../../../ui/UI_Form.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as UI_Label from "../../../ui/UI_Label.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as ProgressBar from "../../common/ProgressBar/ProgressBar.mjs";
import * as SpinnerInput from "../../common/SpinnerInput/SpinnerInput.mjs";
import * as Liquid_Editor from "../../../libraries/liquid/editor/Liquid_Editor.mjs";
import * as Settings_Types from "../Settings_Types.mjs";
import * as Settings_SmsSubstitutions from "./Settings_SmsSubstitutions.mjs";
import * as Settings_SMSModuleCss from "/home/runner/work/planado/planado/client/rescript/components/settings/sms/Settings_SMS.module.css";

function limit(field) {
  var limits = Settings_Types.Limits.limits.smsSettings;
  switch (field) {
    case "JobFinishedTemplate" :
        return limits.jobFinishedTemplate;
    case "WorkerEnRouteTemplate" :
        return limits.workerEnRouteTemplate;
    case "NearestJobsTemplate" :
        return limits.nearestJobsTemplate;
    
  }
}

var smsSettings$p = Utils.Translations.tr("js.settings.sms.title");

var smsInfo$p = Utils.Translations.tr("js.settings.sms.warning");

var sentMessages$p = Utils.Translations.tr("js.settings.sms.sent_messages");

var nearestJobsTemplate$p = Utils.Translations.t("js.settings.sms.nearest_jobs_template");

var workerEnRouteTemplate$p = Utils.Translations.t("js.settings.sms.worker_en_route_template");

var jobFinishedTemplate$p = Utils.Translations.t("js.settings.sms.job_finished_template");

var sendHour$p = Utils.Translations.tr("js.settings.sms.send_hour");

var styles = Settings_SMSModuleCss;

function Settings_SMS(Props) {
  var smsSettings = Props.smsSettings;
  var hasSmsProvider = Props.hasSmsProvider;
  var setSmsSettings = Props.setSmsSettings;
  var errors = Props.errors;
  var setErrors = Props.setErrors;
  var trackingForms = Props.trackingForms;
  var wire = AppContext.useWire();
  var quotas = smsSettings.quotas;
  var inputId = Hooks.useId(undefined);
  var onTextareaChange = function (name) {
    return function (value) {
      var value$1 = Utils.stringLengthWithNewLines(value) <= limit(name) ? value : value.slice(0, limit(name));
      var tmp;
      switch (name) {
        case "JobFinishedTemplate" :
            tmp = {
              quotas: smsSettings.quotas,
              workerEnRouteTemplate: smsSettings.workerEnRouteTemplate,
              nearestJobsTemplate: smsSettings.nearestJobsTemplate,
              jobFinishedTemplate: value$1,
              sendHour: smsSettings.sendHour
            };
            break;
        case "WorkerEnRouteTemplate" :
            tmp = {
              quotas: smsSettings.quotas,
              workerEnRouteTemplate: value$1,
              nearestJobsTemplate: smsSettings.nearestJobsTemplate,
              jobFinishedTemplate: smsSettings.jobFinishedTemplate,
              sendHour: smsSettings.sendHour
            };
            break;
        case "NearestJobsTemplate" :
            tmp = {
              quotas: smsSettings.quotas,
              workerEnRouteTemplate: smsSettings.workerEnRouteTemplate,
              nearestJobsTemplate: value$1,
              jobFinishedTemplate: smsSettings.jobFinishedTemplate,
              sendHour: smsSettings.sendHour
            };
            break;
        
      }
      setSmsSettings(tmp);
    };
  };
  var onErrorsChange = function (name) {
    return function (value) {
      setErrors(function (e) {
            switch (name) {
              case "JobFinishedTemplate" :
                  return {
                          owner: e.owner,
                          businessHours: e.businessHours,
                          language: e.language,
                          timezone: e.timezone,
                          country: e.country,
                          dateFormat: e.dateFormat,
                          measurementSystem: e.measurementSystem,
                          thousandsSeparator: e.thousandsSeparator,
                          decimalMark: e.decimalMark,
                          currency: e.currency,
                          currencyFullFormat: e.currencyFullFormat,
                          smsNearest: e.smsNearest,
                          smsEnRoute: e.smsEnRoute,
                          smsFinished: value
                        };
              case "WorkerEnRouteTemplate" :
                  return {
                          owner: e.owner,
                          businessHours: e.businessHours,
                          language: e.language,
                          timezone: e.timezone,
                          country: e.country,
                          dateFormat: e.dateFormat,
                          measurementSystem: e.measurementSystem,
                          thousandsSeparator: e.thousandsSeparator,
                          decimalMark: e.decimalMark,
                          currency: e.currency,
                          currencyFullFormat: e.currencyFullFormat,
                          smsNearest: e.smsNearest,
                          smsEnRoute: value,
                          smsFinished: e.smsFinished
                        };
              case "NearestJobsTemplate" :
                  return {
                          owner: e.owner,
                          businessHours: e.businessHours,
                          language: e.language,
                          timezone: e.timezone,
                          country: e.country,
                          dateFormat: e.dateFormat,
                          measurementSystem: e.measurementSystem,
                          thousandsSeparator: e.thousandsSeparator,
                          decimalMark: e.decimalMark,
                          currency: e.currency,
                          currencyFullFormat: e.currencyFullFormat,
                          smsNearest: value,
                          smsEnRoute: e.smsEnRoute,
                          smsFinished: e.smsFinished
                        };
              
            }
          });
    };
  };
  var limit$1 = quotas.limit;
  var stats = limit$1 !== undefined ? quotas.used.toString() + "/" + limit$1.toString() : quotas.used.toString();
  var percent = React.useMemo((function () {
          if (hasSmsProvider) {
            return 100;
          }
          var limit = quotas.limit;
          if (limit !== undefined) {
            return Caml_int32.div(Math.imul(quotas.used, 100), limit);
          } else {
            return 100;
          }
        }), [
        hasSmsProvider,
        quotas
      ]);
  var substitutions = Settings_SmsSubstitutions.useSubstitutions(trackingForms);
  return React.createElement(UI_Form.Section.make, {
              children: null
            }, React.createElement(UI_Form.SectionTitle.make, {
                  children: smsSettings$p(wire.ctx)
                }), React.createElement(UI_Form.Container.make, {
                  children: null
                }, React.createElement(Note.make, {
                      className: styles.warning,
                      children: smsInfo$p(wire.ctx),
                      flavor: "warning"
                    }), React.createElement(Optional.make, {
                      show: !hasSmsProvider,
                      children: React.createElement(UI_Form.Group.make, {
                            children: null
                          }, React.createElement(UI_Label.make, {
                                children: sentMessages$p(wire.ctx)
                              }), React.createElement(ProgressBar.make, {
                                percent: percent,
                                stats: stats
                              }))
                    }), React.createElement(Liquid_Editor.make, {
                      maxLength: limit("NearestJobsTemplate"),
                      label: nearestJobsTemplate$p(wire.ctx),
                      value: RCore.$$Option.getOr(smsSettings.nearestJobsTemplate, ""),
                      onValueChange: onTextareaChange("NearestJobsTemplate"),
                      substitutions: substitutions,
                      errors: errors.smsNearest,
                      onErrorsChange: onErrorsChange("NearestJobsTemplate")
                    }), React.createElement(Liquid_Editor.make, {
                      maxLength: limit("WorkerEnRouteTemplate"),
                      label: workerEnRouteTemplate$p(wire.ctx),
                      value: RCore.$$Option.getOr(smsSettings.workerEnRouteTemplate, ""),
                      onValueChange: onTextareaChange("WorkerEnRouteTemplate"),
                      substitutions: substitutions,
                      errors: errors.smsEnRoute,
                      onErrorsChange: onErrorsChange("WorkerEnRouteTemplate")
                    }), React.createElement(Liquid_Editor.make, {
                      maxLength: limit("JobFinishedTemplate"),
                      label: jobFinishedTemplate$p(wire.ctx),
                      value: RCore.$$Option.getOr(smsSettings.jobFinishedTemplate, ""),
                      onValueChange: onTextareaChange("JobFinishedTemplate"),
                      substitutions: substitutions,
                      errors: errors.smsFinished,
                      onErrorsChange: onErrorsChange("JobFinishedTemplate")
                    }), React.createElement(UI_Form.Group.make, {
                      children: null
                    }, React.createElement(UI_Label.make, {
                          htmlFor: inputId,
                          children: sendHour$p(wire.ctx)
                        }), React.createElement(SpinnerInput.Stateless.make, {
                          id: inputId,
                          classes: {
                            spinner_restrict: true
                          },
                          value: smsSettings.sendHour,
                          min: 0,
                          max: 24,
                          disabled: wire.ctx.disposable,
                          onValueChange: (function (value) {
                              setSmsSettings({
                                    quotas: smsSettings.quotas,
                                    workerEnRouteTemplate: smsSettings.workerEnRouteTemplate,
                                    nearestJobsTemplate: smsSettings.nearestJobsTemplate,
                                    jobFinishedTemplate: smsSettings.jobFinishedTemplate,
                                    sendHour: value
                                  });
                            })
                        }))));
}

var make = Settings_SMS;

export {
  make ,
}
/* smsSettings' Not a pure module */
