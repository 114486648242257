

import * as JsCookie from "js-cookie";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function get(name) {
  return Caml_option.undefined_to_opt(JsCookie.default.get(name));
}

export {
  get ,
}
/* js-cookie Not a pure module */
