

import * as El from "../../../libraries/El.mjs";
import * as Fun from "../../../libraries/Fun.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Panel from "../Panel/Panel.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Checkbox from "../../inputs/Checkbox.mjs";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Optional from "../Optional.mjs";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as AssigneesFilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/AssigneesFilter/AssigneesFilter.module.css";

function button$p(ctx, filter) {
  var len = filter.length;
  var num = len > 0 ? " (" + len.toString() + ")" : "";
  return Utils.Translations.t("js.schedule.filter.button")(ctx) + num;
}

var teams$p = Utils.Translations.tr("js.schedule.filter.teams");

var workers$p = Utils.Translations.tr("js.schedule.filter.workers");

var styles = AssigneesFilterModuleCss;

var nextId = (function (_) { return Date.now().toString() });

function AssigneesFilter$Search(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return false;
      });
  var setState = match[1];
  var state = match[0];
  var icon = React.createElement(Optional.make, {
        show: value !== "",
        children: React.createElement("span", {
              className: El.Cn.concat(Icon.style(undefined, undefined, undefined, "times"), styles.searchIcon),
              onClick: (function (param) {
                  onChange("");
                })
            })
      });
  var className = state ? El.Cn.concat(styles.search, styles.focused) : styles.search;
  return React.createElement("div", {
              className: className
            }, icon, React.createElement("input", {
                  className: "form-control",
                  autoComplete: "off",
                  type: "text",
                  value: value,
                  onFocus: (function (param) {
                      setState(function (param) {
                            return !state;
                          });
                    }),
                  onBlur: (function (param) {
                      setState(function (param) {
                            return !state;
                          });
                    }),
                  onChange: (function (v) {
                      onChange(El.getStringValueFromEvent(v));
                    })
                }));
}

function AssigneesFilter$Assignees(Props) {
  var query = Props.query;
  var filter = Props.filter;
  var assignees = Props.assignees;
  var onFilterChange = Props.onFilterChange;
  return RCore.$$Array.keep(assignees, (function (param) {
                  if (query !== undefined) {
                    return Js_string.includes(query.toLowerCase(), param.name.toLowerCase());
                  } else {
                    return true;
                  }
                })).map(function (param) {
              var uuid = param.uuid;
              var checked = RCore.$$Option.isSome(Js_array.find((function (id) {
                          return id === uuid;
                        }), filter));
              return React.createElement("div", {
                          key: uuid,
                          className: styles.item
                        }, React.createElement(Checkbox.make, {
                              onToggle: onFilterChange(uuid),
                              checked: checked,
                              label: param.name,
                              name: uuid,
                              id: uuid
                            }));
            });
}

function AssigneesFilter(Props) {
  var param = Props.wire;
  var filter = Props.filter;
  var teams = Props.teams;
  var workers = Props.workers;
  var onChange = Props.onChange;
  var subscriptions = param.subscriptions;
  var ctx = param.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var show = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setQuery = match$1[1];
  var query = match$1[0];
  var popoverRef = React.useRef(null);
  var buttonId = React.useMemo(nextId, []);
  var close = function () {
    setShow(function (param) {
          return false;
        });
    setQuery(function (param) {
          
        });
  };
  var showPopover = function ($$event) {
    $$event.stopPropagation();
    setShow(function (param) {
          return !show;
        });
  };
  var keyDown = function (param) {
    var next = param[1];
    var $$event = param[0];
    var match = $$event.code;
    if (match === "Escape" && show) {
      return close();
    } else {
      return next($$event);
    }
  };
  var click = function ($$event) {
    var target = $$event.target;
    $$event.stopPropagation();
    var popover = popoverRef.current;
    if (!(popover == null) && !DomUtils.contains(popover, target)) {
      return close();
    }
    
  };
  var scroll = function ($$event) {
    var target = $$event.target;
    $$event.stopPropagation();
    var popover = popoverRef.current;
    if (!(popover == null) && !DomUtils.contains(popover, target) && show) {
      return close();
    }
    
  };
  var onFilterChange = function (uuid) {
    return function (checked) {
      var filter$1 = checked ? Belt_Array.concatMany([
              filter,
              [uuid]
            ]) : Js_array.filter((function (id) {
                return id !== uuid;
              }), filter);
      onChange(filter$1);
    };
  };
  React.useEffect((function () {
          var unsubscribeFromKeydown = subscriptions.keydown.subscribe(keyDown);
          var unsubscribeFromClick = subscriptions.click.subscribe(click);
          var unsubscribeFromScroll = subscriptions.scroll.subscribe(scroll);
          return (function () {
                    unsubscribeFromKeydown();
                    unsubscribeFromClick();
                    unsubscribeFromScroll();
                  });
        }), []);
  var tenantHasTeamsFeature = Context_Types.Features.hasFlag("teams", ctx.features);
  return React.createElement("div", {
              className: styles.container
            }, React.createElement("button", {
                  className: styles.button,
                  id: buttonId,
                  type: "button",
                  onClick: showPopover
                }, button$p(ctx, filter)), React.createElement(Panel.make, {
                  footer: param.footer,
                  show: show,
                  domId: buttonId,
                  position: "bottom",
                  className: styles.dropdown,
                  children: React.createElement("div", {
                        ref: popoverRef,
                        className: styles.items
                      }, React.createElement(AssigneesFilter$Search, {
                            value: RCore.$$Option.getOr(query, ""),
                            onChange: (function (v) {
                                setQuery(function (param) {
                                      return Fun.optString(v);
                                    });
                              })
                          }), React.createElement(Optional.make, {
                            show: tenantHasTeamsFeature,
                            children: null
                          }, React.createElement("div", {
                                className: styles.sectionName
                              }, teams$p(ctx)), React.createElement(AssigneesFilter$Assignees, {
                                query: query,
                                filter: filter,
                                assignees: teams,
                                onFilterChange: onFilterChange
                              })), React.createElement("div", {
                            className: styles.sectionName
                          }, workers$p(ctx)), React.createElement(AssigneesFilter$Assignees, {
                            query: query,
                            filter: filter,
                            assignees: workers,
                            onFilterChange: onFilterChange
                          }))
                }));
}

var make = AssigneesFilter;

export {
  make ,
}
/* teams' Not a pure module */
