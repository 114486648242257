

import * as JwtDecode from "jwt-decode";

function decode(token) {
  try {
    return JwtDecode.jwtDecode(token);
  }
  catch (exn){
    return ;
  }
}

export {
  decode ,
}
/* jwt-decode Not a pure module */
