

import * as Wire from "./types/Wire.mjs";
import * as Backend from "./libraries/backend/Backend.mjs";

function post(wire) {
  Backend.$$finally(Backend.post(undefined, true, undefined, undefined, wire, {
            NAME: "API",
            VAL: {
              NAME: "Token",
              VAL: "Refresh"
            }
          }, {
            NAME: "Obj",
            VAL: {}
          }), (function (param) {
          Wire.refreshToken(wire);
        }));
}

var Refresh = {
  post: post
};

export {
  Refresh ,
}
/* Wire Not a pure module */
