

import * as Icon from "../../../../Icon.mjs";
import * as React from "react";
import * as MapJobsFilterByDate from "./MapJobsFilterByDate.mjs";
import * as MapJobsFilterByType from "./MapJobsFilterByType.mjs";
import * as MapJobsFilterByTemplate from "./MapJobsFilterByTemplate.mjs";
import * as MapJobsFilterByTerritory from "./MapJobsFilterByTerritory.mjs";
import * as MapJobsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/map/jobs/MapJobs.module.css";

var styles = MapJobsModuleCss;

function MapJobsFilter(Props) {
  var wire = Props.wire;
  var fetching = Props.fetching;
  var filter = Props.filter;
  var setFilter = Props.setFilter;
  var filterValues = Props.filterValues;
  var onPeriodChange = function (period) {
    setFilter({
          TAG: "SetPeriod",
          _0: period
        });
  };
  var onTerritoryChange = function (territory) {
    setFilter({
          TAG: "SetTerritory",
          _0: territory
        });
  };
  var onTypeChange = function (jobType) {
    setFilter({
          TAG: "SetJobType",
          _0: jobType
        });
  };
  var onTemplateChange = function (template) {
    setFilter({
          TAG: "SetTemplate",
          _0: template
        });
  };
  var filters;
  var exit = 0;
  if (filterValues !== undefined && !fetching) {
    filters = React.createElement(React.Fragment, undefined, React.createElement(MapJobsFilterByTerritory.make, {
              wire: wire,
              territory: filter.territory,
              territories: filterValues.territories,
              onChange: onTerritoryChange
            }), React.createElement(MapJobsFilterByType.make, {
              wire: wire,
              jobType: filter.jobType,
              types: filterValues.types,
              onChange: onTypeChange
            }), React.createElement(MapJobsFilterByTemplate.make, {
              wire: wire,
              template: filter.template,
              templates: filterValues.templates,
              onChange: onTemplateChange
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    filters = React.createElement("div", {
          className: styles.spinner
        }, React.createElement("i", {
              className: Icon.style("2x", true, undefined, "spinner")
            }));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(MapJobsFilterByDate.make, {
                  wire: wire,
                  period: filter.period,
                  onChange: onPeriodChange
                }), filters);
}

var make = MapJobsFilter;

export {
  make ,
}
/* styles Not a pure module */
