

import * as React from "react";
import * as Optional from "../Optional.mjs";
import * as ProgressBarModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/ProgressBar/ProgressBar.module.css";

var styles = ProgressBarModuleCss;

function ProgressBar(Props) {
  var percent = Props.percent;
  var stats = Props.stats;
  var progressBarStyle = {
    width: percent.toString() + "%"
  };
  var match = stats !== undefined ? [
      React.createElement("div", {
            className: styles.stats
          }, React.createElement("span", undefined, stats)),
      true
    ] : [
      null,
      false
    ];
  var className = match[1] ? styles.progress : styles.progress + " " + styles.noStats;
  return React.createElement("div", {
              className: className
            }, match[0], React.createElement(Optional.make, {
                  show: percent > 0,
                  children: React.createElement("div", {
                        className: styles.progressBar,
                        style: progressBarStyle
                      })
                }));
}

var make = ProgressBar;

export {
  make ,
}
/* styles Not a pure module */
