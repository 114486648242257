

import * as Url from "../../bindings/url/Url.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as DomUtils from "../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Reports_API from "./Reports_API.mjs";
import * as Reports_DatesFilter from "./Reports_DatesFilter.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var finishedAtFilter$p = Utils.Translations.tr("js.reports.jobs.finished_at_filter.title");

var Translations = {
  finishedAtFilter$p: finishedAtFilter$p
};

function $$default(wire) {
  return {
          finishedAt: [
            Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -4
                    })),
            Locale.T.endOf("day", Locale.T.now(wire))
          ]
        };
}

function encode(filters, token) {
  var finishedAt = RCore.$$Option.map(filters.finishedAt, (function (param) {
          return Js_dict.fromArray([
                      [
                        "start",
                        Locale.T.isoDate(param[0])
                      ],
                      [
                        "end",
                        Locale.T.isoDate(param[1])
                      ]
                    ]);
        }));
  return {
          token: token,
          finishedAtFilter: Js_null.fromOption(finishedAt)
        };
}

var Filters = {
  $$default: $$default,
  encode: encode
};

var styles = ReportsModuleCss;

function Reports_Workers(Props) {
  var wire = Props.wire;
  var iframeHeight = Props.iframeHeight;
  var token = Props.token;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default(wire);
      });
  var setFilters = match[1];
  var filters = match[0];
  var match$1 = React.useState(function () {
        var match = filters.finishedAt;
        if (match !== undefined) {
          return [
                  Caml_option.some(match[0]),
                  Caml_option.some(match[1])
                ];
        } else {
          return [
                  undefined,
                  undefined
                ];
        }
      });
  var setPeriod = match$1[1];
  var iframeRef = React.useRef(null);
  var setPeriodDate = function (fst, snd) {
    setPeriod(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  finishedAt: [
                    start,
                    finish
                  ]
                };
        });
  };
  var postMessage = function (url) {
    var iframe = iframeRef.current;
    if (!(iframe == null)) {
      return DomUtils.$$Window.postMessage(DomUtils.$$Window.contentWindow(iframe), encode(filters, token), Url.toString(url));
    }
    
  };
  React.useEffect((function () {
          var match = iframeRef.current;
          var match$1 = ctx.reportsUrl;
          if (match == null) {
            return ;
          }
          if (match$1 === undefined) {
            return ;
          }
          var url = Caml_option.valFromOption(match$1);
          DomUtils.Listeners.onLoad(undefined, match, (function (param) {
                  setTimeout((function () {
                          postMessage(url);
                        }), 100);
                }));
          return (function () {
                    DomUtils.Listeners.removeOnLoad(undefined, match, (function (param) {
                            postMessage(url);
                          }));
                  });
        }), []);
  React.useEffect((function () {
          var url = ctx.reportsUrl;
          if (url !== undefined) {
            postMessage(Caml_option.valFromOption(url));
          }
          
        }), [filters]);
  var url = wire.ctx.reportsUrl;
  if (url !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: styles.filtersContainer
                  }, React.createElement(Reports_DatesFilter.make, {
                        wire: wire,
                        period: match$1[0],
                        setPeriodDate: setPeriodDate,
                        label: React.createElement("span", undefined, finishedAtFilter$p(ctx))
                      })), React.createElement("iframe", {
                    ref: iframeRef,
                    style: {
                      border: "none",
                      minHeight: "100vh",
                      overflow: "hidden"
                    },
                    height: iframeHeight,
                    scrolling: "no",
                    src: Reports_API.buildReportsUrl(ctx, Url.toString(Caml_option.valFromOption(url)) + "workers"),
                    width: "100%"
                  }));
  } else {
    return null;
  }
}

var make = Reports_Workers;

export {
  Translations ,
  Filters ,
  styles ,
  make ,
}
/* finishedAtFilter' Not a pure module */
