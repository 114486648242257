

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Hooks from "../../../libraries/hooks/Hooks.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Utils from "../../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Select from "../../inputs/Select.mjs";
import * as Backend from "../../../libraries/backend/Backend.mjs";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as Spinner from "../../common/Spinner/Spinner.mjs";
import * as UI_Icon from "../../../ui/UI_Icon.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as MonthDay from "../../common/MonthDay/MonthDay.mjs";
import * as Optional from "../../common/Optional.mjs";
import * as UI_Button from "../../../ui/UI_Button.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.mjs";
import * as DatePicker from "../../inputs/DatePicker.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ShiftsTypes from "../ShiftsTypes.mjs";
import * as Context_Types from "../../../context/Context_Types.mjs";
import * as Backend_Errors from "../../../libraries/backend/Backend_Errors.mjs";
import * as Filters_Button from "../../common/Filters/Filters_Button.mjs";
import * as MessengerTypes from "../../messenger/MessengerTypes.mjs";
import * as ShiftsIndexAPI from "./ShiftsIndexAPI.mjs";
import * as ShiftsIndexTeams from "./teams/ShiftsIndexTeams.mjs";
import * as ShiftsIndexWorkers from "./workers/ShiftsIndexWorkers.mjs";
import * as Filters_ButtonGroup from "../../common/Filters/Filters_ButtonGroup.mjs";
import * as ShiftsIndexModuleCss from "/home/runner/work/planado/planado/client/rescript/components/shifts/index/ShiftsIndex.module.css";

var title$p = Utils.Translations.tr("js.shifts.index.title");

var today$p = Utils.Translations.tr("js.shifts.index.today");

var workers$p = Utils.Translations.tr("js.shifts.index.workers");

var teams$p = Utils.Translations.tr("js.shifts.index.teams");

var createTeam$p = Utils.Translations.tr("js.teams.buttons.create");

var constructor$p = Utils.Translations.tr("js.shifts.index.constructor");

var submit$p = Utils.Translations.tr("js.shifts.index.submit");

var cancel$p = Utils.Translations.tr("common.cancel");

function day$p(num, c) {
  return Utils.Translations.tr("js.shifts.index.abbr_day_names_lower." + num.toString())(c);
}

var applyMonth$p = Utils.Translations.tr("js.shifts.index.apply_month");

var apply3months$p = Utils.Translations.tr("js.shifts.index.apply_3_months");

var apply6months$p = Utils.Translations.tr("js.shifts.index.apply_6_months");

var apply12months$p = Utils.Translations.tr("js.shifts.index.apply_12_months");

var unprocessableEntity$p = Utils.Translations.t("js.shifts.show.unprocessable_entity");

var serverError$p = Utils.Translations.t("js.shifts.show.server_error");

var styles = ShiftsIndexModuleCss;

function currentInterval(dateRange) {
  return [
          dateRange[0],
          dateRange[dateRange.length - 1 | 0]
        ];
}

function notifyError(wire, text) {
  wire.subscriptions.messenger.trigger({
        TAG: "Show",
        _0: MessengerTypes.Message.make(undefined, "Danger", {
              TAG: "Text",
              _0: text
            })
      });
}

function toId(t) {
  if (t === "month") {
    return Types.Id.fromInt(1);
  } else if (t === "6months") {
    return Types.Id.fromInt(6);
  } else if (t === "3months") {
    return Types.Id.fromInt(3);
  } else {
    return Types.Id.fromInt(12);
  }
}

function toInt(t) {
  if (t === "month") {
    return 1;
  } else if (t === "6months") {
    return 6;
  } else if (t === "3months") {
    return 3;
  } else {
    return 12;
  }
}

function options(ctx) {
  return [
          {
            value: Types.Id.fromInt(1),
            label: applyMonth$p(ctx)
          },
          {
            value: Types.Id.fromInt(3),
            label: apply3months$p(ctx)
          },
          {
            value: Types.Id.fromInt(6),
            label: apply6months$p(ctx)
          },
          {
            value: Types.Id.fromInt(12),
            label: apply12months$p(ctx)
          }
        ];
}

function ShiftsIndex$CalendarPopover(Props) {
  var currentDay = Props.currentDay;
  var onPick = Props.onPick;
  var closeCalendar = Props.closeCalendar;
  var calendarPopoverRef = Types.ReactRef.use();
  Hooks.useOnClickOutside(undefined, calendarPopoverRef, closeCalendar);
  Hooks.useOnEscPress(undefined, closeCalendar);
  return React.createElement("div", {
              ref: calendarPopoverRef,
              className: styles.calendarPopover
            }, React.createElement(DatePicker.make, {
                  date: currentDay,
                  onDateChange: (function (d) {
                      onPick(d);
                    }),
                  numberOfMonths: 1,
                  navPrev: React.createElement("span", undefined),
                  navNext: React.createElement("span", undefined),
                  daySize: 31,
                  hideKeyboardShortcutsPanel: true
                }));
}

function ShiftsIndex$Header(Props) {
  var loading = Props.loading;
  var dateRange = Props.dateRange;
  var onStartChange = Props.onStartChange;
  var mode = Props.mode;
  var setMode = Props.setMode;
  var constrDuration = Props.constrDuration;
  var setConstrDuration = Props.setConstrDuration;
  var constrDisabled = Props.constrDisabled;
  var submitConstructor = Props.submitConstructor;
  var workersType = Props.workersType;
  var onViewUpdate = Props.onViewUpdate;
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setShowCalendar = match[1];
  var match$1 = currentInterval(dateRange);
  var onConstructorSubmit = function (e) {
    e.preventDefault();
    submitConstructor();
  };
  var renderMonth = function (dateStr) {
    return React.createElement("div", undefined, dateStr);
  };
  var renderInterval = React.createElement("div", {
        className: styles.intervalDates
      }, renderMonth(Locale.T.fmtM("MonthWithFullYear", wire)(match$1[1])));
  var tmp;
  tmp = mode === "View" ? React.createElement(UI_Button.make, {
          purpose: "primary",
          onClick: (function (param) {
              setMode("Constructor");
            }),
          children: null
        }, React.createElement(UI_Icon.make, {
              className: styles.constructorButtonIcon,
              icon: "calendar-alt"
            }), constructor$p(ctx)) : React.createElement(React.Fragment, undefined, React.createElement("div", {
              className: styles.constructorButton,
              onClick: (function (param) {
                  setMode("View");
                })
            }, cancel$p(ctx)), React.createElement("div", {
              className: styles.constructorEl
            }, React.createElement(Select.make, {
                  onChange: (function (v) {
                      setConstrDuration(function (param) {
                            var id = RCore.$$Option.getExn(v === null ? undefined : Caml_option.some(v), undefined);
                            var match = Types.Id.toInt(id);
                            switch (match) {
                              case 3 :
                                  return "3months";
                              case 6 :
                                  return "6months";
                              case 12 :
                                  return "12months";
                              default:
                                return "month";
                            }
                          });
                    }),
                  value: Js_null.fromOption(Caml_option.some(toId(constrDuration))),
                  disabled: false,
                  clearable: false,
                  searchable: false,
                  options: options(ctx),
                  className: styles.monthSelect
                })), React.createElement("button", {
              className: El.Cn.concat(styles.constructorButton, styles.constructorButtonSubmit),
              disabled: constrDisabled,
              onClick: onConstructorSubmit
            }, React.createElement("span", {
                  className: Icon.style(undefined, undefined, undefined, "check")
                }), loading ? React.createElement(Spinner.make, {}) : submit$p(ctx)));
  return React.createElement("div", {
              className: styles.header
            }, React.createElement("h1", {
                  className: styles.headline
                }, title$p(ctx)), React.createElement("div", {
                  className: styles.interval
                }, React.createElement(Optional.make, {
                      show: Context_Types.Features.hasFlag("teams", ctx.features),
                      children: React.createElement(Filters_ButtonGroup.make, {
                            className: styles.filtersButtons,
                            children: null
                          }, React.createElement(Filters_Button.make, {
                                onClick: (function (param) {
                                    onViewUpdate("Teams");
                                  }),
                                active: workersType === "Teams",
                                children: null
                              }, React.createElement("i", {
                                    className: Icon.style(undefined, undefined, undefined, "user-friends")
                                  }), teams$p(ctx)), React.createElement(Filters_Button.make, {
                                onClick: (function (param) {
                                    onViewUpdate("Workers");
                                  }),
                                active: workersType === "Workers",
                                children: null
                              }, React.createElement("i", {
                                    className: Icon.style(undefined, undefined, undefined, "user-tie")
                                  }), workers$p(ctx)))
                    }), React.createElement(Filters_ButtonGroup.make, {
                      className: styles.filtersButtons,
                      children: null
                    }, React.createElement(Filters_Button.make, {
                          onClick: (function (param) {
                              onStartChange("today");
                            }),
                          children: today$p(ctx)
                        }), React.createElement(Filters_Button.make, {
                          onClick: (function (param) {
                              setShowCalendar(function (prev) {
                                    return !prev;
                                  });
                            }),
                          children: React.createElement("i", {
                                className: Icon.style(undefined, undefined, undefined, "calendar-alt")
                              })
                        })), React.createElement(Filters_ButtonGroup.make, {
                      className: styles.filtersButtons,
                      children: null
                    }, React.createElement(Filters_Button.make, {
                          onClick: (function (param) {
                              onStartChange("backward");
                            }),
                          children: React.createElement("i", {
                                className: Icon.style(undefined, undefined, undefined, "chevron-left")
                              })
                        }), React.createElement(Filters_Button.make, {
                          onClick: (function (param) {
                              onStartChange("forward");
                            }),
                          children: React.createElement("i", {
                                className: Icon.style(undefined, undefined, undefined, "chevron-right")
                              })
                        }))), React.createElement(Optional.make, {
                  show: match[0],
                  children: React.createElement(ShiftsIndex$CalendarPopover, {
                        currentDay: match$1[0],
                        onPick: (function (d) {
                            onStartChange({
                                  NAME: "exact",
                                  VAL: d
                                });
                          }),
                        closeCalendar: (function () {
                            setShowCalendar(function (param) {
                                  return false;
                                });
                          })
                      })
                }), renderInterval, React.createElement("div", {
                  className: styles.constructorContainer
                }, tmp));
}

function ShiftsIndex$TableHeader(Props) {
  var mode = Props.mode;
  var workersType = Props.workersType;
  var dateRange = Props.dateRange;
  var setEditedTeamMode = Props.setEditedTeamMode;
  var match = AppContext.useWire();
  var ctx = match.ctx;
  var headerCN = React.useMemo((function () {
          if (mode === "View") {
            return styles.tableHeader;
          } else {
            return El.Cn.concat(styles.tableHeader, styles.constructorMode);
          }
        }), [mode]);
  var tmp;
  tmp = workersType === "Teams" ? React.createElement("div", {
          className: styles.nameHeader
        }, teams$p(ctx), React.createElement(UI_Button.make, {
              flavor: "outline",
              onClick: (function (e) {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditedTeamMode(function (param) {
                        return "New";
                      });
                }),
              children: React.createElement("span", undefined, React.createElement(UI_Icon.make, {
                        className: styles.addTeamBtnIcon,
                        icon: "plus"
                      }), createTeam$p(ctx))
            })) : React.createElement("div", {
          className: styles.nameHeader
        }, workers$p(ctx));
  return React.createElement("div", {
              className: headerCN
            }, tmp, React.createElement("div", {
                  className: styles.headerDates
                }, dateRange.map(function (day, idx) {
                      return React.createElement("div", {
                                  key: idx.toString(),
                                  className: styles.headerDate
                                }, React.createElement("span", undefined, day$p(Locale.T.getDay(day), ctx)), React.createElement("div", {
                                      className: styles.dayNumber
                                    }, React.createElement(MonthDay.make, {
                                          prevDay: dateRange[idx - 1 | 0],
                                          day: day
                                        })));
                    })));
}

var default_workers = [];

var default_teams = [];

var default_jobTypes = [];

var default_territories = [];

var default_constructorWorkers = [];

var $$default = {
  loading: true,
  workers: default_workers,
  teams: default_teams,
  jobTypes: default_jobTypes,
  territories: default_territories,
  constructorWorkers: default_constructorWorkers
};

function ShiftsIndex(Props) {
  var wire = AppContext.useWire();
  var match = React.useState(function () {
        return $$default;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return ShiftsTypes.Mode.$$default;
      });
  var setMode = match$1[1];
  var mode = match$1[0];
  var match$2 = React.useState(function () {
        return ShiftsTypes.WorkersType.$$default(wire.ctx);
      });
  var setWorkersType = match$2[1];
  var workersType = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setEditedTeamMode = match$3[1];
  var match$4 = React.useState(function () {
        return "month";
      });
  var constrDuration = match$4[0];
  var match$5 = React.useState(function () {
        return Locale.T.startOf("isoWeek", Locale.T.now(wire));
      });
  var setStart = match$5[1];
  var start = match$5[0];
  var dateRange = Core__List.toArray(Locale.T.dateRange(14, start));
  var currentInterval$1 = React.useMemo((function () {
          return currentInterval(dateRange);
        }), [dateRange]);
  var onViewUpdate = function (newType) {
    if (newType === workersType) {
      return ;
    } else {
      setWorkersType(function (param) {
            return newType;
          });
      return setState(function (prev) {
                  return {
                          loading: prev.loading,
                          workers: prev.workers,
                          teams: prev.teams,
                          jobTypes: prev.jobTypes,
                          territories: prev.territories,
                          constructorWorkers: []
                        };
                });
    }
  };
  var getWorkers = function (interval) {
    Backend.$$finally(ShiftsIndexAPI.list(wire, interval, [], []), (function (result) {
            if (result.TAG !== "Ok") {
              return setState(function (prev) {
                          return {
                                  loading: false,
                                  workers: prev.workers,
                                  teams: prev.teams,
                                  jobTypes: prev.jobTypes,
                                  territories: prev.territories,
                                  constructorWorkers: prev.constructorWorkers
                                };
                        });
            }
            var match = result._0;
            var territories = match.territories;
            var jobTypes = match.jobTypes;
            var teams = match.teams;
            var users = match.users;
            setState(function (param) {
                  return {
                          loading: false,
                          workers: users,
                          teams: teams,
                          jobTypes: jobTypes,
                          territories: territories,
                          constructorWorkers: []
                        };
                });
          }));
  };
  React.useEffect((function () {
          getWorkers(currentInterval$1);
        }), [start]);
  var onStartChange = function (direction) {
    if (typeof direction !== "object") {
      if (direction === "today") {
        return setStart(function (param) {
                    return Locale.T.startOf("isoWeek", Locale.T.now(wire));
                  });
      } else if (direction === "backward") {
        return setStart(function (prevStart) {
                    return Locale.T.next(prevStart, {
                                NAME: "days",
                                VAL: -14
                              });
                  });
      } else {
        return setStart(function (prevStart) {
                    return Locale.T.next(prevStart, {
                                NAME: "days",
                                VAL: 14
                              });
                  });
      }
    }
    var date = direction.VAL;
    setStart(function (param) {
          return Locale.T.startOf("isoWeek", date);
        });
  };
  var updateWorker = function (user) {
    setState(function (prev) {
          return {
                  loading: prev.loading,
                  workers: prev.workers.map(function (prevWorker) {
                        if (Uuid.equal(prevWorker.uuid, user.uuid)) {
                          return user;
                        } else {
                          return prevWorker;
                        }
                      }),
                  teams: prev.teams,
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: prev.constructorWorkers
                };
        });
  };
  var updateTeam = function (team) {
    setState(function (prev) {
          return {
                  loading: prev.loading,
                  workers: prev.workers,
                  teams: prev.teams.map(function (prevTeam) {
                        if (Uuid.equal(prevTeam.uuid, team.uuid)) {
                          return team;
                        } else {
                          return prevTeam;
                        }
                      }),
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: prev.constructorWorkers
                };
        });
  };
  var deleteTeam = function (uuid) {
    setState(function (prev) {
          return {
                  loading: prev.loading,
                  workers: prev.workers,
                  teams: RCore.$$Array.keep(prev.teams, (function (t) {
                          return Caml_obj.notequal(t.uuid, uuid);
                        })),
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: prev.constructorWorkers
                };
        });
  };
  var updateConstructorWorker = function (userUuid, day) {
    setState(function (prev) {
          return {
                  loading: prev.loading,
                  workers: prev.workers,
                  teams: prev.teams,
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: ShiftsTypes.ConstructorWorker.update(state.constructorWorkers, userUuid, day)
                };
        });
  };
  var deleteConstructorWorker = function (uuid) {
    setState(function (prev) {
          return {
                  loading: prev.loading,
                  workers: prev.workers,
                  teams: prev.teams,
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: RCore.$$Array.keep(prev.constructorWorkers, (function (cu) {
                          return !Uuid.equal(cu.uuid, uuid);
                        }))
                };
        });
  };
  var containerCN = React.useMemo((function () {
          if (mode === "View") {
            return styles.container;
          } else {
            return El.Cn.concat(styles.container, styles.constructorMode);
          }
        }), [mode]);
  var onModeSet = function (mode) {
    if (mode === "View") {
      setState(function (prev) {
            return {
                    loading: prev.loading,
                    workers: prev.workers,
                    teams: prev.teams,
                    jobTypes: prev.jobTypes,
                    territories: prev.territories,
                    constructorWorkers: []
                  };
          });
    }
    setMode(function (param) {
          return mode;
        });
  };
  var handleConstResponse = function (response) {
    if (response.TAG === "Ok") {
      setState(function (prev) {
            return {
                    loading: prev.loading,
                    workers: prev.workers,
                    teams: prev.teams,
                    jobTypes: prev.jobTypes,
                    territories: prev.territories,
                    constructorWorkers: []
                  };
          });
      getWorkers(currentInterval$1);
    } else {
      var errors = response._0;
      var exit = 0;
      if (typeof errors !== "object" || errors.TAG !== "ValidationError") {
        exit = 1;
      } else {
        notifyError(wire, RCore.$$Option.getOr(Backend_Errors.getFirst(errors._0, "intervals"), unprocessableEntity$p(wire.ctx)));
        setState(function (prev) {
              return {
                      loading: false,
                      workers: prev.workers,
                      teams: prev.teams,
                      jobTypes: prev.jobTypes,
                      territories: prev.territories,
                      constructorWorkers: []
                    };
            });
      }
      if (exit === 1) {
        notifyError(wire, serverError$p(wire.ctx));
        setState(function (prev) {
              return {
                      loading: false,
                      workers: prev.workers,
                      teams: prev.teams,
                      jobTypes: prev.jobTypes,
                      territories: prev.territories,
                      constructorWorkers: []
                    };
            });
      }
      
    }
    setMode(function (param) {
          return "View";
        });
  };
  var submitConstructor = function () {
    setState(function (prev) {
          return {
                  loading: true,
                  workers: prev.workers,
                  teams: prev.teams,
                  jobTypes: prev.jobTypes,
                  territories: prev.territories,
                  constructorWorkers: prev.constructorWorkers
                };
        });
    if (mode === "View") {
      return ;
    }
    var res;
    res = workersType === "Teams" ? ShiftsIndexAPI.TeamsConstructor.patch(wire, toInt(constrDuration), state.constructorWorkers) : ShiftsIndexAPI.WorkersConstructor.patch(wire, toInt(constrDuration), state.constructorWorkers);
    Backend.$$finally(res, handleConstResponse);
  };
  var constrDisabled = React.useMemo((function () {
          var arr = state.constructorWorkers;
          if (arr.length !== 0) {
            return !arr.every(function (a) {
                        var match = a.dates;
                        if (match !== undefined) {
                          return match[1] !== undefined;
                        } else {
                          return false;
                        }
                      });
          } else {
            return true;
          }
        }), [state.constructorWorkers]);
  var tmp;
  tmp = workersType === "Teams" ? React.createElement(ShiftsIndexTeams.make, {
          dateRange: dateRange,
          workers: state.workers,
          teams: state.teams,
          jobTypes: state.jobTypes,
          territories: state.territories,
          constructorWorkers: state.constructorWorkers,
          deleteConstructorWorker: deleteConstructorWorker,
          deleteTeam: deleteTeam,
          updateTeam: updateTeam,
          updateWorker: updateWorker,
          interval: currentInterval$1,
          updateConstructorWorker: updateConstructorWorker,
          mode: mode,
          editedTeamMode: match$3[0],
          setEditedTeamMode: setEditedTeamMode,
          loading: state.loading
        }) : React.createElement(ShiftsIndexWorkers.make, {
          dateRange: dateRange,
          workers: state.workers,
          jobTypes: state.jobTypes,
          territories: state.territories,
          constructorWorkers: state.constructorWorkers,
          deleteConstructorWorker: deleteConstructorWorker,
          updateWorker: updateWorker,
          updateConstructorWorker: updateConstructorWorker,
          mode: mode
        });
  return React.createElement("div", {
              className: containerCN
            }, React.createElement("div", {
                  className: styles.headers
                }, React.createElement(ShiftsIndex$Header, {
                      loading: state.loading,
                      dateRange: dateRange,
                      onStartChange: onStartChange,
                      mode: mode,
                      setMode: onModeSet,
                      constrDuration: constrDuration,
                      setConstrDuration: match$4[1],
                      constrDisabled: constrDisabled,
                      submitConstructor: submitConstructor,
                      workersType: workersType,
                      onViewUpdate: onViewUpdate
                    }), React.createElement(ShiftsIndex$TableHeader, {
                      mode: mode,
                      workersType: workersType,
                      dateRange: dateRange,
                      setEditedTeamMode: setEditedTeamMode
                    })), React.createElement("div", {
                  className: styles.content
                }, tmp));
}

var make = ShiftsIndex;

export {
  make ,
}
/* title' Not a pure module */
