


var Size = {};

var Style = {};

var Gauge = {};

var Icon = {};

function style(size, spinOpt, style$1, icon) {
  var spin = spinOpt !== undefined ? spinOpt : false;
  var fasize = size !== undefined ? "fa-" + size : "";
  var style$2 = style$1 !== undefined ? "fa-" + style$1 : "";
  var prefix = icon === "cc-jcb" || icon === "cc-mastercard" || icon === "cc-paypal" || icon === "cc-visa" ? "fab" : (
      icon === "map" ? "far" : (
          icon === "ruble-sign" ? "fas" : "fa"
        )
    );
  var spin$1 = spin ? "fa-spin" : "";
  return prefix + " " + fasize + " fa-" + icon + " " + spin$1 + " " + style$2;
}

export {
  Size ,
  Style ,
  Gauge ,
  Icon ,
  style ,
}
/* No side effect */
