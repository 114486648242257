

import * as El from "../../../../../libraries/El.mjs";
import * as Icon from "../../../../../Icon.mjs";
import * as React from "react";
import * as FileInput_PreviewModuleCss from "/home/runner/work/planado/planado/client/rescript/components/file/file_input/components/preview/FileInput_Preview.module.css";

var styles = FileInput_PreviewModuleCss;

function stopPropagation($$event) {
  $$event.stopPropagation();
}

function FileInput_Preview_DownloadButton(Props) {
  var url = Props.url;
  var name = Props.name;
  var decoration = Props.decoration;
  var className = decoration === "Dark" ? El.Cn.concat(Icon.style(undefined, undefined, undefined, "download"), styles.dark) : Icon.style(undefined, undefined, undefined, "download");
  return React.createElement("a", {
              download: name,
              href: url,
              target: "_blank",
              onClick: stopPropagation
            }, React.createElement("span", {
                  className: className
                }));
}

var make = FileInput_Preview_DownloadButton;

export {
  make ,
}
/* styles Not a pure module */
