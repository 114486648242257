

import * as RCore from "../../libraries/RCore.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_promise from "rescript/lib/es6/js_promise.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Fetch from "rescript-webapi/lib/es6/src/Webapi/Webapi__Fetch.mjs";

function countryIsoCode(country) {
  if (country === "Россия") {
    return "RU";
  } else {
    return "*";
  }
}

function make(constraints) {
  return {
          country_iso_code: RCore.$$Option.mapWithDefault(constraints.country, "*", countryIsoCode),
          region: RCore.$$Option.getOr(constraints.region, ""),
          area: RCore.$$Option.getOr(constraints.area, ""),
          city: RCore.$$Option.getOr(constraints.city, ""),
          settlement: RCore.$$Option.getOr(constraints.settlement, "")
        };
}

function makeBody(query, suggestionsAmount, baseAddress, fromBound, toBound) {
  var locations = make(baseAddress);
  if (fromBound !== undefined && toBound !== undefined) {
    return JSON.stringify(Js_dict.fromList({
                    hd: [
                      "count",
                      RCore.$$Option.getOr(suggestionsAmount, 5).toString()
                    ],
                    tl: {
                      hd: [
                        "query",
                        query
                      ],
                      tl: {
                        hd: [
                          "locations",
                          locations
                        ],
                        tl: {
                          hd: [
                            "from_bound",
                            Js_dict.fromList({
                                  hd: [
                                    "value",
                                    fromBound
                                  ],
                                  tl: /* [] */0
                                })
                          ],
                          tl: {
                            hd: [
                              "to_bound",
                              Js_dict.fromList({
                                    hd: [
                                      "value",
                                      toBound
                                    ],
                                    tl: /* [] */0
                                  })
                            ],
                            tl: /* [] */0
                          }
                        }
                      }
                    }
                  }));
  }
  return JSON.stringify(Js_dict.fromList({
                  hd: [
                    "count",
                    RCore.$$Option.getOr(suggestionsAmount, 5).toString()
                  ],
                  tl: {
                    hd: [
                      "query",
                      query
                    ],
                    tl: {
                      hd: [
                        "locations",
                        locations
                      ],
                      tl: /* [] */0
                    }
                  }
                }));
}

function postAddressRequest(query, suggestionsAmount, token, baseAddress, fromBound, toBound, param) {
  var __x = (function (__x) {
        return Js_promise.then_((function (prim) {
                      return prim.json();
                    }), __x);
      })(fetch("https://dadata.planado.ru/api/v2/suggest/address", Webapi__Fetch.RequestInit.make("Post", {
                "content-type": "application/json",
                accept: "*/*",
                authority: "dadata.ru",
                authorization: "Token " + token,
                "access-control-allow-origin": "*"
              }, Caml_option.some(makeBody(query, suggestionsAmount, baseAddress, fromBound, toBound)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)));
  return Js_promise.then_((function (prim) {
                return Promise.resolve(prim);
              }), __x);
}

export {
  postAddressRequest ,
}
/* No side effect */
