

import * as Case from "../utils/Case.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsonDecode from "../libraries/JsonDecode.mjs";

var features = [
  "amocrm",
  "api",
  "bitrix24",
  "bulkOperations",
  "catalog",
  "clientPortal",
  "clientTracking",
  "createJobsInApp",
  "customFields",
  "linkedJobs",
  "printLayouts",
  "recurringJobs",
  "shifts",
  "signature",
  "sms",
  "smsGateway",
  "teams",
  "territories",
  "tracking",
  "zapier"
];

var flags = [
  "captcha",
  "disablePlanChange",
  "geojson",
  "multipleBitrixSettings",
  "newDecoders",
  "newFields",
  "noDictionaryValuesLimit",
  "oldTeamsUi",
  "scheduleZoom",
  "showOldTimeline",
  "temporaryWorkers",
  "useGallery",
  "webApiSignature",
  "yandexSatelliteView",
  "catalogProducts",
  "reports"
];

var all = Belt_Array.concatMany([
      features,
      flags
    ]);

function fromString(s) {
  var match = Case.camelizeString(s);
  switch (match) {
    case "amocrm" :
        return "amocrm";
    case "api" :
        return "api";
    case "audit" :
        return "audit";
    case "bitrix24" :
        return "bitrix24";
    case "bulkOperations" :
        return "bulkOperations";
    case "captcha" :
        return "captcha";
    case "catalog" :
        return "catalog";
    case "catalogProducts" :
        return "catalogProducts";
    case "clientPortal" :
        return "clientPortal";
    case "clientTracking" :
        return "clientTracking";
    case "createJobsInApp" :
        return "createJobsInApp";
    case "customFields" :
        return "customFields";
    case "disablePlanChange" :
        return "disablePlanChange";
    case "geojson" :
        return "geojson";
    case "hideSuccessPath" :
        return "hideSuccessPath";
    case "linkedJobs" :
        return "linkedJobs";
    case "multipleBitrixSettings" :
        return "multipleBitrixSettings";
    case "newDecoders" :
        return "newDecoders";
    case "newFields" :
        return "newFields";
    case "noDictionaryValuesLimit" :
        return "noDictionaryValuesLimit";
    case "oldTeamsUi" :
        return "oldTeamsUi";
    case "printLayouts" :
        return "printLayouts";
    case "recurringJobs" :
        return "recurringJobs";
    case "reports" :
        return "reports";
    case "scheduleTemplateFilter" :
        return "scheduleTemplateFilter";
    case "scheduleZoom" :
        return "scheduleZoom";
    case "shifts" :
        return "shifts";
    case "showOldTimeline" :
        return "showOldTimeline";
    case "signature" :
        return "signature";
    case "sms" :
        return "sms";
    case "smsGateway" :
        return "smsGateway";
    case "teams" :
        return "teams";
    case "temporaryWorkers" :
        return "temporaryWorkers";
    case "territories" :
        return "territories";
    case "tracking" :
        return "tracking";
    case "useGallery" :
        return "useGallery";
    case "webApiSignature" :
        return "webApiSignature";
    case "yandexSatelliteView" :
        return "yandexSatelliteView";
    case "zapier" :
        return "zapier";
    default:
      return ;
  }
}

var decode = JsonDecode.map(JsonDecode.string, fromString);

export {
  features ,
  flags ,
  all ,
  fromString ,
  decode ,
}
/* all Not a pure module */
