

import * as El from "../../../libraries/El.mjs";
import * as Icon from "../../../Icon.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Optional from "../Optional.mjs";
import * as MultipleAssignee from "../../../types/MultipleAssignee.mjs";
import * as MultipleAssigneesModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/MultipleAssignees/MultipleAssignees.module.css";

var styles = MultipleAssigneesModuleCss;

var TeamMember = {};

function name(w) {
  if (w.TAG === "TeamMember") {
    return w._0._0.name;
  } else {
    return MultipleAssignee.$$Worker.name(w._0);
  }
}

var $$Worker = {
  TeamMember: TeamMember,
  name: name
};

var Decoration = {};

function MultipleAssignees_Worker(Props) {
  var worker = Props.worker;
  var onDelete = Props.onDelete;
  var teamMember = Props.teamMember;
  var disabled = Props.disabled;
  var decoration = Props.decoration;
  var singleOpt = Props.single;
  var displayedName = Props.displayedName;
  var single = singleOpt !== undefined ? singleOpt : false;
  var color = teamMember ? styles.teamWorker : styles.worker;
  var bold;
  bold = worker.TAG === "TeamMember" ? (
      worker._0.TAG === "Edit" && !single ? styles.foreman : ""
    ) : (
      worker._0.TAG === "Edit" && !single ? styles.foreman : ""
    );
  var decorationClassName = decoration !== undefined ? (
      decoration === "Warning" ? styles.warning : styles.error
    ) : "";
  var className = El.Cn.concat(El.Cn.concat(El.Cn.concat(styles.workerContainer, bold), color), decorationClassName);
  var onDeleteClick = function (evt) {
    evt.preventDefault();
    onDelete();
  };
  return React.createElement("div", {
              className: className
            }, React.createElement(Optional.make, {
                  show: !disabled,
                  children: React.createElement("div", {
                        className: El.Cn.concat(styles.deleteButton, "delete-button"),
                        onClick: onDeleteClick
                      }, React.createElement("i", {
                            className: Icon.style(undefined, undefined, undefined, "times")
                          }))
                }), React.createElement("div", {
                  className: styles.workerName
                }, RCore.$$Option.getOr(displayedName, name(worker))));
}

var make = MultipleAssignees_Worker;

export {
  $$Worker ,
  Decoration ,
  make ,
}
/* styles Not a pure module */
