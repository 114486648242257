

import * as Utils from "../../../../../utils/Utils.mjs";

function jobFieldsHeader$p(jobFieldType) {
  switch (jobFieldType) {
    case "SerialNo" :
        return Utils.Translations.t("js.jobs.index.heading.serial_no_text");
    case "ExternalId" :
        return Utils.Translations.t("js.jobs.index.heading.external_id");
    case "Address" :
        return Utils.Translations.t("js.jobs.index.heading.address");
    case "AddressDescription" :
        return Utils.Translations.t("js.jobs.index.heading.address_description");
    case "Geolocation" :
        return Utils.Translations.t("js.jobs.index.heading.geolocation");
    case "EntranceNo" :
        return Utils.Translations.t("js.jobs.index.heading.entrance_no");
    case "Floor" :
        return Utils.Translations.t("js.jobs.index.heading.floor");
    case "Client" :
        return Utils.Translations.t("js.jobs.index.heading.client_or_site");
    case "ClientExternalId" :
        return Utils.Translations.t("js.jobs.index.heading.client_or_site_external_id");
    case "MobileNumber" :
        return Utils.Translations.t("js.jobs.index.heading.mobile_number");
    case "PrimaryContactName" :
        return Utils.Translations.t("js.jobs.index.heading.primary_contact_name");
    case "PrimaryContactMobile" :
        return Utils.Translations.t("js.jobs.index.heading.primary_contact_mobile");
    case "SecondaryContactName" :
        return Utils.Translations.t("js.jobs.index.heading.secondary_contact_name");
    case "SecondaryContactMobile" :
        return Utils.Translations.t("js.jobs.index.heading.secondary_contact_mobile");
    case "Template" :
        return Utils.Translations.t("js.jobs.index.heading.template");
    case "Skills" :
        return Utils.Translations.t("js.jobs.index.heading.skills");
    case "Assignee" :
        return Utils.Translations.t("js.jobs.index.heading.assignee");
    case "Assignees" :
        return Utils.Translations.t("js.jobs.index.heading.assignees");
    case "AssigneesCount" :
        return Utils.Translations.t("js.jobs.index.heading.assignees_count");
    case "ScheduledAt" :
        return Utils.Translations.t("js.jobs.index.heading.scheduled_at");
    case "EnRouteAt" :
        return Utils.Translations.t("js.jobs.index.heading.en_route_at");
    case "StartedAt" :
        return Utils.Translations.t("js.jobs.index.heading.started_at");
    case "FinishedAt" :
        return Utils.Translations.t("js.jobs.index.heading.finished_at");
    case "SuspendedAt" :
        return Utils.Translations.t("js.jobs.index.heading.suspended_at");
    case "ResumedAt" :
        return Utils.Translations.t("js.jobs.index.heading.resumed_at");
    case "ViewedAt" :
        return Utils.Translations.t("js.jobs.index.heading.viewed_at");
    case "ScheduledDuration" :
        return Utils.Translations.t("js.jobs.index.heading.scheduled_duration");
    case "EnRouteDuration" :
        return Utils.Translations.t("js.jobs.index.heading.en_route_duration");
    case "WorkDuration" :
        return Utils.Translations.t("js.jobs.index.heading.work_duration");
    case "SuspensionDuration" :
        return Utils.Translations.t("js.jobs.index.heading.suspension_duration");
    case "ActualDuration" :
        return Utils.Translations.t("js.jobs.index.heading.actual_duration");
    case "Status" :
        return Utils.Translations.t("js.jobs.index.heading.status");
    case "ResolutionName" :
        return Utils.Translations.t("js.jobs.index.heading.resolution_name");
    case "ResolutionComment" :
        return Utils.Translations.t("js.jobs.index.heading.resolution_comment");
    case "TypeCode" :
        return Utils.Translations.t("js.jobs.index.heading.type_code");
    case "Description" :
        return Utils.Translations.t("js.jobs.index.heading.description");
    case "CreatedAt" :
        return Utils.Translations.t("js.jobs.index.heading.created_at");
    case "Creator" :
        return Utils.Translations.t("js.jobs.index.heading.creator");
    case "Territory" :
        return Utils.Translations.t("js.jobs.index.heading.territory");
    case "StartedLocation" :
        return Utils.Translations.t("js.jobs.index.heading.started_location");
    case "FinishedLocation" :
        return Utils.Translations.t("js.jobs.index.heading.finished_location");
    case "CompletionJobs" :
        return Utils.Translations.t("js.jobs.index.heading.completion_jobs");
    case "ExternalResourceLink" :
        return Utils.Translations.t("js.jobs.index.heading.external_resource_link");
    
  }
}

export {
  jobFieldsHeader$p ,
}
/* Utils Not a pure module */
